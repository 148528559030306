import { WatchPartyType } from 'app/data/dto/watchParty/WatchPartyType';
import { BaseVideoDTO } from 'app/data/dto/onDemandVideo/BaseVideoDTO';
import { DateUtil } from 'app/util/DateUtil';
import { Expose, Transform } from 'class-transformer';
import { TransformFnParams } from 'class-transformer/types/interfaces';

export class WatchPartyUpdateDTO {
  public type: WatchPartyType;

  @Transform((params: TransformFnParams) => params.value?.id, { toPlainOnly: true })
  @Expose({ name: 'videoId', toPlainOnly: true })
  public video: BaseVideoDTO;

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;
}
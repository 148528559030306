import { Transform } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { DateUtil } from 'app/util/DateUtil';

export class NotificationScheduledLiveClassDTO extends IdentifiableObjectDTO<number> {
  duration: number;
  @Transform(DateUtil.dateTimeConversion)
  startDate: Date;
  url: string;
}

<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.LIVE_CLASSES.SERIES.CREATE' | translate }}</h1>

  <div class="d-flex flex-wrap gap-4">
    <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="uploadStarted">
      <span *ngIf="uploadStarted" class="loader-white"></span>
      <span>{{ 'COMMON.SAVE' | translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section">
  <form #form="ngForm" (keyup.enter)="submit()">
    <div class="row mb-4">
      <div class="form-group col-12 col-lg-9">
        <label for="title">{{ 'VIEW.MAIN.LIVE_CLASSES.TITLE'| translate }}</label>
        <input
          type="text"
          id="title"
          name="title"
          #title="ngModel"
          class="form-control"
          placeholder="{{ 'VIEW.MAIN.LIVE_CLASSES.TITLE' | translate }}"
          [(ngModel)]="liveClassSeries.title"
          required
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_128"
          [ngClass]="{'is-invalid': form.submitted && title.invalid, 'is-valid': form.submitted && title.valid }"
        />
        <div class="invalid-feedback" *ngIf="form.submitted && title.invalid">
          <div *ngIf="title.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="title.errors['maxLength']">
            {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_128} }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="form-group col-12 col-lg-3">
        <label for="startDate">{{ 'VIEW.MAIN.LIVE_CLASSES.SERIES.START_DATE' | translate }}</label>
        <div class="mb-0 iconbox">
          <svg-icon class="search-icon" src="asset/image/icon/search.svg"></svg-icon>
          <input
            type="text"
            id="startDate"
            name="startDate"
            #startDate="ngModel"
            class="form-control date-icon"
            placeholder="{{ 'VIEW.MAIN.LIVE_CLASSES.SERIES.START_DATE' | translate }}"
            [ngClass]="{'is-invalid': form.submitted && startDate.invalid, 'is-valid': form.submitted && startDate.valid }"
            [(ngModel)]="liveClassSeries.startDate"
            required
            [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-custom',minDate: minDateOfCreateLiveClass }"
            [appMinDateTime]="minDateOfCreateLiveClass"
            (ngModelChange)="onDateChange()"
            bsDatepicker
          />
          <div class="invalid-feedback" *ngIf="form.submitted && startDate.invalid">
            <div *ngIf="startDate.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="startDate.errors['minDate']">
              {{ 'VALIDATION.DATE_MAX' | translate: {dateString: minDateOfCreateLiveClass.toDateString()} }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group col-12 col-lg-3">
        <label for="time">{{ 'VIEW.MAIN.LIVE_CLASSES.TIME' | translate }}</label>
        <timepicker
          id="time"
          name="time"
          #time="ngModel"
          [(ngModel)]="liveClassSeries.startDate"
          [ngClass]="{'is-invalid': form.submitted && time.invalid, 'is-valid': form.submitted && time.valid }"
          [minuteStep]="1"
          [showSpinners]="false"
          [showMeridian]="true"
          [mousewheel]="true"
          [appMinDateTime]="minDateOfCreateLiveClass"
          (ngModelChange)="validateTime(time)"
          required
        ></timepicker>
        <div class="invalid-feedback" *ngIf="form.submitted && time.invalid">
          <div *ngIf="form.controls.time.errors?.['minDateTime']">
            {{ 'VALIDATION.TIME_CANT_BE_IN_THE_PAST' | translate }}
          </div>
          <div *ngIf="time.errors?.['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <div class="d-flex align-items-center gap-4">
          <div class="form-group">
            <label>{{ 'VIEW.MAIN.LIVE_CLASSES.SERIES.LEFT_CLASSES' | translate }}</label>
            <input type="number"
                   class="form-control"
                   name="leftClasses"
                   #leftClasses="ngModel"
                   appInputPositiveIntegerOnly
                   [ngClass]="{
                    'is-invalid': form.submitted && leftClasses.invalid,
                    'is-valid': form.submitted && leftClasses.valid
                   }"
                   [min]="0"
                   [required]="!liveClassSeries.endDate"
                   [appMustBeEmpty]="!!liveClassSeries?.endDate"
                   [(ngModel)]="liveClassSeries.numberOfClassesLeft"
                   [disabled]="!!liveClassSeries?.endDate">
            <div class="invalid-feedback" *ngIf="form.submitted && leftClasses.invalid">
              <div *ngIf="leftClasses.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              <div *ngIf="leftClasses.errors['mustBeEmpty']">{{ 'VALIDATION.MUST_BE_EMPTY' | translate }}</div>
            </div>
          </div>

          <div>{{ 'COMMON.OR' | translate }}</div>

          <div class="form-group">
            <label for="endDate">{{ 'VIEW.MAIN.LIVE_CLASSES.SERIES.END_DATE' | translate }}</label>
            <div class="iconbox mb-0">
              <svg-icon class="search-icon" src="asset/image/icon/calendar-due.svg"></svg-icon>
              <input
                type="text"
                id="endDate"
                name="endDate"
                #endDate="ngModel"
                class="form-control date-icon"
                placeholder="{{ 'VIEW.MAIN.LIVE_CLASSES.SERIES.END_DATE' | translate }}"
                [ngClass]="{'is-invalid': form.submitted && endDate.invalid, 'is-valid': form.submitted && endDate.valid }"
                [(ngModel)]="liveClassSeries.endDate"
                [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-custom', minDate: liveClassSeries?.startDate }"
                [appMinDateTime]="liveClassSeries?.startDate"
                [appMustBeEmpty]="liveClassSeries?.numberOfClassesLeft !== null && liveClassSeries?.numberOfClassesLeft !== undefined"
                [required]="liveClassSeries?.numberOfClassesLeft === null || liveClassSeries?.numberOfClassesLeft === undefined"
                [disabled]="!!liveClassSeries?.numberOfClassesLeft"
                bsDatepicker
              />

              <div class="invalid-feedback" *ngIf="form.submitted && endDate.invalid">
                <div *ngIf="endDate.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                <div *ngIf="endDate.errors['minDate']">
                  {{ 'VALIDATION.DATE_MAX' | translate: {dateString: liveClassSeries?.startDate?.toDateString()} }}
                </div>
                <div *ngIf="endDate.errors['mustBeEmpty']">
                  {{ 'VALIDATION.MUST_BE_EMPTY' | translate }}
                </div>
                <div *ngIf="endDate.errors['minDateTime']">
                  {{ 'VALIDATION.DATE_MAX' | translate: {dateString: liveClassSeries?.startDate?.toDateString()} }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="form-group col-12 col-lg-9">
        <label>{{ 'VIEW.MAIN.LIVE_CLASSES.SERIES.DAYS' | translate }}</label>
        <div class="mt-2">
          <app-days-form
            name="days"
            [ngClass]="{ 'is-invalid': form.submitted && days.invalid, 'is-valid': form.submitted && days.valid }"
            [(ngModel)]="liveClassSeries.daysOfWeek"
            #days="ngModel">
          </app-days-form>
          <div class="invalid-feedback" *ngIf="form.submitted && days.invalid">
            <div *ngIf="days.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="form-group col-12 col-lg-9">
        <!--suppress XmlInvalidId-->
        <label for="visibility">{{ 'VIEW.MAIN.LIVE_CLASSES.SERIES.VISIBILITY' | translate }}</label>
        <ng-select
          labelForId="visibility"
          name="visibility"
          #visibility="ngModel"
          placeholder="{{'VIEW.MAIN.LIVE_CLASSES.SERIES.VISIBILITY' | translate}}"
          [searchable]="false"
          [ngClass]="{'is-invalid': form.submitted && visibility.invalid, 'is-valid': form.submitted && visibility.valid }"
          [(ngModel)]="liveClassSeries.visibility"
          [multiple]="false" required>
          <ng-option *ngFor="let visibility of visibilities"
                     [value]="visibility.value">{{ visibility.label | translate }}
          </ng-option>
        </ng-select>
        <div class="invalid-feedback" *ngIf="form.submitted && visibility.invalid">
          <div *ngIf="visibility.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="d-flex flex-column flex-lg-row gap-lg-12 col-12 col-lg-9">
        <div class="form-group w-100">
          <label for="coach">{{ 'VIEW.MAIN.LIVE_CLASSES.COACH' | translate }}</label>
          <ng-select
            id="coach"
            name="coach"
            #coach="ngModel"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.CHOOSE_A_COACH' | translate}}"
            [searchable]="false"
            [ngClass]="{'is-invalid': form.submitted && coach.invalid, 'is-valid': form.submitted && coach.valid }"
            [(ngModel)]="liveClassSeries.coachIds"
            [multiple]="true"
            required
          >
            <ng-option *ngFor="let coach of coaches" [value]="coach.value"
            >{{ coach.label | translate }}
            </ng-option>
          </ng-select>
          <div class="invalid-feedback" *ngIf="form.submitted && coach.invalid">
            <div *ngIf="coach.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>

        <div class="form-group w-100">
          <label for="embed">{{ 'VIEW.MAIN.LIVE_CLASSES.EMBED' | translate }}</label>
          <input
            type="text"
            id="embed"
            name="embed"
            #embed="ngModel"
            class="form-control"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.PLACE_EMBED_LINK_HERE' | translate}}"
            [(ngModel)]="liveClassSeries.url"
            required
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
            [ngClass]="{'is-invalid': form.submitted && embed.invalid, 'is-valid': form.submitted && embed.valid }"
          />
          <div class="invalid-feedback" *ngIf="form.submitted && embed.invalid">
            <div *ngIf="embed.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="embed.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_255} }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="d-flex flex-column flex-lg-row gap-lg-12 col-12 col-lg-9">
        <div class="form-group w-100">
          <label for="intensity">{{ 'VIEW.MAIN.LIVE_CLASSES.INTENSITY' | translate }}</label>
          <ng-select
            id="intensity"
            name="intensity"
            #intensity="ngModel"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.CHOSE_THE_INTENSITY_LEVEL' | translate}}"
            [searchable]="false"
            [ngClass]="{'is-invalid': form.submitted && intensity.invalid, 'is-valid': form.submitted && intensity.valid }"
            [(ngModel)]="liveClassSeries.intensity"
            required
          >
            <ng-option *ngFor="let intensity of intensities" [value]="intensity.value"
            >{{ intensity.label | translate }}
            </ng-option>
          </ng-select>
          <div class="invalid-feedback" *ngIf="form.submitted && intensity.invalid">
            <div *ngIf="intensity.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
        <div class="form-group w-100">
          <label for="classLength">{{ 'VIEW.MAIN.LIVE_CLASSES.LENGTH' | translate }}</label>
          <ng-select
            id="classLength"
            name="classLength"
            #classLength="ngModel"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.CHOOSE_CLASS_LENGTH' | translate}}"
            [searchable]="false"
            [ngClass]="{'is-invalid': form.submitted && classLength.invalid, 'is-valid': form.submitted && classLength.valid }"
            [(ngModel)]="liveClassSeries.duration"
            required
          >
            <ng-option *ngFor="let length of lengths" [value]="length.value">{{ length.value }}</ng-option>
          </ng-select>
          <div class="invalid-feedback" *ngIf="form.submitted && classLength.invalid">
            <div *ngIf="classLength.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="d-flex flex-column flex-lg-row gap-lg-12 col-12 col-lg-9">
        <div class="form-group w-100">
          <label for="language">{{ 'VIEW.MAIN.LIVE_CLASSES.LANGUAGE' | translate }}</label>
          <ng-select
            id="language"
            name="language"
            #language="ngModel"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.CHOOSE_A_LANGUAGE' | translate}}"
            [searchable]="false"
            [ngClass]="{'is-invalid': form.submitted && language.invalid, 'is-valid': form.submitted && language.valid }"
            [(ngModel)]="liveClassSeries.language"
            required
          >
            <ng-option *ngFor="let language of languages" [value]="language.value"
            >{{ language.label | translate }}
            </ng-option>
          </ng-select>
          <div class="invalid-feedback" *ngIf="form.submitted && language.invalid">
            <div *ngIf="language.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
        <div class="form-group w-100">
          <label for="capacity">{{ 'VIEW.MAIN.LIVE_CLASSES.CAPACITY' | translate }}</label>
          <ng-select
            id="capacity"
            name="capacity"
            #capacity="ngModel"
            placeholder="{{ 'VIEW.MAIN.LIVE_CLASSES.NUMBER_OF_SEATS_AVAILABLE' | translate }}"
            [searchable]="false"
            [ngClass]="{'is-invalid': form.submitted && capacity.invalid, 'is-valid': form.submitted && capacity.valid }"
            [(ngModel)]="liveClassSeries.capacity"
          >
            <ng-option *ngFor="let capacity of capacities" [value]="capacity.value">
              {{ capacity.label | translate }}
            </ng-option>
          </ng-select>
          <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="d-flex flex-column flex-lg-row gap-lg-12 col-12 col-lg-9">
        <div class="form-group w-100">
          <label for="category">{{ 'VIEW.MAIN.LIVE_CLASSES.CATEGORY' | translate }}</label>
          <ng-select
            id="category"
            name="category"
            #category="ngModel"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.CHOOSE_A_CATEGORY' | translate}}"
            [searchable]="false"
            [ngClass]="{'is-invalid': form.submitted && category.invalid, 'is-valid': form.submitted && category.valid }"
            [(ngModel)]="liveClassSeries.category"
            required
          >
            <ng-option *ngFor="let category of categories" [value]="category.value"
            >{{ category.label | translate }}
            </ng-option>
          </ng-select>
          <div class="invalid-feedback" *ngIf="form.submitted && category.invalid">
            <div *ngIf="category.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
        <div class="w-100"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-9 mb-4">
        <div class="form-group">
          <label for="description">{{ 'VIEW.MAIN.LIVE_CLASSES.DESCRIPTION' | translate }}</label>
          <textarea
            id="description"
            name="description"
            #description="ngModel"
            class="form-control"
            placeholder="Text"
            rows="5"
            [maxlength]="Constant.TEXT_MAX_LENGTH_512"
            [ngClass]="{'is-invalid': form.submitted && description.invalid, 'is-valid': form.submitted && description.valid }"
            [(ngModel)]="liveClassSeries.description"
            required
          ></textarea>
          <div class="invalid-feedback" *ngIf="form.submitted && description.invalid">
            <div *ngIf="description.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
          <div class="d-flex justify-content-end">
            {{ description.value ? description.value.length : 0 }}/{{ Constant.TEXT_MAX_LENGTH_512 }}
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-9 mb-4">
        <app-dynamic-input
          [label]="'VIEW.MAIN.LIVE_CLASSES.RECOMMENDED_EQUIPMENT' | translate"
          [items]="liveClassSeries.liveClassSeriesNeeds"
          keyName="need"
          [addButtonText]="'VIEW.MAIN.LIVE_CLASSES.ADD_NEEDS' | translate"
          [placeholder]="'VIEW.MAIN.LIVE_CLASSES.ADD_NEEDS' | translate"
          (updatedItems)="handleUpdatedNeeds($event)"
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_128"
          [formSubmitted]="form.submitted"
        ></app-dynamic-input>

        <app-thumbnail-and-files-upload
          [thumbnailPlaceholder]="'VIEW.MAIN.LIVE_CLASSES.THUMBNAIL' | translate"
          [thumbnailMode]="true"
          [attachmentMode]="true"
          (startUpload)="handleStartUpload($event)"
          (imageId)="handleImageId($event)"
          (attachmentIds)="handleAttachmentIds($event)"
          [formSubmitted]="form.submitted"
          [isThumbnailRequired]="true"
          (thumbnailFormStatus)="handleThumbnailFormStatus($event)"
        ></app-thumbnail-and-files-upload>
      </div>
    </div>
  </form>
</section>
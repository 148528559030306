import { DateUtil } from 'app/util/DateUtil';
import { Transform, Type } from 'class-transformer';
import { BaseUserDTO } from 'app/data/dto/user/BaseUserDTO';
import { UserSubscriptionDTO } from 'app/data/dto/user/UserSubscriptionDTO';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';

export class UserProfileResponseAdminDTO extends BaseUserDTO {
  @Transform(DateUtil.dateTimeConversion)
  public createdDate: Date;

  public email: string;

  public enabled?: boolean;


  public nickname?: string;

  public phone?: string;

  public hasSelfRegistered?: boolean;

  public registrationType: RegistrationType;

  @Type(() => UserSubscriptionDTO)
  public subscription: UserSubscriptionDTO;
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageDTO } from 'app/data/dto/PageDTO';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { map } from 'rxjs/operators';
import { BaseVideoDTO } from 'app/data/dto/onDemandVideo/BaseVideoDTO';
import { VideoDictionaryPageCriteriaDTO } from 'app/data/dto/onDemandVideo/VideoDictionaryPageCriteriaDTO';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { Constant } from 'app/common/Constant';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { BaseRelatedUserDTO } from 'app/data/dto/user/BaseRelatedUserDTO';

@Injectable({ providedIn: 'root' })
export class DictionaryService {
  constructor(private http: HttpClient) {
  }

  public getFriendsPage(criteria: PageCriteriaDTO, silent: boolean = false): Observable<PageDTO<BaseRelatedUserDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    const context: HttpContext = new HttpContext();

    if (silent) {
      context.set(Constant.HTTP_SUPPRESS_LOADING_TOKEN, true);
    }

    return this.http.get(`${ ApplicationConfig.apiUrl }/friends/page`, { params: params }).pipe(
      map((response: any) => ObjectUtil.plainToClassFromExisting(new PageDTO<BaseRelatedUserDTO>(BaseRelatedUserDTO), response))
    );
  }

  public getVideoPage(criteria: VideoDictionaryPageCriteriaDTO, silent: boolean = false): Observable<PageDTO<BaseVideoDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    const context: HttpContext = new HttpContext();

    if (silent) {
      context.set(Constant.HTTP_SUPPRESS_LOADING_TOKEN, true);
    }

    return this.http.get<PageDTO<BaseVideoDTO>>(`${ ApplicationConfig.apiUrl }/dictionary/videos/page`, {
      params,
      context
    })
      .pipe(
        map((response: any) => {
          return ObjectUtil.plainToClassFromExisting(new PageDTO<BaseVideoDTO>(BaseVideoDTO), response);
        })
      );
  }
}
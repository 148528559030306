import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { WatchPartyType } from 'app/data/dto/watchParty/WatchPartyType';
import { Transform, Type } from 'class-transformer';
import { DateUtil } from 'app/util/DateUtil';
import { WatchPartyParticipantDTO } from 'app/data/dto/watchParty/WatchPartyParticipantDTO';
import { WatchPartyVideoDTO } from 'app/data/dto/watchParty/WatchPartyVideoDTO';
import { BaseUserDTO } from 'app/data/dto/user/BaseUserDTO';

export class WatchPartyDTO extends IdentifiableObjectDTO<number> {
  public type: WatchPartyType;

  public isHost: boolean;

  public capacity: number;

  @Type(() => WatchPartyVideoDTO)
  public video: WatchPartyVideoDTO;

  @Type(() => BaseUserDTO)
  public host: BaseUserDTO;

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  @Transform(DateUtil.dateTimeConversion)
  public endDate: Date;

  public ended: boolean;

  @Type(() => WatchPartyParticipantDTO)
  public participants: WatchPartyParticipantDTO[];
}
import { Component } from '@angular/core';
import { BaseModal } from 'app/util/extension/BaseModal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { WatchPartyModel } from 'app/model/WatchPartyModel';
import { State } from 'app/common/State';
import { StateUtil } from 'app/util/StateUtil';

@Component({
  selector: 'app-watch-party-details-modal',
  templateUrl: './WatchPartyDetailsModalComponent.html',
  styleUrls: [ './WatchPartyDetailsModalComponent.scss' ]
})
export class WatchPartyDetailsModalComponent extends BaseModal<void> {
  public watchParty: WatchPartyDTO;

  constructor(protected bsModalRef: BsModalRef,
              public readonly watchPartyModel: WatchPartyModel,
              private readonly stateUtil: StateUtil) {
    super();
  }

  public dropEvent(data: WatchPartyDTO): void {
    this.watchPartyModel.drop(data.id)
      .subscribe(() => this.ok());
  }

  public cancelEvent(data: WatchPartyDTO): void {
    this.watchPartyModel.cancel(data.id)
      .subscribe(() => this.ok());
  }

  public goToEvent(data: WatchPartyDTO): void {
    this.stateUtil.goToState(State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.EVENT, { id: data.id });
  }
}

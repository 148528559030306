import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { WatchPartyCreateDTO } from 'app/data/dto/watchParty/WatchPartyCreateDTO';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { WatchPartyUpdateDTO } from 'app/data/dto/watchParty/WatchPartyUpdateDTO';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { map } from 'rxjs/operators';
import { PageDTO } from 'app/data/dto/PageDTO';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { WatchPartyPageCriteriaDTO } from 'app/data/dto/watchParty/WatchPartyPageCriteriaDTO';
import { WatchPartyEventDTO } from 'app/data/dto/watchParty/WatchPartyEventDTO';
import { SseClient } from 'ngx-sse-client';
import { Constant } from 'app/common/Constant';

@Injectable({ providedIn: 'root' })
export class WatchPartyService {

  constructor(private readonly http: HttpClient,
              private readonly sseClient: SseClient) {}

  public getById(id: number): Observable<WatchPartyDTO> {
      return this.http.get<WatchPartyDTO>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }`)
      .pipe(
        map((result: WatchPartyDTO) => ObjectUtil.plainToClass(WatchPartyDTO, result))
      );
  }

  public getPage(criteria: WatchPartyPageCriteriaDTO): Observable<PageDTO<WatchPartyDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });
    return this.http.get(`${ ApplicationConfig.apiUrl }/watchparties/page`, { params: params }).pipe(
    map((response: any) => ObjectUtil.plainToClassFromExisting(new PageDTO<WatchPartyDTO>(WatchPartyDTO), response))
    );
  }

  public create(data: WatchPartyCreateDTO): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/watchparties`, data);
  }

  public update(id: number, data: WatchPartyUpdateDTO): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }`, ObjectUtil.classToPlain(data));
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }`);
  }

  public drop(id: number): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }/drop`, undefined);
  }

  public end(id: number): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }/end`, undefined);
  }

  public reject(id: number): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }/reject`, undefined);
  }

  public accept(id: number): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }/accept`, undefined);
  }

  public signup(id: number): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/watchparties/${ id }/signup`, undefined);
  }

  public connect(id: number): Observable<WatchPartyEventDTO> {
    const messagesSubject: Subject<WatchPartyEventDTO> = new Subject<WatchPartyEventDTO>();

    const headers: HttpHeaders = new HttpHeaders(
      { Accept: 'text/event-stream' }
    );

    this.sseClient.stream(
      `${ApplicationConfig.apiUrl}/watchparties/${id}:join`,
      { keepAlive: true, reconnectionDelay: 1_000, responseType: 'event' },
      { headers },
      'GET'
    )
    .subscribe(
      (event) => {
        if (event.type === 'error') {
          messagesSubject.error((event as ErrorEvent).error);
        }
        else {
          messagesSubject.next(ObjectUtil.plainToClass(WatchPartyEventDTO, JSON.parse((event as MessageEvent).data)));
        }
      },
      (error) => messagesSubject.error(error));

    return messagesSubject.asObservable();
  }

  public getLastEvent(id: number): Observable<WatchPartyEventDTO> {
    return this.http.get<WatchPartyEventDTO>(`${ApplicationConfig.apiUrl}/watchparties/${id}/lastevent`);
  }

  public sendEvent(id: number, event: WatchPartyEventDTO): Observable<void> {
    const context: HttpContext = new HttpContext().set(Constant.HTTP_SUPPRESS_LOADING_TOKEN, true);

    return this.http.post<void>(`${ApplicationConfig.apiUrl}/watchparties/${id}:sendevent`, ObjectUtil.classToPlain(event), { context });
  }

}
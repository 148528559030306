import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'dateNowRelation' })
export class DateNowRelationPipe implements PipeTransform {

  public transform(value: Date): any {
    if (value) {
      if (moment(value).isSameOrBefore()) {
        return moment(value).fromNow();
      }
      else if (moment(value).isSameOrAfter()) {
        return moment(value).toNow();
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

}

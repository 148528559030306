export enum NotificationType {
  REMINDER = 'REMINDER',
  CLASS_CANCELLATION = 'CLASS_CANCELLATION',
  CLASS_RESCHEDULE = 'CLASS_RESCHEDULE',
  NEW_CLASSES_IN_SERIES = 'NEW_CLASSES_IN_SERIES',
  ENROLLMENT_FROM_WAITING_LIST = 'ENROLLMENT_FROM_WAITING_LIST',
  FRIEND_INVITATION_ACCEPTANCE = 'FRIEND_INVITATION_ACCEPTANCE',
  NEW_FRIEND_INVITATION = 'NEW_FRIEND_INVITATION',
  WATCH_PARTY_INVITATION = 'WATCH_PARTY_INVITATION',
  WATCH_PARTY_CANCELED = 'WATCH_PARTY_CANCELED'
}
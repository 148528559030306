import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { DataGridContext } from 'app/component/ui/dataGrid/DataGridContext';
import { DataGridAction } from 'app/component/ui/dataGrid/DataGridAction';
import { ResponsiveDataGridColumn } from 'app/component/ui/responsiveDataGrid/ResponsiveDataGridColumn';
import { DataGridInterface } from 'app/component/ui/dataGrid/DataGridInterface';
import { ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-responsive-data-grid',
  templateUrl: 'ResponsiveDataGridComponent.html',
  styleUrls: [ 'ResponsiveDataGridComponent.scss' ]
})
export class ResponsiveDataGridComponent implements OnChanges, OnInit, DataGridInterface {

  private static readonly ITEMS_PER_PAGE_ALL_VALUE: number = 99999;

  public itemsPerPageAllValue: number = ResponsiveDataGridComponent.ITEMS_PER_PAGE_ALL_VALUE;

  public internalId: number = _.random(0, 10000);

  public internalPage: number = 1;

  public internalSearchPhrase: string;

  public internalItemsPerPage: number;

  public Math: Math = Math;

  @Input()
  public data: any[];

  @Input()
  public criteria: PageCriteriaDTO;

  @Input()
  public context: DataGridContext;

  @Input()
  public columns: ResponsiveDataGridColumn[];

  @Input()
  public pageTotal: number = 0;

  @Input()
  public itemTotal: number = 0;

  @Input()
  public itemLabel: string = 'COMPONENT.DATA_GRID.ITEMS';

  @Input()
  public sortingEnabled: boolean = false;   // implement someday?

  @Input()
  public selectionEnabled: boolean = true;

  @Input()
  public itemsPerPageOptions: number[] = [ 10, 25, 50, 100, ResponsiveDataGridComponent.ITEMS_PER_PAGE_ALL_VALUE ];

  @Input()
  public showFilters: boolean = true;

  @Input()
  public showSearch: boolean = true;

  @Input()
  public showItemsPerPage: boolean = true;

  @Input()
  public showTotalItems: boolean = true;

  @Input()
  public showPagination: boolean = true;

  @Input()
  public useAlternativePagination: boolean = true;

  @Output()
  public itemSelected = new EventEmitter<any>();

  @Output()
  public gridAction = new EventEmitter<any>();

  @Output()
  public criteriaChanged = new EventEmitter<PageCriteriaDTO>();

  constructor() {
  }

  public ngOnInit(): void {
    if (!this.context) {
      this.context = new DataGridContext();
    }

    this.context.dataGridHost = this;
    this.context.dataGridAction = this.sendGridAction.bind(this);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      if (changes.data.currentValue === null) {
        this.data = [];
      }
    }

    if (changes.criteria?.currentValue) {
      this.internalPage = changes.criteria.currentValue.page + 1;
      this.internalItemsPerPage = changes.criteria.currentValue.pageSize;
      this.internalSearchPhrase = changes.criteria.currentValue.searchPhrase;
    }
  }

  public onRowClicked(data: any): void {
    if (this.selectionEnabled) {
      this.itemSelected.emit(data);
    }
  }

  public onItemsPerPageChange(newValue: number): void {
    const newCriteria = _.cloneDeep(this.criteria);
    newCriteria.pageNumber = 0;
    newCriteria.pageSize = this.internalItemsPerPage;

    this.criteriaChanged.emit(newCriteria);
  }

  public onSearchCommitted(event: Event | KeyboardEvent): void {
    const newCriteria = _.cloneDeep(this.criteria);
    newCriteria.pageNumber = 0;
    newCriteria.searchPhrase = this.internalSearchPhrase;
    this.criteriaChanged.emit(newCriteria);
  }

  public clearSearch(): void {
    const newCriteria = _.cloneDeep(this.criteria);
    newCriteria.pageNumber = 0;
    newCriteria.searchPhrase = undefined;
    this.criteriaChanged.emit(newCriteria);
  }

  public onPageChanged(event: PageChangedEvent): void {
    const newPage: number = event.page;
    const newPageIndex: number = newPage - 1;

    const newCriteria = _.cloneDeep(this.criteria);
    newCriteria.pageNumber = newPageIndex;
    this.criteriaChanged.emit(newCriteria);
  }

  public sendGridAction(actionName: string, args: any[]): void {
    this.gridAction.emit(new DataGridAction(actionName, args));
  }

  public getParams(obj: any, path: string): any {
    const value: any = this.getValue(obj, path);
    const params: ICellRendererParams = {
      value,
      valueFormatted: undefined,
      getValue: undefined,
      setValue: undefined,
      formatValue: undefined,
      data: obj,
      node: undefined,
      colDef: undefined,
      column: undefined,
      $scope: undefined,
      rowIndex: undefined,
      api: undefined,
      columnApi: undefined,
      context: this.context,
      refreshCell: undefined,
      eGridCell: undefined,
      eParentOfValue: undefined,
      registerRowDragger: undefined,
      addRenderedRowListener: undefined
    };

    return params;
  }

  public getValue(obj: any, path: string): any {
    return _.get(obj, path);
  }

  public getValueFormatterValue(obj: any, path: string, valueFormatter: (params: ValueFormatterParams) => any): void {
    const value: any = this.getValue(obj, path);
    const updatedParams: ValueFormatterParams = {
      context: this.context,
      data: obj,
      value,
      node: undefined,
      colDef: undefined,
      column: undefined,
      api: undefined,
      columnApi: undefined
    };

    return valueFormatter(updatedParams);
  }

}


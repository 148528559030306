import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouteTab } from 'app/component/ui/routeTabs/RouteTab';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { OnDemandTourWizardAnchor } from 'app/data/local/OnDemandTourWizardAnchor';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-route-tabs',
  template: `
    <app-tabs
      [translationPrefix]="translationPrefix"
      [tabs]="tabs"
      [tabDefinition]="tabDefinition"
      [activeTab]="currentTab"
      (tabChanged)="onTabChanged($event)"
    ></app-tabs>
  `,
})
export class RouteTabsComponent implements OnInit, OnDestroy {
  @Input() translationPrefix?: string;

  @Input() tourWizardAnchor?: any;

  @Input() set routeTabs(value: RouteTab[]) {
    this.tabDefinition = value;
    this.tabs = value?.map(s => s.name);
  }

  public tabDefinition: RouteTab[];
  public tabs: string[];
  public currentTab: string;

  private subscription: Subscription = new Subscription();

  constructor(private readonly state: StateService,
              private readonly applicationModel: ApplicationModel,
              private readonly uIRouterGlobals: UIRouterGlobals) {
  }

  public ngOnInit(): void {
    this.setCurrentTab();

    this.subscription.add(
      this.applicationModel.currentState$
        .subscribe(() => this.setCurrentTab())
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onTabChanged(name: string): void {
    const current: RouteTab[] = this.tabDefinition.filter(s => s.name === name);

    this.state.go(current[0].state);
  }

  private setCurrentTab(): void {
    const current: RouteTab[] = this.tabDefinition.filter(s => s.state === this.uIRouterGlobals.current.name);

    if (current?.length > 0) {
      this.currentTab = current[0].name;
    }
  }

  protected readonly OnDemandTourWizardAnchor = OnDemandTourWizardAnchor;
}

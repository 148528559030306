<div class="p-4">
  <div class="filter-panel">
    <div class="row w-100 no-gutters">
      <div class="col-12 mb-4 d-flex justify-content-between align-items-center">
        <p (click)="onClearFiltersClick()" class="mb-0 btn p-0">
          {{ 'COMPONENT.VIDEO_FILTERS.CLEAR_FILTERS' | translate }}
        </p>
        <svg-icon class="d-flex btn m-0 p-0" (click)="onHideFiltersClick()" src="asset/image/icon/x.svg"></svg-icon>
      </div>

      <div class="col-12 col-sm mr-4">
        <p class="font-weight-600 mb-2">{{ "COMPONENT.VIDEO_FILTERS.VIDEO_SUBCATEGORY" | translate }}</p>
        <div class="form-group">
          <ng-select
            id="typeSearch"
            name="typeSearch"
            [placeholder]="'COMMON.SEARCH' | translate"
            [style.--icon]="'url(/asset/image/icon/search.svg)'"
            [searchable]="true"
            [multiple]="true"
            [(ngModel)]="filters.type.selectedTypes"
          >
            <ng-option *ngFor="let subcategory of subcategories" [value]="subcategory.value">
              {{ subcategory.label | translate }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="col-12 col-sm mr-4">
        <p class="font-weight-600 mb-2">{{ "COMPONENT.VIDEO_FILTERS.COACH" | translate }}</p>
        <div class="form-group">
          <ng-select
            id="coachSearch"
            name="coachSearch"
            [placeholder]="'COMMON.SEARCH' | translate"
            [style.--icon]="'url(/asset/image/icon/search.svg)'"
            [searchable]="true"
            [multiple]="true"
            [(ngModel)]="filters.coaches.selectedCoaches"
          >
            <ng-option *ngFor="let coach of coaches" [value]="coach.id">
              {{ coach.user.firstName }} {{ coach.user.lastName }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div *ngIf="displayLanguages" class="col-12 col-sm col-md-3 col-xl-3">
        <p class="font-weight-600 mb-2">{{ "COMPONENT.VIDEO_FILTERS.LANGUAGE" | translate }}</p>
        <div class="custom-control custom-checkbox d-flex flex-column">
          <input
            type="checkbox"
            class="custom-control-input"
            id="language"
            name="language"
            [(ngModel)]="filters.languages.allChecked"
            (change)="onAllLanguageChange()"
          />
          <label class="custom-control-label" for="language"> {{ "COMPONENT.VIDEO_FILTERS.ALL" | translate }} </label>
        </div>
        <ng-container *ngFor="let language of languages">
          <div class="custom-control custom-checkbox d-flex flex-column">
            <input
              type="checkbox"
              class="custom-control-input"
              [id]="language.value"
              [name]="language.value"
              [(ngModel)]="this.filters.languages.checked[language.value]"
              (change)="onLanguageCheckboxChange()"
            />
            <label class="custom-control-label" [for]="language.value">{{ language.label | translate }}</label>
          </div>
        </ng-container>
      </div>
      <div class="col-12 col-sm mr-4">
        <p class="font-weight-600">{{ "COMPONENT.VIDEO_FILTERS.INTENSITY" | translate }}</p>
        <div class="custom-control custom-checkbox d-flex flex-column">
          <input
            type="checkbox"
            class="custom-control-input"
            id="intensity"
            name="intensity"
            [(ngModel)]="filters.intensities.allChecked"
            (change)="onAllIntensitiesChange()"
          />
          <label class="custom-control-label" for="intensity"> {{ "COMPONENT.VIDEO_FILTERS.ALL" | translate }} </label>
        </div>
        <ng-container *ngFor="let intensity of intensities">
          <div class="custom-control custom-checkbox d-flex flex-column">
            <input
              type="checkbox"
              class="custom-control-input"
              [id]="intensity.value"
              [name]="intensity.value"
              [(ngModel)]="this.filters.intensities.checked[intensity.value]"
              (change)="onIntensitiesCheckboxChange()"
            />
            <label class="custom-control-label" [for]="intensity.value">{{ intensity.label | translate }}</label>
          </div>
        </ng-container>
      </div>

      <!--      Tier 1-->
      <div class="col-12 col-sm mr-4" *ngIf="displayTier">
        <p class="font-weight-600">{{ "VIEW.MAIN.ON_DEMAND.TIER_VISIBILITY" | translate }}</p>
        <div class="custom-control custom-checkbox d-flex flex-column">
          <input
            type="checkbox"
            class="custom-control-input"
            id="accessibleForFirstTierOnly"
            name="accessibleForFirstTierOnly"
            [(ngModel)]="filters.tierVisibility.checked.accessibleForFirstTierOnly"
            (change)="filters.tierVisibility.checked.accessibleForOverFirstTierOnly = undefined"
          />
          <label class="custom-control-label" for="accessibleForFirstTierOnly"> {{ "VIEW.MAIN.ON_DEMAND.AVAILABLE_FOR_TIER_1" | translate }} </label>
        </div>

        <div class="custom-control custom-checkbox d-flex flex-column">
          <input
            type="checkbox"
            class="custom-control-input"
            id="accessibleForOverFirstTierOnly"
            name="accessibleForOverFirstTierOnly"
            [(ngModel)]="filters.tierVisibility.checked.accessibleForOverFirstTierOnly"
            (change)="filters.tierVisibility.checked.accessibleForFirstTierOnly = undefined"
          />
          <label class="custom-control-label" for="accessibleForOverFirstTierOnly"> {{ "VIEW.MAIN.ON_DEMAND.AVAILABLE_OVER_TIER_1" | translate }} </label>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-primary"
                (click)="onShowFiltersClick()">{{ 'VIEW.MAIN.ON_DEMAND.SHOW' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'unescape' })
export class UnescapePipe implements PipeTransform {

  public transform(value: string): any {
    return _.unescape(value);
  }

}

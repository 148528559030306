<ng-template #headingTemplate>
  <h1>{{ video?.title | replaceEmpty }}</h1>
  <button class="btn btn-outline-primary w-100 w-sm-auto" (click)="goBack()">
    {{ 'COMMON.BACK' | translate }}
  </button>
</ng-template>

<section class="section space-y-4">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl d-flex flex-column pb-2">
        <figure id="vimeo" class="mb-2"></figure>

        <article id="video-details" class="mb-4">
          <div [collapse]="!videoDetailsAreVisible">
            <dl class="row no-gutters mb-4">
              <div class="col-9">
                <dt class="font-weight-normal text-grey">{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.DESCRIPTION' | translate }}</dt>
                <dd>{{ video?.description | replaceEmpty }}</dd>
              </div> <!-- /.col -->

              <div class="col-3">
                <dt class="font-weight-normal text-grey">{{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.COACH' | translate }}</dt>
                <dd>
                  <p *ngFor="let couch of video?.coaches" class="mb-0">{{ couch.user | fullName }}</p>
                </dd>
              </div> <!-- /.col -->
            </dl> <!-- /.row -->
          </div>

          <button type="button"
                  class="btn btn-link"
                  (click)="videoDetailsAreVisible = !videoDetailsAreVisible"
                  [attr.aria-expanded]="videoDetailsAreVisible"
          >
            {{
              (videoDetailsAreVisible
                ? 'VIEW.MAIN.ON_DEMAND.PROGRAM.HIDE_DETAILS'
                : 'VIEW.MAIN.ON_DEMAND.PROGRAM.SHOW_DETAILS') | translate
            }}
            <svg-icon src="asset/image/icon/chevron-down.svg"></svg-icon>
          </button>
        </article>

        <app-rating-form *ngIf="video.ratingEnabled" (rate)="rateVideo($event)"></app-rating-form>
      </div> <!-- /.col -->

      <div *ngIf="program" class="col-12 col-xl-4">
        <aside id="vod-list" class="card h-100">
          <div class="card-header">
            {{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.VIDEOS_IN_PROGRAM' | translate }}
          </div> <!-- /.card-header -->
          <div class="card-body space-y-3">
            <div *ngFor="let videoProgram of program.videoPrograms" class="vod-item"
                 [class.active]="currentVideoId === videoProgram.video.id"
            >
              <div class="vod-details">
                <img class="vod-image" [src]="videoProgram.video.thumbnail.defaultAsset.url" alt=""/>

                <div class="vod-overlay p-3">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="setCurrentVideoId(videoProgram.video.id)"
                    [disabled]="currentVideoId === videoProgram.video.id"
                  >
                        <span *ngIf="currentVideoId !== videoProgram.video.id">
                          {{ 'VIEW.MAIN.ON_DEMAND.PLAY' | translate }}
                        </span>
                    <span *ngIf="currentVideoId === videoProgram.video.id">
                          {{ 'VIEW.MAIN.ON_DEMAND.PROGRAM.PLAYING' | translate }}
                        </span>
                    <svg-icon src="asset/image/icon/player-play.svg"></svg-icon>
                  </button>
                </div>
              </div>

              <h5 class="vod-title">{{ videoProgram.video.title }}</h5>
            </div>
          </div> <!-- /.card-body -->
        </aside> <!-- /.card -->
      </div> <!-- /.col -->
    </div> <!-- /.row -->
  </div> <!-- /.container-fluid -->
</section>

import { Injectable } from '@angular/core';
import { Constant } from 'app/common/Constant';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';

@Injectable({ providedIn: 'root' })
export class UserUtil {
  public determineUsernameType(username: string): RegistrationType {
    if (username.match(Constant.EMAIL_ADDRESS_PATTERN)) {
      return RegistrationType.EMAIL;
    }
    else {
      return RegistrationType.PHONE;
    }
  }

  public formatUsername(username: string, usernameType: RegistrationType): string {
    username = username.replace(/ /g, '');

    if (usernameType === RegistrationType.PHONE && !username.startsWith(Constant.PHONE_NUMBER_RULES.prefix)) {
      username = Constant.PHONE_NUMBER_RULES.prefix + username;
    }

    return username;
  }
}

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { minDateTimeValidator } from 'app/util/validator/MinDateTimeValidator';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { State } from 'app/common/State';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { WatchPartyModel } from 'app/model/WatchPartyModel';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { WatchPartyUpdateDTO } from 'app/data/dto/watchParty/WatchPartyUpdateDTO';
import { Transition } from '@uirouter/angular';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { WatchPartyParticipantDTO } from 'app/data/dto/watchParty/WatchPartyParticipantDTO';

@Component({
  selector: 'app-watch-party-edit',
  templateUrl: './WatchPartyEditComponent.html'
})
export class WatchPartyEditComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public State: typeof State = State;

  public id: number;
  public minDate: Date = new Date();

  public form: FormGroup = this.fb.group({
    type: [ undefined, Validators.required ],
    category: [ undefined ],
    subcategory: [ undefined ],
    video: [ undefined, Validators.required ],
    startDate: [ undefined, [ Validators.required, minDateTimeValidator(this.minDate) ] ],
    participants: [ undefined, [ Validators.required, Validators.maxLength(3) ] ]
  });

  constructor(private readonly portalUtil: PortalUtil,
              private readonly fb: FormBuilder,
              private readonly stateUtil: StateUtil,
              private readonly viewUtil: ViewUtil,
              private readonly transition: Transition,
              private readonly watchPartyModel: WatchPartyModel,
              private readonly viewContainerRef: ViewContainerRef) {
    this.id = this.transition.params().id;

    this.watchPartyModel.getById(this.id)
      .subscribe((watchParty: WatchPartyDTO) => {
        if (!watchParty.isHost) {
          this.stateUtil.goToState(State.PRELIMINARY.ERROR.ACCESS_DENIED);
          return;
        }

        const participants: WatchPartyParticipantDTO[] = watchParty.participants.filter(participant => {
          return participant.id !== watchParty.host.id
        });
        this.form.patchValue({...watchParty, participants});
      });
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public save(): void {
    if (this.form.valid) {
      const payload = ObjectUtil.plainToClass(WatchPartyUpdateDTO, this.form.value);

      this.watchPartyModel.update(this.id, payload)
        .subscribe({
          next: () => this.stateUtil.goBack(),
          error: (err) => this.viewUtil.handleServerError(err)
        });
    }
  }
}

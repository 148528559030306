export class DataGridAction {

  public actionName: string;

  public args: any[];

  constructor(actionName: string, args: any[] = null) {
    this.actionName = actionName;
    this.args = args;
  }

}

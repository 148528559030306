<ul *ngIf="tabs?.length" class="nav nav-tab mb-4 py-2">

  <li *ngFor="let tab of tabs; let i = index" class="nav-item">
    <button class="nav-link btn btn-light" [class.active-tab]="tab === activeTab" (click)="onTabChanged(tab)" *ngIf="!tabDefinition?.[i]?.tourWizardAnchor">
      {{ (translationPrefix ? translationPrefix + tab : tab) | translate }}
    </button>
    <button class="nav-link btn btn-light" [class.active-tab]="tab === activeTab" (click)="onTabChanged(tab)" *ngIf="tabDefinition?.[i]?.tourWizardAnchor" [tourWizardAnchor]="tabDefinition?.[i]?.tourWizardAnchor">
      {{ (translationPrefix ? translationPrefix + tab : tab) | translate }}
    </button>
  </li>
</ul>

<div class="d-flex gap-4 flex-wrap justify-content-end mb-4 w-100">
  <button *ngIf="!editMode" class="btn btn-primary edit-btn" (click)="editProfile()">
    <div class="d-flex align-items-center justify-content-center">
      <svg-icon class="d-flex mr-2" src="asset/image/icon/edit.svg"></svg-icon>
      <span>{{ 'COMMON.EDIT' | translate }}</span>
    </div>
  </button>
  <button *ngIf="editMode" class="btn btn-outline-primary edit-btn" (click)="cancelEdit()">
    <div class="d-flex align-items-center justify-content-center">
      <svg-icon class="d-flex mr-2" src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.CANCEL' | translate }}</span>
    </div>
  </button>
  <button *ngIf="editMode" class="btn btn-primary edit-btn" (click)="saveChanges()">
    <div class="d-flex align-items-center justify-content-center">
      <svg-icon class="d-flex mr-2" src="asset/image/icon/edit.svg"></svg-icon>
      <span>{{ 'COMMON.SAVE' | translate }}</span>
    </div>
  </button>
</div>
<form
  *ngIf="userProfile"
  #userForm="ngForm"
  id="account-profile"
  class="row no-gutters row-cols-1 row-cols-lg-2 w-100 w-lg-75"
  novalidate
  (keyup.enter)="saveChanges()"
>
  <div class="form-group col pr-lg-6">
    <label for="firstName">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.FIRST_NAME' | translate }}</label>
    <input
      id="firstName"
      name="firstName"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.FIRST_NAME' | translate}}"
      [ngClass]="{'is-invalid': userForm.submitted && firstName.invalid, 'is-valid': userForm.submitted && firstName.valid }"
      #firstName="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="userProfile.user.firstName"
      required
      [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
    />
    <div class="invalid-feedback" *ngIf="userForm.submitted && firstName.invalid">
      <div *ngIf="firstName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
      <div *ngIf="firstName.errors['maxLength']">
        {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
      </div>
    </div>
  </div>
  <div class="form-group col pr-lg-6">
    <label for="lastName">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.LAST_NAME' | translate }}</label>
    <input
      id="lastName"
      name="lastName"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.LAST_NAME' | translate}}"
      [ngClass]="{'is-invalid': userForm.submitted && lastName.invalid, 'is-valid': userForm.submitted && lastName.valid }"
      #lastName="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="userProfile.user.lastName"
      required
      [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
    />
    <div class="invalid-feedback" *ngIf="userForm.submitted && lastName.invalid">
      <div *ngIf="lastName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
      <div *ngIf="lastName.errors['maxLength']">
        {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
      </div>
    </div>
  </div>
  <div class="form-group col pr-lg-6">
    <label for="nickname">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.NICKNAME' | translate }}</label>
    <input
      id="nickname"
      name="nickname"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.NICKNAME' | translate}}"
      [ngClass]="{'is-invalid': userForm.submitted && nickname.invalid, 'is-valid': userForm.submitted && userProfile.user.nickname }"
      #nickname="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="userProfile.user.nickname"
      [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
    />
    <div class="invalid-feedback" *ngIf="userForm.submitted && nickname.invalid">
      <div *ngIf="nickname.errors['maxLength']">
        {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
      </div>
    </div>
    <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
  </div>
  <div class="form-group col pr-lg-6">
    <label for="birthDate">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.DATE_OF_BIRTH' | translate }}</label>
    <input
      id="dateOfBirth"
      name="dateOfBirth"
      type="text"
      class="form-control"
      [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-custom' }"
      bsDatepicker
      [ngClass]="{'is-invalid': userForm.submitted && dateOfBirth.invalid, 'is-valid': userForm.submitted && dateOfBirth.valid }"
      #dateOfBirth="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="userProfile.dateOfBirth"
      required
      [appMaxDate]="maxDateOfBirth"
    />
    <div class="invalid-feedback" *ngIf="userForm.submitted && dateOfBirth.invalid">
      <div *ngIf="dateOfBirth.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
      <div *ngIf="dateOfBirth.errors['maxDate']">
        {{ 'VALIDATION.DATE_MAX' | translate: {dateString: maxDateOfBirth.toDateString()} }}
      </div>
    </div>
  </div>
  <div class="form-group col pr-lg-6">
    <label for="gender">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.GENDER' | translate }}</label>
    <ng-select
      id="gender"
      name="gender"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.GENDER' | translate}}"
      [searchable]="false"
      [ngClass]="{'is-valid': userForm.submitted && gender.valid && userProfile.gender}"
      #gender="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="userProfile.gender"
    >
      <ng-option *ngFor="let option of userGenderSelectList" [value]="option.value"
      >{{ option.label | translate }}
      </ng-option
      >
    </ng-select>
    <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
  </div>

  <!-- TODO 2nd phase -->
  <!-- <div class="form-group col pr-lg-6">
    <label for="country">{{'VIEW.MAIN.ACCOUNT.PROFILE.COUNTRY' | translate}}</label>
    <ng-select
      id="country"
      name="country"
      #country="ngModel"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.COUNTRY' | translate}}"
      [searchable]="false"
      [ngClass]="{'is-valid': userForm.submitted && country.valid && userProfile.address.country}"
      [(ngModel)]="userProfile.address.country"
      [disabled]="!editMode"
    >
      <ng-option *ngFor="let country of countries" [value]="country.value">{{ country.label | translate }} </ng-option>
    </ng-select>

    <div class="text-sm-light color-orange ml-4 mt-1">* {{'COMMON.OPTIONAL' | translate}}</div>
  </div> -->

  <div class="form-group col pr-lg-6">
    <label for="street">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.STREET' | translate }}</label>
    <input
      id="street"
      name="street"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.STREET' | translate}}"
      [ngClass]="{'is-invalid': userForm.submitted && street.invalid, 'is-valid': userForm.submitted && userProfile.address.street}"
      #street="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="userProfile.address.street"
      [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
    />
    <div class="invalid-feedback" *ngIf="userForm.submitted && street.invalid">
      <div *ngIf="street.errors['maxLength']">
        {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
      </div>
    </div>
  </div>

  <div class="form-group col pr-lg-6">
    <label for="city">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.CITY' | translate }}</label>
    <input
      id="city"
      name="city"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.CITY' | translate}}"
      [ngClass]="{'is-invalid': userForm.submitted && city.invalid, 'is-valid': userForm.submitted && userProfile.address.city }"
      #city="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="userProfile.address.city"
      [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
    />
    <div class="invalid-feedback" *ngIf="userForm.submitted && city.invalid">
      <div *ngIf="city.errors['maxLength']">
        {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
      </div>
    </div>
    <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
  </div>
  <div class="form-group col pr-lg-6">
    <label for="state">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.STATE' | translate }}</label>
    <input
      id="state"
      name="state"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.STATE' | translate}}"
      [ngClass]="{'is-invalid': userForm.submitted && state.invalid, 'is-valid': userForm.submitted && userProfile.address.state }"
      #state="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="userProfile.address.state"
      [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
    />
    <div class="invalid-feedback" *ngIf="userForm.submitted && state.invalid">
      <div *ngIf="state.errors['maxLength']">
        {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
      </div>
    </div>
    <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
  </div>
  <div class="form-group col pr-lg-6">
    <label for="zipCode">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.ZIP' | translate }}</label>
    <input
      id="zipCode"
      name="zipCode"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.ZIP' | translate}}"
      [ngClass]="{'is-valid': userForm.submitted && zipCode.valid && userProfile.address.zipCode }"
      #zipCode="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="userProfile.address.zipCode"
    />
    <div class="invalid-feedback" *ngIf="userForm.submitted && zipCode.invalid">
      <div *ngIf="zipCode.errors['maxLength']">
        {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
      </div>
    </div>
    <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
  </div>
  <div class="form-group col pr-lg-6">
    <label for="email">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.EMAIL' | translate }}</label>
    <input
      id="email"
      name="email"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.EMAIL' | translate}}"
      [ngClass]="{'is-invalid': userForm.submitted && email.invalid, 'is-valid': userForm.submitted && email.valid }"
      #email="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="userProfile.user.email"
      [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
      [required]="userProfile.user.registrationType === RegistrationType.EMAIL"
      ngvemail
    />
    <div class="text-sm-light color-orange ml-4 mt-1"
         *ngIf="userProfile.user.registrationType !== RegistrationType.EMAIL">* {{ 'COMMON.OPTIONAL' | translate }}
    </div>

    <div class="invalid-feedback" *ngIf="userForm.submitted && email.invalid">
      <div *ngIf="email.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
      <div *ngIf="email.errors['maxLength']">
        {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
      </div>
      <div *ngIf="email.errors['email']">{{ 'VALIDATION.EMAIL' | translate }}</div>
    </div>
  </div>

  <div class="form-group col pr-lg-6">
    <label for="phoneNo">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.PHONE' | translate }}</label>
    <input
      id="phoneNo"
      name="phoneNo"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.PHONE' | translate}}"
      [ngClass]="{'is-invalid': userForm.submitted && phone.invalid, 'is-valid': userForm.submitted && userProfile.user.phone }"
      #phone="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="userProfile.user.phone"
      [showMaskTyped]="true"
      [prefix]="Constant.PHONE_NUMBER_RULES.prefix + ' '"
      [shownMaskExpression]="Constant.PHONE_NUMBER_RULES.expression"
      [mask]="Constant.PHONE_NUMBER_RULES.mask"
      [pattern]="Constant.PHONE_NUMBER_RULES.pattern"
      [required]="userProfile.user.registrationType === RegistrationType.PHONE"
    />
    <div class="text-sm-light color-orange ml-4 mt-1"
         *ngIf="userProfile.user.registrationType !== RegistrationType.PHONE">* {{ 'COMMON.OPTIONAL' | translate }}
    </div>

    <div class="invalid-feedback" *ngIf="userForm.submitted && phone.invalid">
      <div *ngIf="phone.errors['pattern']">{{ 'VALIDATION.PHONE' | translate }}</div>
      <div *ngIf="phone.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
    </div>
  </div>
</form>
<form
  *ngIf="adminProfile"
  #adminForm="ngForm"
  id="account-profile"
  class="row no-gutters row-cols-1 row-cols-lg-2 w-100 w-lg-75"
  novalidate
  (keyup.enter)="saveChanges()"
>
  <div class="form-group col pr-lg-6">
    <label for="firstName">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.FIRST_NAME' | translate }}</label>
    <input
      id="firstName"
      name="firstName"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.FIRST_NAME' | translate}}"
      [ngClass]="{'is-invalid': adminForm.submitted && firstName.invalid, 'is-valid': adminForm.submitted && firstName.valid }"
      #firstName="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="adminProfile.firstName"
      required
      [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
    />
    <div class="invalid-feedback" *ngIf="adminForm.submitted && firstName.invalid">
      <div *ngIf="firstName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
      <div *ngIf="firstName.errors['maxLength']">
        {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
      </div>
    </div>
  </div>
  <div class="form-group col pr-lg-6">
    <label for="lastName">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.LAST_NAME' | translate }}</label>
    <input
      id="lastName"
      name="lastName"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.LAST_NAME' | translate}}"
      [ngClass]="{'is-invalid': adminForm.submitted && lastName.invalid, 'is-valid': adminForm.submitted && lastName.valid }"
      #lastName="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="adminProfile.lastName"
      required
      [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
    />
    <div class="invalid-feedback" *ngIf="adminForm.submitted && lastName.invalid">
      <div *ngIf="lastName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
      <div *ngIf="firstName.errors['maxLength']">
        {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
      </div>
    </div>
  </div>
  <div class="form-group col pr-lg-6">
    <label for="nickname">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.NICKNAME' | translate }}</label>
    <input
      id="nickname"
      name="adminProfile"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.NICKNAME' | translate}}"
      [ngClass]="{'is-invalid': adminForm.submitted && nickname.invalid, 'is-valid': adminForm.submitted && nickname.valid }"
      #nickname="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="adminProfile.nickname"
      [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
    />
    <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
    <div class="invalid-feedback" *ngIf="adminForm.submitted && nickname.invalid">
      <div *ngIf="nickname.errors['maxLength']">
        {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
      </div>
    </div>
  </div>
  <div class="form-group col pr-lg-6">
    <label for="email">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.EMAIL' | translate }}</label>
    <input
      id="email"
      name="email"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.EMAIL' | translate}}"
      [ngClass]="{'is-invalid': adminForm.submitted && email.invalid, 'is-valid': adminForm.submitted && email.valid }"
      #email="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="adminProfile.email"
      required
      [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
      ngvemail
    />
    <div class="invalid-feedback" *ngIf="adminForm.submitted && email.invalid">
      <div *ngIf="email.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
      <div *ngIf="email.errors['maxLength']">
        {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
      </div>
      <div *ngIf="email.errors['email']">{{ 'VALIDATION.EMAIL' | translate }}</div>
    </div>
  </div>
  <div class="form-group col pr-lg-6">
    <label for="phoneNo">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.PHONE' | translate }}</label>
    <input
      id="phoneNo"
      name="phoneNo"
      type="text"
      class="form-control"
      placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.PHONE' | translate}}"
      [ngClass]="{'is-invalid': adminForm.submitted && phone.invalid, 'is-valid': adminForm.submitted && phone.valid }"
      #phone="ngModel"
      [disabled]="!editMode"
      [(ngModel)]="adminProfile.phone"
      [showMaskTyped]="true"
      [prefix]="Constant.PHONE_NUMBER_RULES.prefix + ' '"
      [shownMaskExpression]="Constant.PHONE_NUMBER_RULES.expression"
      [mask]="Constant.PHONE_NUMBER_RULES.mask"
      [pattern]="Constant.PHONE_NUMBER_RULES.pattern"
    />
    <div class="invalid-feedback" *ngIf="adminForm.submitted && phone.invalid">
      <div *ngIf="phone.errors['pattern']">{{ 'VALIDATION.PHONE' | translate }}</div>
    </div>
    <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
  </div>
</form>
<form
  *ngIf="coachProfileUpdate"
  #coachForm="ngForm"
  id="account-profile"
  class="w-100 w-lg-75"
  novalidate
  (keyup.enter)="saveChanges()"
>
  <div class="row no-gutters">
    <div class="form-group col-12 col-sm-6 pr-sm-6">
      <label for="firstName">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.FIRST_NAME' | translate }}</label>
      <input
        id="firstName"
        name="firstName"
        type="text"
        class="form-control"
        placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.FIRST_NAME' | translate}}"
        [ngClass]="{'is-invalid': coachForm.submitted && firstName.invalid, 'is-valid': coachForm.submitted && firstName.valid }"
        #firstName="ngModel"
        [disabled]="!editMode"
        [(ngModel)]="coachProfileUpdate.user.firstName"
        required
        [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
      />
      <div class="invalid-feedback" *ngIf="coachForm.submitted && firstName.invalid">
        <div *ngIf="firstName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        <div *ngIf="firstName.errors['maxLength']">
          {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
        </div>
      </div>
    </div>
    <div class="form-group col-12 col-sm-6">
      <label for="lastName">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.LAST_NAME' | translate }}</label>
      <input
        id="lastName"
        name="lastName"
        type="text"
        class="form-control"
        placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.LAST_NAME' | translate}}"
        [ngClass]="{'is-invalid': coachForm.submitted && lastName.invalid, 'is-valid': coachForm.submitted && lastName.valid }"
        #lastName="ngModel"
        [disabled]="!editMode"
        [(ngModel)]="coachProfileUpdate.user.lastName"
        required
        [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
      />
      <div class="invalid-feedback" *ngIf="coachForm.submitted && lastName.invalid">
        <div *ngIf="lastName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        <div *ngIf="firstName.errors['maxLength']">
          {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
        </div>
      </div>
    </div>
    <div class="form-group col-12 col-sm-6 pr-sm-6">
      <label for="nickname">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.NICKNAME' | translate }}</label>
      <input
        id="nickname"
        name="nickname"
        type="text"
        class="form-control"
        placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.NICKNAME' | translate}}"
        [ngClass]="{'is-invalid': coachForm.submitted && nickname.invalid, 'is-valid': coachForm.submitted && coachProfileUpdate.user.nickname }"
        #nickname="ngModel"
        [disabled]="!editMode"
        [(ngModel)]="coachProfileUpdate.user.nickname"
        [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
      />
      <div class="invalid-feedback" *ngIf="coachForm.submitted && nickname.invalid">
        <div *ngIf="nickname.errors['maxLength']">
          {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
        </div>
      </div>
      <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
    </div>

    <div class="form-group col-12 col-sm-6">
      <label for="email">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.EMAIL' | translate }}</label>
      <input
        id="email"
        name="email"
        type="text"
        class="form-control"
        placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.EMAIL' | translate}}"
        [ngClass]="{'is-invalid': coachForm.submitted && email.invalid, 'is-valid': coachForm.submitted && email.valid }"
        #email="ngModel"
        [disabled]="!editMode"
        [(ngModel)]="coachProfileUpdate.user.email"
        required
        [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
        ngvemail
      />
      <div class="invalid-feedback" *ngIf="coachForm.submitted && email.invalid">
        <div *ngIf="email.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        <div *ngIf="email.errors['maxLength']">
          {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
        </div>
        <div *ngIf="email.errors['email']">{{ 'VALIDATION.EMAIL' | translate }}</div>
      </div>
    </div>
    <div class="form-group col-12 col-sm-6 pr-sm-6">
      <label for="phone">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.PHONE' | translate }}</label>
      <input
        id="phone"
        name="phone"
        type="text"
        class="form-control"
        placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.PHONE' | translate}}"
        [ngClass]="{'is-invalid': coachForm.submitted && phone.invalid, 'is-valid': coachForm.submitted && coachProfileUpdate.user.phone }"
        #phone="ngModel"
        [disabled]="!editMode"
        [(ngModel)]="coachProfileUpdate.user.phone"
        [showMaskTyped]="true"
        [prefix]="Constant.PHONE_NUMBER_RULES.prefix + ' '"
        [shownMaskExpression]="Constant.PHONE_NUMBER_RULES.expression"
        [mask]="Constant.PHONE_NUMBER_RULES.mask"
        [pattern]="Constant.PHONE_NUMBER_RULES.pattern"
      />
      <div class="invalid-feedback" *ngIf="coachForm.submitted && phone.invalid">
        <div *ngIf="phone.errors['pattern']">{{ 'VALIDATION.PHONE' | translate }}</div>
      </div>
      <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
    </div>
    <div class="form-group col-12 col-sm-6">
      <label for="location">{{ 'VIEW.MAIN.ACCOUNT.PROFILE.LOCATION' | translate }}</label>
      <input
        id="location"
        name="location"
        type="text"
        class="form-control"
        placeholder="{{'VIEW.MAIN.ACCOUNT.PROFILE.LOCATION' | translate}}"
        [ngClass]="{'is-invalid': coachForm.submitted && location.invalid, 'is-valid': coachForm.submitted }"
        #location="ngModel"
        [disabled]="!editMode"
        [(ngModel)]="coachProfileUpdate.location"
        [appMaxLength]="Constant.TEXT_MAX_LENGTH_150"
        required
      />
      <div class="invalid-feedback" *ngIf="coachForm.submitted && location.invalid">
        <div *ngIf="location.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        <div *ngIf="location.errors['maxLength']">
          {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_150} }}
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <textarea
        id="bio"
        name="bio"
        #bio="ngModel"
        class="form-control"
        placeholder="Text"
        rows="5"
        maxlength="255"
        [ngClass]="{'is-invalid': coachForm.submitted && bio.invalid, 'is-valid': coachForm.submitted && bio.valid }"
        [(ngModel)]="coachProfileUpdate.bio"
        required
        [disabled]="!editMode"
      ></textarea>
      <div class="d-flex justify-content-end">{{ bio.value ? bio.value.length : 0 }}/255</div>
      <div class="invalid-feedback" *ngIf="coachForm.submitted && bio.invalid">
        <div *ngIf="bio.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
      </div>
    </div>
    <div class="form-group col-12">
      <div class="form-group col d-flex flex-column mb-4">
        <app-dynamic-input
          [label]="'VIEW.MAIN.COACHES.HOBBIES_PASSIONS' | translate"
          [items]="coachProfileUpdate.coachHobbies"
          keyName="hobby"
          [addButtonText]="'COMMON.ADD' | translate"
          [placeholder]="'VIEW.MAIN.COACHES.HOBBIES_PASSIONS' | translate"
          (updatedItems)="handleUpdatedHobbies($event)"
          [disabled]="!editMode"
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
          [formSubmitted]="coachForm.submitted"
          (formStatus)="onHobbiesFormStatusChange($event)"
        ></app-dynamic-input>
      </div>

      <div class="form-group col d-flex flex-column mb-4">
        <app-dynamic-input
          [label]="'VIEW.MAIN.COACHES.EDUCATION' | translate"
          [items]="coachProfileUpdate.coachEducations"
          keyName="education"
          [addButtonText]="'COMMON.ADD' | translate"
          [placeholder]="'VIEW.MAIN.COACHES.EDUCATION' | translate"
          (updatedItems)="handleUpdatedEducations($event)"
          [disabled]="!editMode"
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
          [formSubmitted]="coachForm.submitted"
          (formStatus)="onEducationFormStatusChange($event)"
        ></app-dynamic-input>
      </div>

      <div class="form-group col d-flex flex-column mb-4">
        <app-dynamic-input
          [label]="'VIEW.MAIN.COACHES.CERTIFICATIONS' | translate"
          [items]="coachProfileUpdate.coachCertifications"
          keyName="certification"
          [addButtonText]="'COMMON.ADD' | translate"
          [placeholder]="'VIEW.MAIN.COACHES.CERTIFICATIONS' | translate"
          (updatedItems)="handleUpdatedCertification($event)"
          [disabled]="!editMode"
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
          [formSubmitted]="coachForm.submitted"
          (formStatus)="onCertificationFormStatusChange($event)"
        ></app-dynamic-input>
      </div>
    </div>
    <div class="form-group col-12"></div>
  </div>
</form>

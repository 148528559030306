import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SeniorService } from 'app/service/SeniorService';
import { BaseModal } from 'app/util/extension/BaseModal';
import { Observable } from 'rxjs';
import { OfferDTO } from 'app/data/dto/stripe/OfferDTO';

@Component({
  selector: 'app-membership-plans-modal',
  templateUrl: './MembershipPlansModalComponent.html',
  styleUrls: [ './MembershipPlansModalComponent.scss' ]
})
export class MembershipPlansModalComponent extends BaseModal<void> {
  public title: string = 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MODAL.MEMBERSHIP_PLAN.CHOOSE_PLAN';

  public offers$: Observable<OfferDTO[]> = this.seniorService.getStripeOffers();

  public selectedOffer: OfferDTO;

  public submitBlocked: boolean = false;

  constructor(protected readonly bsModalRef: BsModalRef,
              private readonly seniorService: SeniorService) {
    super();
  }

  public subscribe(): void {
    this.submitBlocked = true;

    this.seniorService.checkout(this.selectedOffer.id)
      .subscribe({
        next: (url: string) => {
          document.location.href = url;
        },
        error: () => {
          this.submitBlocked = false;
        },
        complete: () => {
          this.close();
        }
      });
  }
}
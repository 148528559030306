import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'minutesToTime' })
export class MinutesToTimePipe implements PipeTransform {

  public transform(value: number): any {
    if (!_.isUndefined(value) && _.isNumber(value)) {
      return moment().startOf('day').add(value, 'minutes').format('HH:mm');
    }
    else {
      return '-';
    }
  }
}

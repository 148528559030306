<app-content-and-image-column-layout [imageSrc]="'asset/image/register-background.jpg'">
  <form id="content" class="d-flex flex-column p-4" #resetPasswordForm="ngForm" novalidate (keyup.enter)="continue()">
    <div class="d-flex justify-content-center">
      <img class="logo" src="asset/image/logo.png" alt="Logo"/>
    </div>
    <div class="d-flex flex-column align-items-star">
      <h1 class="mb-0">{{ 'VIEW.PRELIMINARY.RESET_PASSWORD.PASSWORD_RESET' | translate }}</h1>
      <div class="d-flex flex-column flex-sm-row align-content-center">
        <span class="mr-2">{{ 'VIEW.PRELIMINARY.RESET_PASSWORD.PASSWORD_RESET_MESSAGE' | translate }}</span>
        <span class="font-weight-bold">{{ usernameParam }}</span>
      </div>
    </div>

    <div class="flex-column mt-md-6">
      <div class="code-inputs d-flex justify-content-center justify-content-center">
        <input
          type="text"
          maxlength="1"
          (keyup)="onKey($event, 0)"
          (paste)="onKey($event, 0)"
          #input0
          [(ngModel)]="formData.verificationCode0"
          name="code0"
          required
        />
        <input
          type="text"
          maxlength="1"
          (keyup)="onKey($event, 1)"
          #input1
          [(ngModel)]="formData.verificationCode1"
          name="code1"
          required
        />
        <input
          type="text"
          maxlength="1"
          (keyup)="onKey($event, 2)"
          #input2
          [(ngModel)]="formData.verificationCode2"
          name="code2"
          required
        />
        <input
          type="text"
          maxlength="1"
          (keyup)="onKey($event, 3)"
          #input3
          [(ngModel)]="formData.verificationCode3"
          name="code3"
          required
        />
        <input
          type="text"
          maxlength="1"
          (keyup)="onKey($event, 4)"
          #input4
          [(ngModel)]="formData.verificationCode4"
          name="code4"
          required
        />
        <input
          type="text"
          maxlength="1"
          (keyup)="onKey($event, 5)"
          #input5
          [(ngModel)]="formData.verificationCode5"
          name="code5"
          required
        />
      </div>

      <div class="d-flex flex-column flex-sm-row my-6 align-items-center">
        <span class="mr-1">{{ "VIEW.PRELIMINARY.RESET_PASSWORD.RESET_VERIFICATION_MESSAGE" | translate: { usernameType } }}</span>
        <span (click)="resendConfirmationLink()" class="btn-link font-size-base font-weight-600"
        ><u>{{ 'VIEW.PRELIMINARY.RESET_PASSWORD.RESET_VERIFICATION_CODE' | translate }}</u></span
        >
      </div>
    </div>

    <div class="d-flex flex-column flex-sm-row justify-content-end control-buttons">
      <button class="btn btn-light" (click)="onBackClick()">
        <svg-icon class="mr-2" src="asset/image/icon/chevron-left.svg"></svg-icon>
        <span>{{ "COMMON.BACK" | translate }}</span>
      </button>
      <button id="next-btn" class="btn btn-primary" (click)="continue()">
        <span> {{ "COMMON.CONTINUE" | translate }}</span>
      </button>
    </div>
  </form>
</app-content-and-image-column-layout>

import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'birthDateToAge' })
export class BirthDateToAgePipe implements PipeTransform {

  public transform(value: Date): any {
    if (!_.isUndefined(value) && _.isDate(value)) {
      return moment().diff(moment(value), 'years').toString();
    }
    else {
      return '-';
    }
  }
}

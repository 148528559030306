import { BreakpointObserver } from '@angular/cdk/layout';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { State } from 'app/common/State';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { CurrentUserProfileDTO } from 'app/data/dto/user/CurrentUserProfileDTO';
import { TutorialType } from 'app/data/dto/user/TutorialType';
import { UserDetailsDTO } from 'app/data/dto/user/UserDetailsDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { AccountTabs } from 'app/data/enum/account/AccountTabs';
import { UserType } from 'app/data/enum/user/UserType';
import { AccountTourWizardAnchor } from 'app/data/local/AccountTourWizardAnchor';
import { AccessControlModel } from 'app/model/AccessControlModel';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { UserModel } from 'app/model/UserModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { TourWizardService, TourWizardStep } from 'ngx-tour-wizard';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { privacyPolicyUrl, termsAndConditionsUrl } from 'src/asset/files/files';

import { ChangePhotoComponent } from './components/ChangePhotoComponent';
import { AccountReferFriendComponent } from './referFriend/AccountReferFriendComponent';

@Component({
  selector: 'app-account-details',
  templateUrl: 'AccountDetailsComponent.html',
  styleUrls: [ 'AccountDetailsComponent.scss' ]
})
export class AccountDetailsComponent implements OnInit, OnDestroy {
  public readonly TourWizardAnchor: typeof AccountTourWizardAnchor = AccountTourWizardAnchor;

  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  public currentUser: UserDTO;
  public userType: UserType;
  public UserType: typeof UserType = UserType;
  public currentUser$: Observable<UserDTO>;
  public privacyPolicyUrl: string = privacyPolicyUrl;
  public termsAndConditionsUrl: string = termsAndConditionsUrl;

  public tabs: AccountTabs[] = [ AccountTabs.PROFILE, AccountTabs.PASSWORD, AccountTabs.DATA_PRIVACY ];

  public selectedTab: string = AccountTabs.PROFILE;
  public availableTabs = AccountTabs;
  public currentUserProfile: CurrentUserProfileDTO;
  private onboardingTourStepList: TourWizardStep[] = [];
  private readonly canDisplayOnboardingTour: boolean = false;

  constructor(private stateService: StateService,
              public viewUtil: ViewUtil,
              public stateUtil: StateUtil,
              private applicationModel: ApplicationModel,
              public userModel: UserModel,
              private modalService: BsModalService,
              private transition: Transition,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef,
              private tourWizardService: TourWizardService,
              private breakpointObserver: BreakpointObserver,
              private accessControlModel: AccessControlModel) {
    this.currentUser = userModel.currentUser;
    this.currentUser$ = this.userModel.currentUserObservable$;

    this.canDisplayOnboardingTour = this.breakpointObserver.isMatched('(min-width: 768px)');

    this.onboardingTourStepList = [
      {
        anchorId: AccountTourWizardAnchor.ACCOUNT,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ACCOUNT.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_ACCOUNT.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      }
    ]
  }

  public ngOnInit(): void {
    from(this.userModel.getCurrentUser())
      .pipe(
        tap(({ userType }: UserDTO) => {
          this.userType = userType;
          switch (userType) {
            case UserType.SENIOR:
              this.tabs.splice(1, 0, AccountTabs.PREFERENCES); // Insert PREFERENCES at the second position
              this.tabs.push(AccountTabs.BILLING_INFO);

              const tab = this.transition.params().tab;
              if (tab) {
                this.selectedTab = tab;
              }

              if (this.accessControlModel.isFullAccess) {
                this.startOnboardingTour();
              }
              break;
            case UserType.ADMIN:
              // ADMIN does not have a specific tab that others don't, but we can adjust for clarity or future changes
              break;
            case UserType.COACH:
              this.tabs.splice(1, 0, AccountTabs.NOTIFICATIONS); // Insert NOTIFICATIONS at the second position
              break;
          }
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();

    this.applicationModel.selectSideBarItemWithState(State.MAIN.ACCOUNT.DETAILS);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.applicationModel.selectSideBarItemWithState();
    this.tourWizardService.end();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  private startOnboardingTour() {
    if (this.currentUser?.completedTutorials?.includes(TutorialType.ACCOUNT) || !this.canDisplayOnboardingTour) {
      return;
    }

    setTimeout(() => {
      this.tourWizardService.initialize(this.onboardingTourStepList);
      this.tourWizardService.start();

      // add progress per step
      this.onboardingTourStepList.forEach((step: TourWizardStep, index: number) => {
        const tourStepElement: HTMLElement = document.querySelector('[tourWizardAnchor="' + step.anchorId + '"] + tour-wizard-popper-component');
        tourStepElement?.classList.add('tour-wizard-progress');
        tourStepElement?.style.setProperty('--current-step', (index + 1).toString());
        tourStepElement?.style.setProperty('--all-steps', (this.onboardingTourStepList.length).toString());
      });

      this.tourWizardService.end$.pipe(
        first(),
        switchMap(() => this.userModel.setCurrentUserTutorialCompletion(TutorialType.ACCOUNT))
      ).subscribe((userDetails: UserDetailsDTO) => {
        this.currentUser.completedTutorials = userDetails.completedTutorials;
      });
    });
  }

  public editDetails(): void {
    this.stateService.go(State.MAIN.ACCOUNT.EDIT);
  }

  public openReferFriendModal(): void {
    this.modalService.show(AccountReferFriendComponent);
  }

  public onTabChanged(selectedTab: string): void {
    this.selectedTab = selectedTab;
  }

  public onChangePhotoClick(): void {
    this.modalService.show(ChangePhotoComponent, {
      initialState: {
        currentUser: this.currentUser
      },
      class: 'change-photo-modal modal-dialog-centered',
      backdrop: 'static',
      keyboard: false
    });
  }

  public onRemovePhotoClick(): void {
    if (!this.currentUser.avatar)
      return;
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        message: 'VIEW.MAIN.USER.DELETE_PHOTO',
        okText: 'COMMON.YES',
        cancelText: 'COMMON.NO'
      },
      class: 'modal-dialog-centered'
    });
    modal.onHide
      .pipe(
        takeUntil(this.destroy$),
        filter((reason) => reason === PopupConfirmationComponent.POPUP_RESULT_CONFIRM),
        switchMap(() => this.userModel.getCurrentUserProfile()),
        tap((user) => {
          this.currentUserProfile = user;
        }),
        switchMap(() => {
          const { firstName, lastName, nickname, email, phone } = this.currentUserProfile;
          const req = {
            email,
            firstName,
            imageId: null,
            lastName,
            nickname,
            phone
          } as CurrentUserProfileDTO;
          return this.userModel.updateCurrentUserProfile(req);
        }),
        tap(() => {
          this.userModel.getCurrentUser();
          this.viewUtil.showToastSuccess('VIEW.MAIN.ACCOUNT.UPDATE.SUCCESS');
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public onResourceClick(source: string): void {
    this.stateUtil.goToUrl(source, true);
  }
}

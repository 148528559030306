import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SeniorVideoSortBy, VideoSortBy } from 'app/data/enum/video/VideoSortBy';
import _ from 'lodash';
import { BaseCriteriaSortOrder } from 'app/data/dto/BaseCriteriaDTO';
import { SortDirection } from 'app/data/enum/SortDirection';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { ViewUtil } from 'app/util/ViewUtil';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';

@Component({
  selector: 'app-on-demand-filter-list',
  templateUrl: './OnDemandFilterListComponent.html',
  styleUrls: [ './OnDemandFilterListComponent.scss' ]
})
export class OnDemandFilterListComponent implements OnInit {
  @Input() criteria: PageCriteriaDTO;
  @Input() excludedSortBy: SeniorVideoSortBy[] = [ SeniorVideoSortBy.AVAILABLE ];
  @Output() criteriaChange: EventEmitter<PageCriteriaDTO> = new EventEmitter<PageCriteriaDTO>();

  public internalSearchPhrase: string;
  public sortByOptions: OptionItem<SeniorVideoSortBy>[];
  public sortValue: string;

  constructor(private readonly viewUtil: ViewUtil) { }

  public ngOnInit(): void {
    this.sortByOptions = this.viewUtil.createEnumSelectOptions(SeniorVideoSortBy, 'VIDEO_SORT_BY')
      .filter(s => !this.excludedSortBy.includes(s.value as SeniorVideoSortBy)) as OptionItem<SeniorVideoSortBy>[];

    this.setCriteriaBasedOnObject();
  }

  public onSortOptionChange(sortBy: SeniorVideoSortBy): void {
    const criteria = _.cloneDeep(this.criteria);

    if (!sortBy) {
      criteria.sortOrders = undefined;
    }
    else {
      let sortOrders: BaseCriteriaSortOrder;

      const { sortField, sortDirection } = this.transformableSortBy(sortBy);

      sortOrders = `${ sortField } ${ sortDirection }`;
      criteria.sortOrders = [ sortOrders ];
    }

    this.criteriaChange.emit(criteria);
  }

  public clearSearch(): void {
    this.internalSearchPhrase = undefined;
    this.onSearchCommitted();
  }

  public onSearchCommitted(): void {
    const criteria = _.cloneDeep(this.criteria);
    criteria.searchPhrase = this.internalSearchPhrase;

    this.criteriaChange.next(criteria);
  }

  private transformableSortBy(sortBy: SeniorVideoSortBy): { sortField: string, sortDirection: SortDirection } {
    const config = {
      [SeniorVideoSortBy.MOST_RECENT]: { sortField: 'createdDate', sortDirection: SortDirection.DESCENDING },
      [SeniorVideoSortBy.RATING]: { sortField: 'rating', sortDirection: SortDirection.DESCENDING },
      [SeniorVideoSortBy.ALPHABETIC]: { sortField: 'title', sortDirection: SortDirection.ASCENDING },
      [SeniorVideoSortBy.AVAILABLE]: { sortField: 'accessibleForFirstTier', sortDirection: SortDirection.DESCENDING }
    };

    return config[sortBy] || { sortField: undefined, sortDirection: SortDirection.ASCENDING };
  }

  private getSortBy(key: string): SeniorVideoSortBy {
    const config = {
      'createdDate': SeniorVideoSortBy.MOST_RECENT,
      'title': SeniorVideoSortBy.ALPHABETIC,
      'rating': SeniorVideoSortBy.RATING,
      'accessibleForFirstTier': SeniorVideoSortBy.AVAILABLE
    };

    return config[key];
  }

  private setCriteriaBasedOnObject(): void {
    const { searchPhrase, sortOrders } = this.criteria;
    this.internalSearchPhrase = searchPhrase ?? undefined;

    if (sortOrders && sortOrders?.length > 0) {
      const [ sortField, sortDirection ] = sortOrders[0].split(' ');
      this.sortValue = this.getSortBy(sortField);
    }
  }
}

<div id="on-demand">
  <div class="details mb-2" (mouseenter)="displayOverlay = true" (mouseleave)="displayOverlay = false">
    <img class="image" [src]="data.thumbnail.defaultAsset.url" alt=""/>
    <div @inOutAnimation *ngIf="displayOverlay" class="overlay p-3">
      <ng-container *ngIf="subscriptionRequired" [ngTemplateOutlet]="subscriptionRequiredTemplate"></ng-container>
      <ng-container *ngIf="!subscriptionRequired" [ngTemplateOutlet]="overlayTemplate"></ng-container>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="text-sm mb-2">{{ data.title }}</div>
    <div *ngIf="data.rating >=4" class="rating">
      <app-rating-stars [value]="data.rating"></app-rating-stars>
    </div>
  </div>
</div>


<!--Template-->
<ng-template #subscriptionRequiredTemplate>
  <div class="d-flex flex-column gap-4 justify-content-center align-items-center h-100 text-center">
    <h3>{{ 'VIEW.MAIN.ON_DEMAND.LIMITED_BY_TIER_LEVEL.TITLE' | translate }}</h3>
    <p>{{ 'VIEW.MAIN.ON_DEMAND.LIMITED_BY_TIER_LEVEL.DESCRIPTION' | translate }}</p>
  </div>
</ng-template>

<ng-template #overlayTemplate>
  <div class="d-flex justify-content-between icons-top">
    <svg-icon *ngIf="mappedIntensity > 0" [src]="'asset/image/icon/level-' +  mappedIntensity + '.svg'"></svg-icon>
    <svg-icon
      role="button"
      src="asset/image/icon/heart.svg"
      class="cursor-pointer"
      *ngIf="!guestMode && !data.isFavorite"
      (click)="changeIsFavorite()"
    ></svg-icon>
    <!-- <img class="image-webkit-fill-available" [src]="video.thumbnail.defaultAsset.url" /> -->
    <svg-icon
      role="button"
      src="asset/image/icon/heart-filled.svg"
      class="cursor-pointer favorite"
      *ngIf="!guestMode && data.isFavorite"
      (click)="changeIsFavorite()"
    ></svg-icon>
  </div>
  <div class="text-sm-light">{{ 'VIEW.MAIN.ON_DEMAND.COACH' | translate }}</div>
  <div class="mb-2 text-semibold text-center text-truncate w-100">
    {{ data.coaches[0].user.firstName }} {{ data.coaches[0].user.lastName }}
    <span *ngIf="data.coaches.length > 1">(+{{ data.coaches.length - 1 }})</span>
  </div>
  <div class="text-sm-light">{{ 'VIEW.MAIN.ON_DEMAND.CATEGORY' | translate }}</div>
  <div class="mb-2 text-semibold">{{ data.category | prefix: 'ENUM.VIDEO_CATEGORY.' | translate }}</div>
  <div class="text-sm-light">{{ 'VIEW.MAIN.ON_DEMAND.LENGTH' | translate }}</div>
  <div class="mb-2 text-semibold">{{ data.duration }}</div>
  <div class="buttons">
    <button *ngIf="!guestMode" class="btn btn-outline-primary" (click)="showDetailsModal()">
      <span>{{ 'VIEW.MAIN.ON_DEMAND.MORE_INFO' | translate }}</span>
    </button>
    <button class="btn btn-outline-primary" (click)="playVideo()">
      <span>{{ 'VIEW.MAIN.ON_DEMAND.PLAY' | translate }}</span>
      <svg-icon src="asset/image/icon/player-play.svg"></svg-icon>
    </button>
  </div>
</ng-template>

import { BreakpointObserver } from '@angular/cdk/layout';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { State } from 'app/common/State';
import {
  DataGridTemplateCellRendererComponent
} from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { DataGridAction } from 'app/component/ui/dataGrid/DataGridAction';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { BaseCriteriaDTO } from 'app/data/dto/BaseCriteriaDTO';
import { CoachDTO } from 'app/data/dto/coach/CoachDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { BaseLiveClass } from 'app/data/dto/scheduledLiveClass/BaseLiveClass';
import {
  ScheduledLiveClassSeniorPageCriteriaDTO
} from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassSeniorPageCriteriaDTO';
import {
  ScheduledLiveClassSeniorDetailsPageDTO
} from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsPageDTO';
import { TutorialType } from 'app/data/dto/user/TutorialType';
import { UserDetailsDTO } from 'app/data/dto/user/UserDetailsDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { DayOfWeek } from 'app/data/enum/DayOfWeek';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { Language } from 'app/data/enum/Language';
import { LiveClassCategory } from 'app/data/enum/liveClass/LiveClassCategory';
import { LiveClassesDaysAhead } from 'app/data/enum/liveClass/LiveClassesDaysAhead';
import { LiveClassesTabs } from 'app/data/enum/liveClass/LiveClassesTabs';
import { TimeOfDay } from 'app/data/enum/TimeOfDay';
import { DashboardTourWizardAnchor } from 'app/data/local/DashboardTourWizardAnchor';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { LiveClassesTourWizardAnchor } from 'app/data/local/LiveClassesTourWizardAnchor';
import { AccessControlModel } from 'app/model/AccessControlModel';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { UserModel } from 'app/model/UserModel';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { PrefixPipe } from 'app/util/pipe/PrefixPipe';
import { PortalUtil } from 'app/util/PortalUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import * as _ from 'lodash';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { TourWizardService, TourWizardStep } from 'ngx-tour-wizard';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IntensityCellRendererComponent } from '../components/cellRenderer/intensity/IntensityCellRendererComponent';

import {
  LiveClassesListActionCellRendererComponent
} from '../components/cellRenderer/LiveClassesListActionCellRendererComponent';
import {
  LiveClassesListCoachCellRendererComponent
} from '../components/cellRenderer/LiveClassesListCoachCellRendererComponent';
import { LiveClassesFilterComponent } from '../components/filter/LiveClassesFilterComponent';
import { JoinUserComponent } from '../components/join/JoinUserComponent';
import { ThankYouComponent } from '../components/thankYou/ThankYouComponent';

@Component({
  selector: 'app-live-classes-list-user',
  templateUrl: './LiveClassesListUserComponent.html',
  styleUrls: [ './LiveClassesListUserComponent.scss' ]
})
export class LiveClassesListUserComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  private _activeTab: string;
  private destroy$: Subject<void> = new Subject<void>();
  private readonly canDisplayOnboardingTour: boolean = false;
  public selectedDaysAhead: typeof LiveClassesDaysAhead = LiveClassesDaysAhead;
  public bsConfig: Partial<BsDatepickerConfig>;
  public page$: Observable<PageDTO<ScheduledLiveClassSeniorDetailsPageDTO>>;
  public criteria: ScheduledLiveClassSeniorPageCriteriaDTO = new ScheduledLiveClassSeniorPageCriteriaDTO();
  public consecutiveDays: string;
  public selectedDate: Date = new Date();
  public selectedItems: ScheduledLiveClassSeniorDetailsPageDTO[] = [];
  public currentUser: UserDTO;
  public tabs: string[];
  private onboardingTourStepList: TourWizardStep[] = [];
  public readonly TourWizardAnchor: typeof LiveClassesTourWizardAnchor = LiveClassesTourWizardAnchor;
  public daysAheadSelectList: OptionItem<LiveClassesDaysAhead>[] = this.viewUtil.createEnumSelectOptions(
    LiveClassesDaysAhead,
    'DAYS_AHEAD'
  );

  public LiveClassesListActionCellRendererComponent: typeof LiveClassesListActionCellRendererComponent =
    LiveClassesListActionCellRendererComponent;

  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent =
    DataGridTemplateCellRendererComponent;

  public LiveClassesListCoachCellRendererComponent: typeof LiveClassesListCoachCellRendererComponent =
    LiveClassesListCoachCellRendererComponent;

  public IntensityComponent: typeof IntensityCellRendererComponent = IntensityCellRendererComponent;
  public coaches: OptionItem<CoachDTO>[] = [];
  public categories: OptionItem<LiveClassCategory>[] = this.viewUtil.createEnumSelectOptions(
    LiveClassCategory,
    'CATEGORY'
  );

  public intensities: OptionItem<IntensityLevel>[] = this.viewUtil.createEnumSelectOptions(
    IntensityLevel,
    'INTENSITY_LEVEL'
  );

  public days: OptionItem<DayOfWeek>[] = this.viewUtil.createEnumSelectOptions(DayOfWeek, 'DAYS');
  public timeOfTheDay: OptionItem<TimeOfDay>[] = this.viewUtil.createEnumSelectOptions(
    TimeOfDay,
    'TIME_OF_THE_DAY'
  );

  public languages: OptionItem<Language>[] = this.viewUtil.createEnumSelectOptions(Language, 'LANGUAGE');

  public getLabelForKeyValueBind: (key: string, value: string) => string = this.getLabelForKeyValue.bind(this);

  public friendlyNames: { [key: string]: string } = {
    anyOfCategories: 'COMPONENT.CLASS_FILTERS.CLASS_CATEGORY',
    anyOfIntensities: 'COMPONENT.CLASS_FILTERS.INTENSITY',
    daysOfWeek: 'COMPONENT.CLASS_FILTERS.DAY_OF_THE_WEEK',
    anyOfCoachIds: 'COMPONENT.CLASS_FILTERS.COACH',
    anyOfLanguages: 'COMPONENT.CLASS_FILTERS.LANGUAGE'
  };

  public startDateValueFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'MMM dd');

  public startTimeValueFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) => {
    return this.viewUtil.dataGridPipeValueFormatter(params, DatePipe, 'h:mm a');
  };

  public enumCategoryFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.CATEGORY.');

  public enumLengthFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.DURATION.');

  public enumIntensityFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.INTENSITY_LEVEL.');

  public enumCapacityFormatter: (params: ValueFormatterParams) => any = (params: ValueFormatterParams) =>
    this.viewUtil.dataGridPipeValueFormatterWithTranslate(params, PrefixPipe, 'ENUM.CAPACITY.');

  public checkboxSelectionBinder: () => any = this.gridRowCheckboxSelection.bind(this);

  public get activeTab(): string {
    return this._activeTab;
  }

  public set activeTab(value: string) {
    this._activeTab = value;
    this.criteria.favorites = value === LiveClassesTabs.FAVORITES;
    this.getScheduledLiveClassPage();
  }

  constructor(
    public modalService: BsModalService,
    private stateService: StateService,
    public viewUtil: ViewUtil,
    private applicationModel: ApplicationModel,
    private userModel: UserModel,
    private accessControlModel: AccessControlModel,
    private portalUtil: PortalUtil,
    private viewContainerRef: ViewContainerRef,
    private tourWizardService: TourWizardService,
    private breakpointObserver: BreakpointObserver) {
    this.canDisplayOnboardingTour = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.currentUser = this.userModel.currentUser;
    this.onGridAction = this.onGridAction.bind(this);

    this.onboardingTourStepList = [
      {
        anchorId: LiveClassesTourWizardAnchor.NEXT_DAYS,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_NEXT_DAYS.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_NEXT_DAYS.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      },
      {
        anchorId: LiveClassesTourWizardAnchor.FILTER_BY,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_FILTER_BY.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_FILTER_BY.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      },
      {
        anchorId: LiveClassesTourWizardAnchor.HEART,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_HEART.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_HEART.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.TOP,
          positionFixed: true
        }
      },
      {
        anchorId: LiveClassesTourWizardAnchor.BULK_REGISTER,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_BULK_REGISTER.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_BULK_REGISTER.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.TOP,
          positionFixed: true
        }
      }
    ];
  }

  public ngOnInit(): void {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.SENIOR.LIVE_CLASSES.LIST);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.criteria.selectedDate = new Date();

    this.tabs = [ LiveClassesTabs.ALL ];
    this.activeTab = LiveClassesTabs.ALL;

    // this component is available also for guests,
    // so we need to check if we are logged in or not

    if (this.accessControlModel.isFullAccess) {
      this.tabs = [ LiveClassesTabs.ALL, LiveClassesTabs.FAVORITES ];

      this.userModel
        .getSingleUnratedScheduledLiveClass()
        .pipe(
          takeUntil(this.destroy$),
          tap((response) => {
            if (response) {
              const modal: BsModalRef = this.modalService.show(ThankYouComponent, {
                initialState: {
                  unratedScheduledLiveClasses: [ response ]
                },
                class: 'thankyou-modal modal-dialog-centered'
              });
            }
          })
        )
        .subscribe( res => {});
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public gridRowCheckboxSelection(TData: any, TValue: any): boolean {
    if (!this.currentUser) {
      return false;
    }

    const { data } = TData;
    return data.id !== null && data.name !== null;
  }

  public changeToNextDay(): void {
    const currentDate: Date = new Date(this.selectedDate);
    currentDate.setDate(currentDate.getDate() + 1);
    this.selectedDate = currentDate;
    this.criteria.selectedDate = this.selectedDate;
    this.resetConsecutiveDaysIfNotToday();
    this.onCriteriaChanged(this.criteria);
  }

  public changeToPreviousDay(): void {
    const currentDate = new Date(this.selectedDate);
    currentDate.setDate(currentDate.getDate() - 1);
    this.selectedDate = currentDate;
    this.criteria.selectedDate = this.selectedDate;
    this.resetConsecutiveDaysIfNotToday();
    this.onCriteriaChanged(this.criteria);
  }

  public showFilterClick(): void {
    const modal: BsModalRef = this.modalService.show(LiveClassesFilterComponent, {
      initialState: {
        anyOfCategories: this.criteria.anyOfCategories,
        anyOfCoachIds: this.criteria.anyOfCoachIds,
        anyOfIntensities: this.criteria.anyOfIntensities,
        anyOfLanguages: this.criteria.anyOfLanguages,
        daysOfWeek: this.criteria.daysOfWeek
      },
      class: 'modal-filterLiveClasses modal-dialog-centered'
    });
    modal.onHide
      .pipe(
        filter((reason) => reason === LiveClassesFilterComponent.SHOW_FILTERS || reason === LiveClassesFilterComponent.CLEAR_FILTERS),
        tap(() => {
          const { categories, coaches, dayOfTheWeek, languages, intensities } = modal.content.filters;
          this.criteria = {
            ...this.criteria,
            anyOfCategories:
              Object.keys(categories.checked).length > 0
                ? Object.keys(categories.checked).filter((key) => categories.checked[key])
                : undefined,
            anyOfIntensities:
              Object.keys(intensities.checked).length > 0
                ? Object.keys(intensities.checked).filter((key) => intensities.checked[key])
                : undefined,
            daysOfWeek: dayOfTheWeek,
            anyOfCoachIds: coaches.selectedCoaches.length > 0 ? coaches.selectedCoaches : undefined,
            anyOfLanguages:
              Object.keys(languages.checked).length > 0
                ? Object.keys(languages.checked).filter((key) => languages.checked[key])
                : undefined
          } as ScheduledLiveClassSeniorPageCriteriaDTO;
          this.criteria = ObjectUtil.plainToClass(ScheduledLiveClassSeniorPageCriteriaDTO, this.criteria);
          const { coaches: coachesOptionItemList } = modal.content;
          this.coaches = coachesOptionItemList;
          this.onCriteriaChanged(this.criteria);
        })
      )
      .subscribe();
  }

  public detailsLiveClass(scheduledLiveClass: ScheduledLiveClassSeniorDetailsPageDTO): void {
    const { id } = scheduledLiveClass;
    this.stateService.go(State.MAIN.SENIOR.LIVE_CLASSES.DETAILS, { id });
  }

  public handleJoinClick(scheduledLiveClass: ScheduledLiveClassSeniorDetailsPageDTO): void {
    const modal: BsModalRef = this.modalService.show(JoinUserComponent, {
      initialState: {
        scheduledLiveClass: scheduledLiveClass
      },
      class: 'modal-join-live-class modal-dialog-centered'
    });

    modal.onHide
      .pipe(
        filter((reason) => reason === JoinUserComponent.JOIN || reason === JoinUserComponent.DROP),
        tap(() => {
          this.getScheduledLiveClassPage();
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public handleDropClick(liveClass: BaseLiveClass): void {
    this.userModel.dropFromLiveClass(liveClass)
      .subscribe(() => {
          this.getScheduledLiveClassPage();
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        },
        (err) => {
          this.viewUtil.handleServerError(err);
        });
  }

  public handleRegisterClick(liveClass: BaseLiveClass): void {
    this.userModel.registerToLiveClass(liveClass)
      .subscribe(() => {
          this.getScheduledLiveClassPage();
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        },
        (err) => {
          this.viewUtil.handleServerError(err);
        });
  }

  public handleAddToFavoriteClick(scheduledLiveClassSeniorDetailsPage: ScheduledLiveClassSeniorDetailsPageDTO): void {
    this.userModel
      .addScheduledLiveClassesToFavorite([ scheduledLiveClassSeniorDetailsPage ])
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => of(this.getScheduledLiveClassPage()))
      )
      .subscribe();
  }

  public handleUnfavoriteClick({ id }: ScheduledLiveClassSeniorDetailsPageDTO): void {
    this.userModel
      .removeScheduledLiveClassesFromFavorite(id)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => of(this.getScheduledLiveClassPage()))
      )
      .subscribe();
  }

  public handleJoinWaitingListClick(scheduledLiveClass: ScheduledLiveClassSeniorDetailsPageDTO): void {
    this.userModel.joinToScheduledLiveClassWaitingList(scheduledLiveClass.id, this.applicationModel.platform)
      .subscribe(() => {
          this.getScheduledLiveClassPage();
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        },
        (err) => {
          this.viewUtil.handleServerError(err);
        });
  }

  public handleDropWaitingListClick(scheduledLiveClass: ScheduledLiveClassSeniorDetailsPageDTO): void {
    this.userModel.dropFromScheduledLiveClassWaitingList(scheduledLiveClass.id)
      .subscribe(() => {
          this.getScheduledLiveClassPage();
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        },
        (err) => {
          this.viewUtil.handleServerError(err);
        });
  }

  public getLabelForKeyValue(key: string, value: string): string {
    let optionsSet: OptionItem<any>[] = [];

    switch (key) {
      case 'anyOfCategories':
        optionsSet = this.categories;
        break;
      case 'anyOfIntensities':
        optionsSet = this.intensities;
        break;
      case 'daysOfWeek':
        optionsSet = this.days;
        break;
      case 'anyOfCoachIds':
        optionsSet = this.coaches;
        break;
      case 'anyOfLanguages':
        optionsSet = this.languages;
        break;
    }

    const foundItem = optionsSet.find((item) => item.value === value);
    return foundItem ? foundItem.label : value;
  }

  public onTabChanged(tabName: string): void {
    if (tabName === this.activeTab) {
      return;
    }
    this.activeTab = tabName;
    this.selectedItems = [];
    if (tabName === LiveClassesTabs.ALL) {
      this.criteria.favorites = undefined;
    }
    else if (tabName === LiveClassesTabs.FAVORITES) {
      this.criteria.favorites = true;
    }
    this.onCriteriaChanged(this.criteria);
  }

  public onCriteriaChanged(changedCriteria: BaseCriteriaDTO): void {
    const criteriaCopy: ScheduledLiveClassSeniorPageCriteriaDTO = _.cloneDeep(this.criteria);
    _.assignIn(criteriaCopy, changedCriteria);

    this.criteria = criteriaCopy;
    this.getScheduledLiveClassPage();
  }

  public onDaysAheadChange(selectedDaysAhead: string): void {
    if (selectedDaysAhead) {
      const daysAhead = this.mapEnumToDays(selectedDaysAhead);
      this.criteria.consecutiveDays = daysAhead;
    }
    else {
      this.criteria.consecutiveDays = null;
      this.consecutiveDays = this.selectedDaysAhead.TODAY;
    }
    this.onCriteriaChanged(this.criteria);
  }

  public onDateChange(event: any): void {
    this.selectedDate = new Date(event);
    this.criteria.selectedDate = this.selectedDate;
    this.resetConsecutiveDaysIfNotToday();
    this.onCriteriaChanged(this.criteria);
  }

  public onTodayClick(): void {
    const today: Date = new Date();
    this.selectedDate = today;
    this.criteria.selectedDate = this.selectedDate;
    this.criteria.consecutiveDays = null;
    this.consecutiveDays = LiveClassesDaysAhead.TODAY;
    this.onCriteriaChanged(this.criteria);
  }

  public onGridAction(action: DataGridAction): void {
    const scheduledLiveClassSeniorDetailsPage = action.args[0] as ScheduledLiveClassSeniorDetailsPageDTO;
    if (action.actionName === LiveClassesListActionCellRendererComponent.ACTION_DETAILS_USER) {
      this.detailsLiveClass(scheduledLiveClassSeniorDetailsPage);
    }
    else if (action.actionName === LiveClassesListActionCellRendererComponent.ACTION_JOIN) {
      this.handleJoinClick(scheduledLiveClassSeniorDetailsPage);
    }
    else if (action.actionName === LiveClassesListActionCellRendererComponent.ACTION_DROP) {
      this.handleDropClick(scheduledLiveClassSeniorDetailsPage);
    }
    else if (action.actionName === LiveClassesListActionCellRendererComponent.ACTION_REGISTER_USER) {
      this.handleRegisterClick(scheduledLiveClassSeniorDetailsPage);
    }
    else if (action.actionName === LiveClassesListActionCellRendererComponent.ACTION_FAVORITE) {
      this.handleAddToFavoriteClick(scheduledLiveClassSeniorDetailsPage);
    }
    else if (action.actionName === LiveClassesListActionCellRendererComponent.ACTION_UNFAVORITE) {
      this.handleUnfavoriteClick(scheduledLiveClassSeniorDetailsPage);
    }
    else if (action.actionName === LiveClassesListActionCellRendererComponent.ACTION_JOIN_WAITING_LIST) {
      this.handleJoinWaitingListClick(scheduledLiveClassSeniorDetailsPage);
    }
    else if (action.actionName === LiveClassesListActionCellRendererComponent.ACTION_DROP_WAITING_LIST) {
      this.handleDropWaitingListClick(scheduledLiveClassSeniorDetailsPage);
    }
  }

  public onSelectionChange(selectedItems: ScheduledLiveClassSeniorDetailsPageDTO[]): void {
    this.selectedItems = selectedItems;
  }

  public onFavoriteClick(selectedClasses: ScheduledLiveClassSeniorDetailsPageDTO[]): void {
    this.userModel
      .addScheduledLiveClassesToFavorite(selectedClasses)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => of(this.getScheduledLiveClassPage())),
        tap(() => {
          this.selectedItems = [];
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public onRegisterClick(selectedClasses: ScheduledLiveClassSeniorDetailsPageDTO[]): void {
    this.userModel
      .registerToScheduledLiveClasses(selectedClasses)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => of(this.getScheduledLiveClassPage())),
        tap(() => {
          this.selectedItems = [];
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  private getScheduledLiveClassPage(): void {
    this.criteria.pageSize = 8;
    this.userModel
      .getScheduledLiveClassesPage(this.criteria)
      .pipe(
        takeUntil(this.destroy$),
        tap((page: PageDTO<ScheduledLiveClassSeniorDetailsPageDTO>) => {
          this.page$ = of(page);
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe(() => {
        if (this.accessControlModel.isFullAccess) {
          this.startOnboardingTour();
        }
      });
  }

  private mapEnumToDays(value: string | number): number | null {
    switch (value) {
      case LiveClassesDaysAhead.TODAY:
        return null;
      case LiveClassesDaysAhead.SHOW_3_DAYS:
        return 3;
      case LiveClassesDaysAhead.SHOW_10_DAYS:
        return 10;
      case LiveClassesDaysAhead.SHOW_30_DAYS:
        return 30;
      default:
        return 0;
    }
  }

  private resetConsecutiveDaysIfNotToday() {
    const selectedDateMidnight = new Date(this.selectedDate).setHours(0, 0, 0, 0);
    const todayMidnight = new Date().setHours(0, 0, 0, 0);
    if (selectedDateMidnight !== todayMidnight && this.consecutiveDays === this.selectedDaysAhead.TODAY) {
      this.consecutiveDays = null;
    }
  }

  private startOnboardingTour() {
    if (this.currentUser?.completedTutorials?.includes(TutorialType.LIVE_CLASSES) || !this.canDisplayOnboardingTour) {
      return;
    }

    setTimeout(() => {
      this.tourWizardService.initialize(this.onboardingTourStepList);
      this.tourWizardService.start();

      // add progress per step
      this.onboardingTourStepList.forEach((step: TourWizardStep, index: number) => {
        const tourStepElement: HTMLElement = document.querySelector('[tourWizardAnchor="' + step.anchorId + '"] + tour-wizard-popper-component');
        tourStepElement.classList.add('tour-wizard-progress');
        tourStepElement.style.setProperty('--current-step', (index + 1).toString());
        tourStepElement.style.setProperty('--all-steps', (this.onboardingTourStepList.length).toString());
      });

      this.tourWizardService.end$.pipe(
        first(),
        switchMap(() => this.userModel.setCurrentUserTutorialCompletion(TutorialType.LIVE_CLASSES))
      ).subscribe((userDetails: UserDetailsDTO) => {
        this.currentUser.completedTutorials = userDetails.completedTutorials;
      });
    });
  }
}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Application } from 'app/Application';
import { Event } from 'app/common/Event';
import { State } from 'app/common/State';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { UserType } from 'app/data/enum/user/UserType';
import { ApplicationState } from 'app/data/local/ApplicationState';
import { LanguageVersion } from 'app/data/local/LanguageVersion';
import { SideBarItem } from 'app/data/local/ui/SideBarItem';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { AuthModel } from 'app/model/AuthModel';
import { UserModel } from 'app/model/UserModel';
import { NotificationService } from 'app/service/NotificationService';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { EventManager } from 'app/util/other/EventManager';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { SubscriptionStoreComponent } from 'app/component/SubscriptionStoreComponent';
import { PortalUtil } from 'app/util/PortalUtil';
import { DashboardTourWizardAnchor } from 'app/data/local/DashboardTourWizardAnchor';

@Component({
  selector: 'app-main-layout',
  templateUrl: 'MainLayoutComponent.html',
  styleUrls: [ 'MainLayoutComponent.scss' ]
})
export class MainLayoutComponent extends SubscriptionStoreComponent implements OnInit, OnDestroy {
  public static readonly PORTAL_OUTLET = {
    HEADING: 'MainLayoutComponent_PORTAL_OUTLET_HEADING'
  } as const;

  @ViewChild('headingPortalOutlet', { static: true })
  private set headingPortalOutlet(elementRef: ElementRef<HTMLElement>) {
    this.portalUtil.registerPortalOutlet(MainLayoutComponent.PORTAL_OUTLET.HEADING, elementRef.nativeElement);
  }

  public devMode: boolean = false;

  public hasUnreadNotifications$: Observable<boolean> = this.notificationService.hasUnreadNotifications$;
  public hasUnreadCriticalNotifications$: Observable<boolean> = this.notificationService.hasUnreadCriticalNotifications$;

  public currentState: Ng2StateDeclaration;

  public userType = UserType;
  public currentUser$: Observable<UserDTO>;
  public currentUser: UserDTO;

  public applicationVersion: string = ApplicationConfig.version;
  public environmentName: string = ApplicationConfig.environmentName;

  public sideBarItems: SideBarItem[] = [];
  public sideBarAccountItem: SideBarItem[];

  public forcedPasswordChangeMode: boolean = false;
  public sideBarActive: boolean = false;

  public currentDate: Date = new Date();

  private inactiveSessionLogoutBoundFunction: () => any = this.inactiveSessionLogout.bind(this);

  public readonly LanguageVersion: typeof LanguageVersion = LanguageVersion;
  public readonly State: typeof State = State;
  public readonly TourWizardAnchor: typeof DashboardTourWizardAnchor = DashboardTourWizardAnchor;

  constructor(
    private eventManager: EventManager,
    private stateService: StateService,
    public stateUtil: StateUtil,
    public viewUtil: ViewUtil,
    private applicationModel: ApplicationModel,
    private userModel: UserModel,
    private authModel: AuthModel,
    private notificationService: NotificationService,
    private application: Application,
    private portalUtil: PortalUtil
  ) {
    super();

    this.devMode = this.applicationModel.devMode;

    /*
      These values are (or can potentially be) changed by children of this component (i.e. by publishing new values to that observable in model)
      during the same detection cycle (triggering for both this component and its children). Since check detection for children components
      is executed *after* the DOM update for this component, it is prone to ExpressionChangedAfterItHasBeenCheckedError, because a child component
      isn't permitted to change parent's bindings in that same cycle. So we need to manually trigger the forced change detection here and
      we cannot use ChangeDetectorRef.detectChanges(), because it is not allowed to be used in constructor (would throw an error).
      Instead we can either wrap it in setTimeout(), which would create a macrotask, or wrap it in queueMicrotask(), which would create a microtask.
      Both are patched by zone.js and thus recognized by Angular, but since they don't happen within the bounds of current macrotask
      (running the detection cycle) they won't throw ExpressionChangedAfterItHasBeenCheckedError. The difference between micro and macro task is mostly
      in priorities (micro is higher). I'm picking queueMicrotask() for educational purposes, to show that it exists and can be used for such corner cases.
    */

    this.subscription = this.applicationModel.currentState$.subscribe((value: ApplicationState) => {
      queueMicrotask(() => {
        if (value) {
          this.currentState = value.state;
          this.setActiveSidebarItem(value.state);
          this.forcedPasswordChangeMode = value.state.name === State.PRELIMINARY.PASSWORD_CHANGE_REQUIRED;
        }
      });
    });

    this.subscription = this.userModel.currentUser$.subscribe((value: UserDTO) => {
      queueMicrotask(() => {
        this.currentUser = value;
      });
    });

    this.subscription = this.applicationModel.sideBarItems$.subscribe((value: SideBarItem[]) => {
      queueMicrotask(() => {
        this.sideBarItems = value.filter(
          (item) => item.stateName !== State.MAIN.ACCOUNT.DETAILS && item.stateName !== State.MAIN.ACCOUNT.LOGOUT
        );
        this.sideBarAccountItem = value.filter(
          (item) => item.stateName === State.MAIN.ACCOUNT.DETAILS || item.stateName === State.MAIN.ACCOUNT.LOGOUT
        );
        this.setActiveSidebarItem(this.currentState);
      });
    });
  }

  public ngOnInit(): void {
    this.createSidebarItemsForUserType();
    this.currentUser$ = this.userModel.currentUserObservable$;
    this.eventManager.on(Event.AUTH.SESSION_TIMEOUT, this.inactiveSessionLogoutBoundFunction);
  }

  public ngOnDestroy(): void {
    this.eventManager.destroyListener(Event.AUTH.SESSION_TIMEOUT, this.inactiveSessionLogoutBoundFunction);
    this.portalUtil.unregisterPortalOutlet(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public logout(): void {
    this.authModel
      .logout()
      .then(() => {
        this.application.destroy$.next();
        this.application.destroy$.complete();
      })
      .catch((_error) => {});
  }

  public showAccountDetails(): void {
    this.stateService.go(State.MAIN.ACCOUNT.DETAILS);
  }

  public changePassword(): void {
    this.stateService.go(State.MAIN.ACCOUNT.CHANGE_PASSWORD);
  }

  public changeLanguage(language: LanguageVersion): void {
    this.applicationModel.setLanguage(language);
  }

  public goToSideBarItem(sideBarItem: SideBarItem): void {
    if (sideBarItem.callback && _.isFunction(sideBarItem.callback)) {
      sideBarItem.callback();
    }

    if (sideBarItem.stateName) {
      this.stateService.go(sideBarItem.stateName, sideBarItem.stateParameters);
    }

    this.hideSideBar();
  }

  public toggleSideBar(): void {
    this.sideBarActive = !this.sideBarActive;
  }

  public hideSideBar(): void {
    this.sideBarActive = false;
  }

  public onMouseDown(_event): void {
    this.authModel.resetSessionTimeout();
  }

  private inactiveSessionLogout(): void {
    this.viewUtil.closeAllModals();
    this.logout();
    this.viewUtil.showToastWarning('MESSAGE.INACTIVITY_LOGGED_OUT', 'MESSAGE.SESSION_ENDED');
  }

  //TODO add stateName when the specific feature branch is being merged
  private createSidebarItemsForUserType(): void {
    let sideBarItems: SideBarItem[];
    const userType: UserType = this.currentUser?.userType;

    switch (userType) {
      case UserType.SENIOR: {
        sideBarItems = [
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.DASHBOARD',
            image: 'asset/image/icon/layout-grid.svg',
            stateName: State.MAIN.DASHBOARD,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.LIVE_CLASSES',
            image: 'asset/image/icon/movie.svg',
            stateName: State.MAIN.SENIOR.LIVE_CLASSES.LIST,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.ON_DEMAND',
            image: 'asset/image/icon/folders.svg',
            stateName: State.MAIN.SENIOR.ON_DEMAND.VIDEO.LIST,
            includeToBeActive: State.MAIN.SENIOR.ON_DEMAND.ON_DEMAND,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.MY_SCHEDULE',
            image: 'asset/image/icon/calendar-due.svg',
            stateName: State.MAIN.SENIOR.MY_SCHEDULE,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.MY_ACTIVITY',
            image: 'asset/image/icon/trending-up.svg',
            stateName: State.MAIN.SENIOR.MY_ACTIVITY.LIST,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.FRIENDS',
            image: 'asset/image/icon/friends.svg',
            stateName: State.MAIN.SENIOR.FRIEND.LIST,
            includeToBeActive: State.MAIN.SENIOR.FRIEND.FRIEND,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.HELP',
            image: 'asset/image/icon/clipboard-text.svg',
            stateName: State.MAIN.HELP,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.ACCOUNT',
            image: 'asset/image/icon/settings.svg',
            stateName: State.MAIN.ACCOUNT.DETAILS,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.MAIN.LOGOUT',
            image: 'asset/image/icon/logout.svg',
            stateName: State.MAIN.ACCOUNT.LOGOUT,
            callback: () => {
              this.logout();
            },
            selected: false,
            visible: true
          })
        ];
        break;
      }
      case UserType.ADMIN: {
        sideBarItems = [
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.STATS',
            image: 'asset/image/icon/level-1.svg',
            stateName: State.MAIN.DASHBOARD,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.USERS',
            image: 'asset/image/icon/users.svg',
            stateName: State.MAIN.ADMIN.USER.LIST,
            includeToBeActive: State.MAIN.ADMIN.USER.USER,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.COACHES',
            image: 'asset/image/icon/user-heart.svg',
            stateName: State.MAIN.ADMIN.COACHES.LIST,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.LIVE_CLASSES',
            image: 'asset/image/icon/movie.svg',
            stateName: State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.LIST,
            includeToBeActive: State.MAIN.ADMIN.LIVE_CLASSES.LIVE_CLASSES,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.ON_DEMAND',
            image: 'asset/image/icon/folders.svg',
            stateName: State.MAIN.ADMIN.ON_DEMAND.LIST,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.ORGANIZATIONS',
            image: 'asset/image/icon/building-skyscraper.svg',
            stateName: State.MAIN.ADMIN.ORGANIZATIONS.LIST,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.ACCOUNT',
            image: 'asset/image/icon/settings.svg',
            stateName: State.MAIN.ACCOUNT.DETAILS,
            includeToBeActive: State.MAIN.ACCOUNT.ACCOUNT,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.MAIN.LOGOUT',
            image: 'asset/image/icon/logout.svg',
            stateName: State.MAIN.ACCOUNT.LOGOUT,
            callback: () => {
              this.logout();
            },
            selected: false,
            visible: true
          })
        ];
        break;
      }
      case UserType.COACH: {
        sideBarItems = [
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.MY_SCHEDULE',
            image: 'asset/image/icon/calendar-due.svg',
            stateName: State.MAIN.DASHBOARD,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.MY_STATS',
            image: 'asset/image/icon/level-1.svg',
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.CLASSES',
            image: 'asset/image/icon/movie.svg',
            stateName: State.MAIN.COACH.LIVE_CLASSES.LIST,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.ON_DEMAND',
            image: 'asset/image/icon/folders.svg',
            stateName: State.MAIN.COACH.ON_DEMAND.LIST,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.ACCOUNT',
            image: 'asset/image/icon/settings.svg',
            stateName: State.MAIN.ACCOUNT.DETAILS,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.MAIN.LOGOUT',
            image: 'asset/image/icon/logout.svg',
            stateName: State.MAIN.ACCOUNT.LOGOUT,
            callback: () => {
              this.logout();
            },
            selected: false,
            visible: true
          })
        ];
        break;
      }
      default: // guest (not logged in)
        sideBarItems = [
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.BACK_TO_LOGIN',
            image: 'asset/image/icon/arrow-left.svg',
            stateName: State.PRELIMINARY.LOGIN,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.DASHBOARD',
            image: 'asset/image/icon/layout-grid.svg',
            stateName: State.MAIN.DASHBOARD,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.LIVE_CLASSES',
            image: 'asset/image/icon/movie.svg',
            stateName: State.MAIN.SENIOR.LIVE_CLASSES.LIST,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.ON_DEMAND',
            image: 'asset/image/icon/folders.svg',
            stateName: State.MAIN.SENIOR.ON_DEMAND.VIDEO.LIST,
            includeToBeActive: State.MAIN.SENIOR.ON_DEMAND.ON_DEMAND,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.MY_SCHEDULE',
            image: 'asset/image/icon/calendar-due.svg',
            stateName: State.MAIN.SENIOR.MY_SCHEDULE,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.MY_ACTIVITY',
            image: 'asset/image/icon/trending-up.svg',
            stateName: State.MAIN.SENIOR.MY_ACTIVITY.LIST,
            selected: false,
            visible: true
          }),
          ObjectUtil.plainToClass(SideBarItem, {
            label: 'VIEW.MAIN.SIDEBAR.FAQ',
            image: 'asset/image/icon/clipboard-text.svg',
            stateName: State.MAIN.FAQ,
            selected: false,
            visible: true
          })
        ];
        break;
    }
    this.applicationModel.sideBarItems = sideBarItems;
  }

  private setActiveSidebarItem(currentState: Ng2StateDeclaration): void {
    if (!currentState) {
      return;
    }

    this.sideBarItems = this.sideBarItems.map((item: SideBarItem): SideBarItem => {
      return {
        ...item,
        selected: !item.includeToBeActive ? item.stateName === currentState.name : currentState.name.includes(item.includeToBeActive)
      };
    });
  }
}

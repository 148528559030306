import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ZoomConfiguration } from 'app/data/local/zoom/ZoomConfiguration';

@Component({
  selector: 'app-zoom-meeting',
  templateUrl: './ZoomMeetingComponent.html',
  styleUrls: [ './ZoomMeetingComponent.scss' ]
})
export class ZoomMeetingComponent {
  @ViewChild('iframe', { static: false }) iframe: ElementRef;

  @Input() public config: ZoomConfiguration;
  @Input() public username: string = 'Anonymous';

  @Output() public meetingEnd: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('window:message', [ '$event' ])
  iframeEventHandler(event: Event) {
    try {
      const message = JSON.parse((event as MessageEvent).data);

      if (message.type === 'onUserLeave' || message.type === 'onMeetingEnd') {
        this.meetingEnd.next();
      }
    } catch (ex) {

    }
  }

  onLoad() {
    const contentWindow = this.iframe.nativeElement.contentWindow;

    contentWindow.postMessage(JSON.stringify({
      type: 'init',
      data: this.config
    }), '*');
  }
}

import { RegistrationType } from 'app/data/enum/user/RegistrationType';
import { Transform } from 'class-transformer';

export class UserSeniorUpdateRequestDTO {
  @Transform(({ value }) => value?.replace(/[\s\\/]/g, '') || null, { toPlainOnly: true })
  public email?: string;

  public enabled: boolean;

  public firstName: string;

  public lastName: string;

  @Transform(({ value }) => value?.replace(/[\s\\/]/g, '') || null, { toPlainOnly: true })
  public nickname?: string;

  @Transform(({ value }) => value?.replace(/[\s\\/]/g, '') || null, { toPlainOnly: true })
  public phone?: string;

  public imageId?: number;

  public registrationType: RegistrationType;

  constructor(data?: Partial<UserSeniorUpdateRequestDTO>) {
    Object.assign(this, data);
  }
}

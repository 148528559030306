import { Injectable } from '@angular/core';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { UserModel } from 'app/model/UserModel';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { WatchPartyParticipantDTO } from 'app/data/dto/watchParty/WatchPartyParticipantDTO';
import { WatchPartyParticipantEnrolmentStatus } from 'app/data/dto/watchParty/WatchPartyParticipantEnrolmentStatus';

@Injectable({ providedIn: 'root' })
export class WatchPartyLogic {
  public static JOIN_TIME: number = 15 * 60; // seconds
  public static CANCEL_TIME: number = 0; //seconds
  public static REGISTRATION_TIME: number = 0; // seconds
  public static DROP_TIME: number = 0; //seconds
  public static PENDING_ACCEPTANCE_TIME: number = 0; //seconds

  constructor(private readonly userModel: UserModel) {}

  public isCreationAllowed = (): Observable<boolean> => {
    return this.userModel.currentUserObservable$.pipe(
      map((user: UserDTO) => !user?.watchPartyLimitReached),
      distinctUntilChanged()
    );
  };

  public isPendingAcceptance = (data: WatchPartyDTO): Observable<boolean> => {
    return of(
      !data.isHost
      && this.isPending(data)
      && WatchPartyLogic.PENDING_ACCEPTANCE_TIME <= this.leftSeconds(data.startDate));
  };

  public isCancelable = (data: WatchPartyDTO): Observable<boolean> => {
    return of(
      data.isHost
      && WatchPartyLogic.CANCEL_TIME <= this.leftSeconds(data.startDate));
  };

  public isDroppable = (data: WatchPartyDTO): Observable<boolean> => {
    return of(
      !data.isHost
      && this.isEnrolled(data)
      && WatchPartyLogic.DROP_TIME <= this.leftSeconds(data.startDate));
  };

  public isOpenForRegistration = (data: WatchPartyDTO): Observable<boolean> => {
    return of(
      !data.isHost
      && !this.currentUserInParticipants(data)?.enrollmentStatus
      && data.capacity > data.participants.length
      && WatchPartyLogic.REGISTRATION_TIME <= this.leftSeconds(data.startDate));
  };

  public isJoinable = (data: WatchPartyDTO): Observable<boolean> => {
    return of(
      this.isEnrolled(data)
      && !data.ended
      && this.leftSeconds(data.startDate) <= WatchPartyLogic.JOIN_TIME
      && this.leftSeconds(data.endDate) > 0);
  };

  public isClosed = (data: WatchPartyDTO): Observable<boolean> => {
    return of(data.capacity <= data.participants.length);
  };

  private isEnrolled = (data: WatchPartyDTO): boolean => {
    return [ WatchPartyParticipantEnrolmentStatus.ENROLLED, WatchPartyParticipantEnrolmentStatus.HOST ]
      .includes(this.currentUserInParticipants(data)?.enrollmentStatus)
  };

  private isPending = (data: WatchPartyDTO): boolean => {
    return this.currentUserInParticipants(data)?.enrollmentStatus === WatchPartyParticipantEnrolmentStatus.PENDING;
  }

  private leftSeconds = (date: Date): number => {
    return this.diffSeconds(date, new Date());
  };

  private diffSeconds = (dateA: Date, dateB: Date): number => {
    return Math.floor(moment.duration(moment(dateA).diff(moment(dateB))).asSeconds());
  };

  private currentUserInParticipants = (data: WatchPartyDTO): WatchPartyParticipantDTO => {
    return data.participants.find((participant: WatchPartyParticipantDTO) => {
      return (participant.firstName === this.userModel.currentUser?.firstName) && (participant.lastName === this.userModel.currentUser?.lastName);
    });
  };
}
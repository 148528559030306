import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import {
  WatchPartyDetailsModalComponent
} from 'app/component/view/main/onDemand/senior/view/watchParty/modal/WatchPartyDetailsModalComponent';
import { MyScheduleCriteriaDTO } from 'app/data/dto/schedule/MyScheduleCriteriaDTO';
import { MyScheduleResponseDTO } from 'app/data/dto/schedule/MyScheduleResponseDTO';
import {
  ScheduledLiveClassDetailsResponseDTO
} from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsResponseDTO';
import { TutorialType } from 'app/data/dto/user/TutorialType';
import { UserDetailsDTO } from 'app/data/dto/user/UserDetailsDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { MyScheduleTourWizardAnchor } from 'app/data/local/MyScheduleTourWizardAnchor';
import { ScheduleEventType } from 'app/data/local/schedule/ScheduleEventType';
import { AccessControlModel } from 'app/model/AccessControlModel';
import { UserModel } from 'app/model/UserModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  endOfDay,
  format,
  startOfDay,
  startOfHour,
  startOfWeek
} from 'date-fns';
import _ from 'lodash';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { TourWizardService, TourWizardStep } from 'ngx-tour-wizard';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { JoinUserComponent } from '../../../liveClasses/components/join/JoinUserComponent';
import { ScheduledLiveClassJoinComponentInput } from '../../../liveClasses/components/join/JoinUserInterface';

@Component({
  selector: 'app-my-schedule-user',
  templateUrl: 'MyScheduleUserComponent.html',
  styleUrls: [ 'MyScheduleUserComponent.scss' ]
})
export class MyScheduleUserComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly TourWizardAnchor: typeof MyScheduleTourWizardAnchor = MyScheduleTourWizardAnchor;
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  @ViewChild('scrollContainer') scrollContainer: ElementRef<HTMLElement>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  public scheduledLiveClass: ScheduledLiveClassDetailsResponseDTO[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  public bsConfig: Partial<BsDatepickerConfig>;
  public weekDisplay: string;
  private readonly canDisplayOnboardingTour: boolean = false;
  private onboardingTourStepList: TourWizardStep[] = [];
  public criteria: MyScheduleCriteriaDTO = new MyScheduleCriteriaDTO();

  public view: CalendarView = CalendarView.Week;
  public CalendarView = CalendarView;
  public viewDate: Date = new Date();
  public activeDayIsOpen: boolean = true;
  public daysInWeek: number = 7;

  public options: { name: string; view: CalendarView }[] = [];
  public formData: {
    optionSelected1: any;
    optionSelected2: any;
  } = {
    optionSelected1: null,
    optionSelected2: null
  };

  public refresh: Subject<void> = new Subject<void>();
  public currentUser: UserDTO;
  public events: CalendarEvent[] = [];

  constructor(public viewUtil: ViewUtil,
              private breakpointObserver: BreakpointObserver,
              private cd: ChangeDetectorRef,
              private userModel: UserModel,
              private modalService: BsModalService,
              private translate: TranslateService,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef,
              private tourWizardService: TourWizardService,
              private accessControlModel: AccessControlModel) {
  this.canDisplayOnboardingTour = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.currentUser = this.userModel.currentUser;
    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: 'MMMM, YYYY'
      }
    );
    this.updateCalendarView();

    this.onboardingTourStepList = [
      {
        anchorId: MyScheduleTourWizardAnchor.MY_SCHEDULE,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.MY_SCHEDULE.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.MY_SCHEDULE.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      },
      {
        anchorId: MyScheduleTourWizardAnchor.PRINT,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.MY_SCHEDULE_PRINT.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.MY_SCHEDULE_PRINT.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      }
    ];
  }

  public updateCalendarView(): void {
    const weekLaterDate = addDays(this.viewDate, this.daysInWeek - 1);

    if (this.viewDate.getMonth() !== weekLaterDate.getMonth()) {
      this.weekDisplay = `${ format(this.viewDate, 'MMMM') }/${ format(weekLaterDate, 'MMMM, yyyy') }`;
    }
    else {
      this.weekDisplay = format(this.viewDate, 'MMMM, yyyy');
    }
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.options = [
      // TODO for second phase
      // { name: this.translate.instant('COMMON.MONTH'), view: CalendarView.Month },
      { name: this.translate.instant('COMMON.WEEK'), view: CalendarView.Week }
      // { name: this.translate.instant('COMMON.DAY'), view: CalendarView.Day },
    ];

    const CALENDAR_RESPONSIVE = {
      small: {
        breakpoint: '(max-width: 576px)',
        daysInWeek: 2
      },
      medium: {
        breakpoint: '(max-width: 768px)',
        daysInWeek: 3
      },
      large: {
        breakpoint: '(max-width: 960px)',
        daysInWeek: 5
      },
      extraLarge: {
        breakpoint: '(max-width: 961px)',
        daysInWeek: 7
      }
    };

    this.breakpointObserver
      .observe(Object.values(CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint))
      .pipe(
        takeUntil(this.destroy$),
        filter((state: BreakpointState) => {
          const foundBreakpoint = Object.values(CALENDAR_RESPONSIVE).find(
            ({ breakpoint }) => !!state.breakpoints[breakpoint]
          );
          if (foundBreakpoint) {
            this.daysInWeek = foundBreakpoint.daysInWeek;
            return true;
          }
          else {
            this.daysInWeek = 7;
            return false;
          }
        }),
        debounceTime(500),
        switchMap(() => {
          const startOfTheWeek = this.viewDate;
          this.criteria.dateFrom = startOfDay(this.viewDate);
          this.criteria.dateTo = endOfDay(addDays(startOfTheWeek, this.daysInWeek - 1));
          return this.getSchedule(this.criteria);
        }),
        tap((calendarEvents: CalendarEvent[]) => {
          this.events = calendarEvents;
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public ngAfterViewInit(): void {
    const defaultOption = this.options.find((option) => option.view === CalendarView.Week);
    this.formData.optionSelected1 = defaultOption;
    this.setView(defaultOption.view);
    this.cd.detectChanges();
    this.scrollToCurrentView();
  }

  public handleViewDateChange(daysInWeek: number): void {
    this.activeDayIsOpen = false;
    this.viewDate = addDays(this.viewDate, daysInWeek);
    const startOfTheWeek = startOfWeek(this.viewDate);
    this.criteria.dateFrom = startOfDay(this.viewDate);
    this.criteria.dateTo = addDays(startOfTheWeek, this.daysInWeek + 1);
  }

  public setView(view: CalendarView): void {
    this.view = view;
  }

  public handleEvent(_action: string, event: CalendarEvent): void {
    const type = event.meta.type;

    switch (type) {
      case ScheduleEventType.WATCH_PARTY:
        return this.showWatchPartyDetails(event.meta.data);
      case ScheduleEventType.LIVE_CLASS:
        return this.showScheduledLiveClassDetails(event.meta.data);
    }
  }

  public eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  public onDateChange(event: any): void {
    if (event) {
      this.viewDate = event;
      this.updateCalendarView();
      const startOfTheWeek = this.viewDate;
      this.criteria.dateFrom = startOfDay(this.viewDate);
      this.criteria.dateTo = endOfDay(addDays(startOfTheWeek, this.daysInWeek - 1));
      this.getSchedule(this.criteria).pipe(takeUntil(this.destroy$)).subscribe();
    }
  }

  public onPrintClick(): void {
    window.print();
  }

  private getSchedule(criteria: MyScheduleCriteriaDTO): Observable<CalendarEvent[]> {
    return this.userModel.getMySchedule(criteria).pipe(
      takeUntil(this.destroy$),
      map((schedule: MyScheduleResponseDTO) => [
        ...schedule.scheduledLiveClasses.map(this.scheduledLiveClassMapper),
        ...schedule.watchParties.map(this.watchPartyMapper)
      ]),
      tap((calendarEvents: CalendarEvent[]) => {
        this.events = calendarEvents;
        if (this.accessControlModel.isFullAccess) {
          this.startOnboardingTour();
        }
      })
    );
  }

  private scrollToCurrentView(): void {
    if (this.view === CalendarView.Week || CalendarView.Day) {
      const minutesSinceStartOfDay: number = differenceInMinutes(startOfHour(new Date()), startOfDay(new Date()));
      this.scrollContainer.nativeElement.scrollTop = minutesSinceStartOfDay;
    }
  }

  private showWatchPartyDetails(watchParty: WatchPartyDTO): void {
    this.modalService.show(WatchPartyDetailsModalComponent, {
      initialState: { watchParty },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.getSchedule(this.criteria))
      )
      .subscribe(() => {
        //
      });
  }

  private showScheduledLiveClassDetails(scheduledLiveClassDetails: ScheduledLiveClassDetailsResponseDTO): void {
    const scheduledLiveClass: ScheduledLiveClassJoinComponentInput = {
      ..._.cloneDeep(scheduledLiveClassDetails),
      currentSeniorEnrollment: {
        attended: false,
        dropped: false
      }
    };
    const modal: BsModalRef = this.modalService.show(JoinUserComponent, {
      initialState: {
        scheduledLiveClass: scheduledLiveClass
      },
      class: 'modal-dialog-centered'
    });

    modal.onHide
      .pipe(
        filter((reason) => reason === JoinUserComponent.DROP || reason === JoinUserComponent.JOIN),
        switchMap(() => this.getSchedule(this.criteria)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private watchPartyMapper(data: WatchPartyDTO): CalendarEvent {
    return {
      title: `${ format(data.startDate, 'h:mmaaaaa\'m\'').toLowerCase() } ${ data.video.title }`,
      start: data.startDate,
      end: data.endDate,
      meta: {
        type: ScheduleEventType.WATCH_PARTY,
        data
      },
      cssClass: 'watch-party'
    } as CalendarEvent;
  }

  private scheduledLiveClassMapper(data: ScheduledLiveClassDetailsResponseDTO): CalendarEvent {
    return {
      title: `${ format(data.startDate, 'h:mmaaaaa\'m\'').toLowerCase() } ${ data.title }`,
      start: data.startDate,
      end: addMinutes(new Date(data.startDate), data.duration),
      meta: {
        type: ScheduleEventType.LIVE_CLASS,
        data
      },
      cssClass: 'live-class'
    } as CalendarEvent;
  }

  private startOnboardingTour() {
    if (this.currentUser?.completedTutorials?.includes(TutorialType.MY_SCHEDULE) || !this.canDisplayOnboardingTour) {
      return;
    }

    setTimeout(() => {
      this.tourWizardService.initialize(this.onboardingTourStepList);
      this.tourWizardService.start();

      // add progress per step
      this.onboardingTourStepList.forEach((step: TourWizardStep, index: number) => {
        const tourStepElement: HTMLElement = document.querySelector('[tourWizardAnchor="' + step.anchorId + '"] + tour-wizard-popper-component');
        tourStepElement.classList.add('tour-wizard-progress');
        tourStepElement.style.setProperty('--current-step', (index + 1).toString());
        tourStepElement.style.setProperty('--all-steps', (this.onboardingTourStepList.length).toString());
      });

      this.tourWizardService.end$.pipe(
        first(),
        switchMap(() => this.userModel.setCurrentUserTutorialCompletion(TutorialType.MY_SCHEDULE))
      ).subscribe((userDetails: UserDetailsDTO) => {
        this.currentUser.completedTutorials = userDetails.completedTutorials;
      });
    });
  }
}

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Transition } from '@uirouter/angular';
import Player, { Options } from '@vimeo/player';
import { VideoDetailsDTO } from 'app/data/dto/onDemandVideo/VideoDetailsDTO';
import { StateUtil } from 'app/util/StateUtil';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { VideoRatingRequestDTO } from 'app/data/dto/onDemandVideo/VideoRatingRequestDTO';
import { ViewUtil } from 'app/util/ViewUtil';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-on-demand-videos-play-video',
  templateUrl: './OnDemandVideosPlayVideoComponent.html',
  styleUrls: [ './OnDemandVideosPlayVideoComponent.scss' ]
})
export class OnDemandVideosPlayVideoComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public player: Player;

  public video: VideoDetailsDTO;
  private videoId: number;
  private videoPlayed: boolean = false;

  constructor(
    private transition: Transition,
    private onDemandVideoModel: OnDemandVideoModel,
    public stateUtil: StateUtil,
    private viewUtil: ViewUtil,
    private applicationModel: ApplicationModel,
    private portalUtil: PortalUtil,
    private viewContainerRef: ViewContainerRef) {
    this.videoId = this.transition.params().videoId;
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getVideoAndSetupPlayer();
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  private getVideoAndSetupPlayer(): void {
    this.onDemandVideoModel.getVideo(this.videoId).subscribe((video) => {
      this.video = video;
      this.setupVimeoPlayer();
    });
  }

  private setupVimeoPlayer(): void {
    const options: Options = {
      url: this.video.vimeoVideo.embedUrl,
      byline: false,
      portrait: false,
      responsive: true,
      title: false
    };
    this.player = new Player('vimeo', options);
    this.captureVideoPlayedEvent();
    this.resetOnVideoEnd();
  }

  private captureVideoPlayedEvent(): void {
    this.player.on('play', () => {
      if (!this.videoPlayed) {
        this.onDemandVideoModel.sendVideoPlayedEvent(this.videoId, this.applicationModel.platform)
          .subscribe(() => {
            this.videoPlayed = true;
          });
      }
    });
  }

  private resetOnVideoEnd(): void {
    this.player.on('ended', () => {
      this.videoPlayed = false;
    });
  }

  public rateVideo(rating: VideoRatingRequestDTO): void {
    this.onDemandVideoModel.rateVideo(this.videoId, rating)
      .subscribe({ error: (error) => this.viewUtil.handleServerError(error) });
  }
}

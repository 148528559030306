<div class="modal-header border-bottom-0 align-items-center">
  <h4 class="modal-title">{{ 'DOMAIN.WATCH_PARTY.DETAILS' | translate }}</h4>
  <div class="modal-control">
    <button type="button" class="btn" (click)="close()">
      <svg-icon src="asset/image/icon/x.svg"></svg-icon>
    </button>
  </div>
</div>
<div class="modal-body">
  <div class="row">
    <div class="d-flex flex-column col-12 mb-4">
      <span class="details-label">{{ 'VIEW.MAIN.ON_DEMAND.NAME' | translate }}</span>
      <span>{{ watchParty.video.title | replaceEmpty }}</span>
    </div>

    <div class="d-flex flex-column col-12 col-md-6 mb-4">
      <span class="details-label">{{ 'DOMAIN.WATCH_PARTY.DATA.START_DATE.LABEL' | translate }}</span>
      <span>{{ watchParty.startDate | date:'short' | replaceEmpty }}
        ({{ watchParty.video.duration }} {{ 'COMMON.UNIT.MIN' | translate }})</span>
    </div>

    <div class="d-flex flex-column col-12 col-md-6 mb-4">
      <span class="details-label">{{ 'DOMAIN.WATCH_PARTY.DATA.HOST.LABEL' | translate }}:</span>
      <div class="d-flex gap-2">
        <app-avatar [src]="watchParty.host.avatar?.defaultAsset?.url"
                    [circle]="true"
                    size="24px">
        </app-avatar>

        <span>{{ watchParty?.host?.fullName | replaceEmpty }}</span>
      </div>
    </div>

    <div class="d-flex flex-column col-12 col-md-6 mb-4">
      <span class="details-label">{{ 'VIEW.MAIN.ON_DEMAND.CATEGORY' | translate }}</span>
      <span>{{ watchParty.video.category | prefix: 'ENUM.VIDEO_CATEGORY.' | translate | replaceEmpty }}</span>
    </div>

    <div class="d-flex flex-column col-12 col-md-6 mb-4">
      <span class="details-label">{{ 'VIEW.MAIN.ON_DEMAND.LANGUAGE' | translate }}</span>
      <span>{{ watchParty.video.language | prefix: 'ENUM.LANGUAGE.' | translate | replaceEmpty }}</span>
    </div>

    <div class="d-flex flex-column col-12 col-md-6 mb-4">
      <span class="details-label">{{ 'DOMAIN.WATCH_PARTY.DATA.TYPE.LABEL' | translate }}</span>
      <span>{{ watchParty.type | prefix: 'ENUM.WATCH_PARTY_TYPE.' | translate | replaceEmpty }}</span>
    </div>

    <div class="d-flex flex-column col-12 col-md-6 mb-4">
      <span class="details-label">{{ 'DOMAIN.WATCH_PARTY.DATA.CAPACITY.LABEL' | translate }}</span>
      <span>
        {{ watchParty.participants?.length | replaceEmpty:'0' }}/{{ watchParty.capacity | replaceEmpty:'0' }}
      </span>
    </div>
  </div>
</div>

<div class="modal-footer gap-2">
  <button type="button"
          class="btn"
          (click)="close()">
    <div class="d-flex align-items-center gap-2">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </div>
  </button>

  <button type="button" class="btn"
          *appCanPerformAction="watchPartyModel.logic.isDroppable;data:watchParty"
          (click)="dropEvent(watchParty)">
    <div class="d-flex align-items-center gap-2">
      <svg-icon class="d-flex" src="asset/image/icon/circle-off.svg"></svg-icon>
      <span>{{ 'DOMAIN.WATCH_PARTY.ACTION.DROP' | translate }}</span>
    </div>
  </button>

  <button type="button" class="btn"
          *appCanPerformAction="watchPartyModel.logic.isCancelable;data:watchParty"
          (click)="cancelEvent(watchParty)">
    <div class="d-flex align-items-center gap-2">
      <svg-icon class="d-flex" src="asset/image/icon/circle-off.svg"></svg-icon>
      <span>{{ 'DOMAIN.WATCH_PARTY.ACTION.CANCEL' | translate }}</span>
    </div>
  </button>

  <button type="button"
          class="btn btn-primary"
          *appCanPerformAction="watchPartyModel.logic.isJoinable;data:watchParty"
          (click)="goToEvent(watchParty); close()">
    <svg-icon class="d-flex" src="asset/image/icon/circle-chevron-right.svg"></svg-icon>
    <span>{{ 'DOMAIN.WATCH_PARTY.ACTION.JOIN' | translate }}</span>
  </button>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { LiveClassZoomDTO } from 'app/data/dto/scheduledLiveClass/LiveClassZoomDTO';


@Injectable({ providedIn: 'root' })
export class LiveClassZoomMeetingService {
  constructor(private httpClient: HttpClient) {
  }

  public getLiveClassZoomConfiguration(liveClassId: number): Observable<LiveClassZoomDTO> {
    return this.httpClient.get(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ liveClassId }/zoom`).pipe(
      map((response: LiveClassZoomDTO) => ObjectUtil.plainToClass(LiveClassZoomDTO, response))
    );
  }
}
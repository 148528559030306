<div class="modal-header border-bottom-0 align-items-center">
  <h4 class="modal-title">{{ title | translate }}</h4>
  <div class="modal-control">
    <button type="button" class="btn" (click)="close()">
      <svg-icon src="asset/image/icon/x.svg"></svg-icon>
    </button>
  </div>
</div>

<div class="d-flex flex-column modal-footer border-top-0">
  <div class="form-group w-50">
    <ng-select type="text" id="plan" name="plan" [(ngModel)]="selectedOffer" [items]="offers$ | async"
               [clearable]="false" [searchable]="false"
               [placeholder]="'VIEW.MAIN.ACCOUNT.BILLING_INFO.MODAL.MEMBERSHIP_PLAN.CHOOSE_OFFER' | translate">
      <ng-template ng-option-tmp
                   let-item="item">{{ 'ENUM.OFFER_RECURRING_TYPE.' + item.recurringType | translate }}
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        {{ 'ENUM.OFFER_RECURRING_TYPE.' + item.recurringType | translate }}
      </ng-template>
    </ng-select>

    <div class="content mt-5" *ngIf="selectedOffer">
      <h3>{{ selectedOffer.productName }}</h3>
      <p class="font-size-sm">{{ selectedOffer.productDescription | translate }}</p>

      <div class="price-section">
        <div class="price">{{ selectedOffer.priceAmount / 100 | currency:selectedOffer.currency.toUpperCase() }}</div>
        <div class="recurring">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MODAL.MEMBERSHIP_PLAN.RECURRING.' + selectedOffer.recurringType | translate }}</div>
      </div>

      <button type="button"
              class="btn btn-primary btn-block mt-5"
              [disabled]="submitBlocked"
              (click)="subscribe()">{{ 'VIEW.MAIN.ACCOUNT.BILLING_INFO.MODAL.MEMBERSHIP_PLAN.SUBSCRIBE' | translate }}
      </button>
    </div>
  </div>
</div>

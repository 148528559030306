import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-full-screen-toggle',
  templateUrl: 'FullScreenToggleComponent.html',
  styleUrls: [ 'FullScreenToggleComponent.scss' ]
})
export class FullScreenToggleComponent {
  @Input()
  public classes: string = 'btn btn-sm btn-square btn-outline-primary';

  @Input()
  public target: HTMLElement;

  public isFullScreen: boolean = false;

  public toggleFullScreen(): void {
    if (this.isFullScreen) {
      document.exitFullscreen()
        .then(() => {
          this.isFullScreen = false;
        });
    }
    else {
      this.target.requestFullscreen()
        .then(() => {
          this.isFullScreen = true;
        });
    }
  }
}

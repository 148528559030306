<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.ON_DEMAND.ON_DEMAND' | translate }}</h1>
</ng-template>

<section class="section">
  <div>
    <app-on-demand-tabs></app-on-demand-tabs>
  </div>

  <ng-container *ngIf="page">
    <div>
      <app-on-demand-filter-list [criteria]="criteria"
                                 [excludedSortBy]="excludedSortBy"
                                 (criteriaChange)="onCriteriaChanged($event)">
        <button
          id="filter-videos"
          name="filter-videos"
          class="btn btn-select d-flex justify-content-between align-items-center gap-2"
          (click)="openFiltersModal()"
          [tourWizardAnchor]="TourWizardAnchor.FILTER"
        >
          <svg-icon src="asset/image/icon/filter.svg"></svg-icon>
          <span>{{ 'COMMON.FILTER_BY' | translate }}</span>
          <svg-icon src="asset/image/icon/chevron-down.svg"></svg-icon>
        </button>
      </app-on-demand-filter-list>

      <app-on-demand-video-category [selected]="selectedCategory" [tourWizardAnchor]="TourWizardAnchor.QUICK_FILTER"
                                    (selectedChange)="changeCategory($event)"></app-on-demand-video-category>
    </div>
    <div [tourWizardAnchor]="TourWizardAnchor.THUMBNAILS">
      <div id="videos-container" class="mb-4" [tourWizardAnchor]="TourWizardAnchor.HEART">
        <app-on-demand-videos-item
          *ngFor="let video of page?.content"
          [data]="video"
          [subscriptionTier]="userModel.userTierLevel | async"
          [guestMode]="accessControlModel.isLimitedAccess$ | async"
          (favoriteClicked)="changeIsFavoriteVideo(video)"
        ></app-on-demand-videos-item>
      </div>
    </div>

    <ng-container [ngTemplateOutlet]="paginationTemplate"></ng-container>
  </ng-container>
</section>


<!--Templates-->
<ng-template #paginationTemplate>
  <pagination
    class="justify-content-center mb-0 on-demand-pagination"
    [totalItems]="page?.totalElements"
    [itemsPerPage]="criteria?.pageSize"
    [maxSize]="maxNumberOfPages"
    [rotate]="true"
    [boundaryLinks]="false"
    [customPreviousTemplate]="prevTemplate"
    [customNextTemplate]="nextTemplate"
    [pageBtnClass]="'pagination-btn'"
    [(ngModel)]="internalPage"
    (pageChanged)="onPageChanged($event)"
  >
  </pagination>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
</ng-template>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
  <svg-icon src="asset/image/icon/chevron-right.svg"></svg-icon>
</ng-template>

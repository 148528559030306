import { BaseLiveClass } from 'app/data/dto/scheduledLiveClass/BaseLiveClass';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { SubscriptionModel } from 'app/model/SubscriptionModel';
import { UserModel } from 'app/model/UserModel';
import { Observable, of } from 'rxjs';
import { DateUtil } from 'app/util/DateUtil';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class LiveClassLogic {
  public static JOIN_TIME: number = 15 * 60; // seconds

  constructor(private readonly subscriptionModel: SubscriptionModel,
              private readonly userModel: UserModel) {
  }

  public isJoinable = (data: BaseLiveClass): Observable<boolean> => {
    if (!data) {
      return of(false);
    }

    const endDate = moment(data.startDate).add(data.duration, 'minutes').toDate();

    return of(data
      && DateUtil.leftSeconds(data.startDate) <= LiveClassLogic.JOIN_TIME
      && DateUtil.leftSeconds(endDate) > 0);
  };

  public isRegistrationAllowed = (liveClass: BaseLiveClass): Observable<boolean> => {
    return of(this.isRegistrationAvailable(liveClass));
  };

  public isBulkRegistrationAllowed = (liveClasses: BaseLiveClass[]): Observable<boolean> => {
    return of(_.every(liveClasses, (item: BaseLiveClass) =>
      item.liveClassSeriesId === null && this.isRegistrationAvailable(item)));
  };

  private isRegistrationAvailable = (liveClass: BaseLiveClass): boolean => {
    const isSenior = this.userModel.currentUser.isSenior;
    const isSubscriptionActive = this.subscriptionModel.isSubscriptionActive;
    const currentSeniorEnrollment = liveClass?.currentSeniorEnrollment;
    const isDropped = currentSeniorEnrollment?.dropped;

    if (isSenior) {
      return isSubscriptionActive && (!currentSeniorEnrollment || isDropped);
    }
    else {
      return !currentSeniorEnrollment || isDropped;
    }
  };
}
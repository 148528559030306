import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { SubscriptionStoreComponent } from 'app/component/SubscriptionStoreComponent';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { UserModel } from 'app/model/UserModel';
import { zip } from 'rxjs';
import { skip, switchMap } from 'rxjs/operators';
import { SideBarItem } from 'app/data/local/ui/SideBarItem';
import * as _ from 'lodash';
import { ApplicationState } from 'app/data/local/ApplicationState';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { StateUtil } from 'app/util/StateUtil';
import { State } from 'app/common/State';
import { SubscriptionModel } from 'app/model/SubscriptionModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';
import { TemplatePortal } from '@angular/cdk/portal';

enum ViewAccess {
  FULL = 'FULL',
  UNAUTHORIZED = 'UNAUTHORIZED',
  LIMITED = 'LIMITED',
  LIMITED_BY_TIER_LEVEL = 'LIMITED_BY_TIER_LEVEL',
}

@Component({
  selector: 'app-custom-ui-view',
  templateUrl: 'CustomUiViewComponent.html',
  styleUrls: [ 'CustomUiViewComponent.scss' ]
})
export class CustomUiViewComponent extends SubscriptionStoreComponent {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public viewAccess: ViewAccess;

  public currentSideBarItem: SideBarItem;

  public ViewAccess: typeof ViewAccess = ViewAccess;
  public State: typeof State = State;

  constructor(public subscriptionModel: SubscriptionModel,
              private applicationModel: ApplicationModel,
              private userModel: UserModel,
              private stateUtil: StateUtil,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    super();

    this.subscription = zip(this.applicationModel.currentState$, this.userModel.currentUser$)
      .pipe(switchMap(() => this.applicationModel.currentState$.pipe(skip(1))))
      .subscribe((nextState) => {
        if (nextState?.state?.name) {
          this.processVisibility(nextState, this.userModel.currentUser);
          setTimeout(() => {
            this.getCurrentSideBarItem(nextState);
          });
        }
      });
  }

  private processVisibility(state: ApplicationState, currentUser: UserDTO): void {
    const isAuthorized: boolean = !!currentUser;
    const isPlaceholderPage = state?.state.data?.showGuestPlaceholder;

    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);

    // Guest senior view
    if (!isAuthorized) {
      this.viewAccess = isPlaceholderPage ? ViewAccess.UNAUTHORIZED : ViewAccess.FULL;
    }
    else if (currentUser?.isSenior) {
      const invalidSubscription: boolean = !currentUser.subscription.active;

      if (invalidSubscription) {
        this.viewAccess = isPlaceholderPage ? ViewAccess.LIMITED : ViewAccess.FULL;
      }
      else {
        const isOrganization = currentUser.subscription.type === SubscriptionType.ORGANIZATION;
        const isTierDefined = state?.state.data?.tiers;
        const isTierMatch = isTierDefined && state?.state.data?.tiers.includes(currentUser.subscription.tier);

        if (isOrganization && isTierDefined) {
          this.viewAccess = isTierMatch ? ViewAccess.FULL : ViewAccess.LIMITED_BY_TIER_LEVEL;
        }
        else {
          this.viewAccess = ViewAccess.FULL;
        }
      }
    }
    else {
      this.viewAccess = ViewAccess.FULL;
    }

    if (this.viewAccess !== ViewAccess.FULL) {
      this.attachHeader();
    }
  }

  private getCurrentSideBarItem(state: ApplicationState): void {
    this.currentSideBarItem = _.find(this.applicationModel.sideBarItems, [ 'stateName', state?.state.name ]);
  }

  public goToRegistration(): void {
    this.stateUtil.goToState(State.PRELIMINARY.REGISTRATION.REGISTRATION);
  }

  public goToLogin(): void {
    this.stateUtil.goToState(State.PRELIMINARY.LOGIN);
  }

  public goToAccountDetails(): void {
    this.stateUtil.goToState(State.MAIN.ACCOUNT.DETAILS, { tab: 'BILLING_INFO' });
  }

  private attachHeader(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
  }
}
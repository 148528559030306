<ng-template #headingTemplate>
  <h1 [tourWizardAnchor]="TourWizardAnchor.ACCOUNT">{{ 'VIEW.MAIN.ACCOUNT.ACCOUNT' | translate }}</h1>
</ng-template>

<section id="account" class="section">
  <ng-container *ngIf="currentUser$ | async as currentUser">
    <div class="d-flex flex-row justify-content-between flex-wrap gap-4 mb-4 profile-header">
      <div class="d-flex flex-row align-items-end">
        <app-avatar [src]="currentUser.avatar?.defaultAsset?.url"
                    size="100px"
                    [circle]="true">
        </app-avatar>
        <div class="d-flex flex-column ml-4">
          <h2 class="font-weight-bold font-size-lg">
            {{ currentUser.firstName | fullName: currentUser.lastName : true }}
          </h2>
          <span (click)="onChangePhotoClick()" type="button" class="font-weight-bold change-photo">
            {{ 'VIEW.MAIN.ACCOUNT.DETAILS.CHANGE_PHOTO' | translate }}
          </span>
          <span *ngIf="currentUser.avatar" (click)="onRemovePhotoClick()" type="button" class="text-danger">
            {{ 'VIEW.MAIN.ACCOUNT.DETAILS.REMOVE_PHOTO' | translate }}
          </span>
        </div>
      </div>
      <button
        *ngIf="userType !== UserType.ADMIN"
        class="btn btn-outline-primary refer-friend"
        (click)="openReferFriendModal()"
      >
        {{ "VIEW.MAIN.ACCOUNT.DETAILS.REFER_A_FRIEND" | translate }}
      </button>
    </div>

    <app-tabs [translationPrefix]="'VIEW.MAIN.ACCOUNT.TABS.'"
              [tabs]="tabs"
              [activeTab]="selectedTab"
              (tabChanged)="onTabChanged($event)">
    </app-tabs>

    <ng-container [ngSwitch]="selectedTab">
      <app-account-details-profile [userType]="userType" *ngSwitchCase="availableTabs.PROFILE">
      </app-account-details-profile>
      <app-account-details-preferences [userType]="userType" *ngSwitchCase="availableTabs.PREFERENCES">
      </app-account-details-preferences>
      <app-account-change-password *ngSwitchCase="availableTabs.PASSWORD"></app-account-change-password>
      <ng-container *ngSwitchCase="availableTabs.DATA_PRIVACY">
        <div>
          <button type="button" class="btn btn-link" (click)="onResourceClick(termsAndConditionsUrl)">
            <u>{{ 'VIEW.MAIN.ACCOUNT.DATA_PRIVACY.TERMS_AND_CONDITIONS' | translate }}</u>
          </button>
        </div>
        <div>
          <button type="button" class="btn btn-link" (click)="onResourceClick(privacyPolicyUrl)">
            <u>{{ 'VIEW.MAIN.ACCOUNT.DATA_PRIVACY.PRIVACY_POLICY' | translate }}</u>
          </button>
        </div>
      </ng-container>

      <app-account-billing-info *ngSwitchCase="availableTabs.BILLING_INFO"></app-account-billing-info>
    </ng-container>
  </ng-container>
</section>

import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'split' })
export class SplitPipe implements PipeTransform {

  public transform(value: any, separator: string = ', '): any {
    if (value) {
      return _.split(value, separator);
    }
    else {
      return value;
    }
  }

}

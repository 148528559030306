import { Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { OrganizationAddressDTO } from './OrganizationAddressDTO';
import { SubscriptionTier } from 'app/data/enum/SubscriptionTier';

export class OrganizationCreateRequestDTO extends IdentifiableObjectDTO<number> {
  @Type(() => OrganizationAddressDTO)
  address?: OrganizationAddressDTO;
  apiUrl?: string;
  contactName: string;
  imageId?: number;
  name: string;
  phone?: string;
  type: string;
  subscriptionTier: SubscriptionTier;
}

import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constant } from 'app/common/Constant';
import { CoachCertificateDTO } from 'app/data/dto/coach/CertificateDTO';
import { CoachHobbyDTO } from 'app/data/dto/coach/CoachHobbyDTO';
import { CoachProfileResponseDTO } from 'app/data/dto/coach/CoachProfileResponseDTO';
import { CoachProfileUpdateRequestDTO } from 'app/data/dto/coach/CoachProfileUpdateRequestDTO';
import { CoachEducationDTO } from 'app/data/dto/coach/EducationDTO';
import { SeniorProfileDTO } from 'app/data/dto/senior/SeniorProfileDTO';
import { CurrentUserProfileDTO } from 'app/data/dto/user/CurrentUserProfileDTO';
import { UserAddressDTO } from 'app/data/dto/user/UserAddressDTO';
import { UserProfileDTO } from 'app/data/dto/user/UserProfileDTO';
import { CountryCode } from 'app/data/enum/CountryCode';
import { UserGender } from 'app/data/enum/account/UserGender';
import { UserType } from 'app/data/enum/user/UserType';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { CoachModel } from 'app/model/CoachModel';
import { SeniorModel } from 'app/model/SeniorModel';
import { UserModel } from 'app/model/UserModel';
import { ViewUtil } from 'app/util/ViewUtil';
import _ from 'lodash';
import { of, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';

@Component({
  selector: 'app-account-details-profile',
  templateUrl: './AccountDetailsProfileComponent.html',
  styleUrls: [ './AccountDetailsProfileComponent.scss' ]
})
export class AccountDetailsProfileComponent implements OnChanges, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  @Input()
  public userType: UserType;
  public hobbiesFormStatus: boolean = false;
  public educationFormStatus: boolean = false;
  public certificationFormStatus: boolean = false;
  public uploadStarted: boolean = false;
  public userProfile: SeniorProfileDTO;
  public adminProfile: UserProfileDTO;
  public coachProfile: CoachProfileResponseDTO;
  public coachProfileUpdate: CoachProfileUpdateRequestDTO;
  public userGenderSelectList: OptionItem<UserGender>[] = this.viewUtil.createEnumSelectOptions(
    UserGender,
    'USER_GENDER'
  );

  public editMode: boolean = false;
  public Constant = Constant;
  public RegistrationType: typeof RegistrationType = RegistrationType;
  public maxDateOfBirth: Date = this.getYesterdayDate();
  public countries: OptionItem<CountryCode>[] = this.viewUtil.createEnumSelectOptions(CountryCode, 'COUNTRIES');
  @ViewChild('userForm')
  private userForm: NgForm;

  @ViewChild('adminForm')
  private adminForm: NgForm;

  @ViewChild('coachForm')
  private coachForm: NgForm;

  constructor(
    private seniorModel: SeniorModel,
    private viewUtil: ViewUtil,
    public userModel: UserModel,
    public coachModel: CoachModel
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userType) {
      this.getUserBasedOnType();
    }
  }

  public editProfile(): void {
    this.editMode = true;
  }

  public cancelEdit(): void {
    this.editMode = false;
  }

  public saveChanges(): void {
    const actions = {
      [UserType.SENIOR]: () => ({ form: this.userForm, model: this.seniorModel, profile: this.userProfile }),
      [UserType.ADMIN]: () => ({ form: this.adminForm, model: this.userModel, profile: this.adminProfile }),
      [UserType.COACH]: () => ({ form: this.coachForm, model: this.coachModel, profile: this.coachProfileUpdate })
    };
    const action = actions[this.userType];
    if (!action) return;
    const { form, model, profile } = action();
    form.onSubmit(null);

    if (!form.form.valid) return;

    this.updateProfile(model, profile);
  }

  public handleUpdatedHobbies(items): void {
    this.coachProfileUpdate.coachHobbies = _.cloneDeep(items);
  }

  public handleUpdatedEducations(items): void {
    this.coachProfile.coachEducations = _.cloneDeep(items);
  }

  public handleUpdatedCertification(items): void {
    this.coachProfile.coachCertifications = _.cloneDeep(items);
  }

  public onHobbiesFormStatusChange(status: boolean): void {
    this.hobbiesFormStatus = status;
  }

  public onEducationFormStatusChange(status: boolean): void {
    this.educationFormStatus = status;
  }

  public onCertificationFormStatusChange(status: boolean): void {
    this.certificationFormStatus = status;
  }

  private getUserBasedOnType(): void {
    switch (this.userType) {
      case UserType.SENIOR:
        this.getSeniorProfile();
        break;
      case UserType.ADMIN:
        this.getUserProfile();
        break;
      case UserType.COACH:
        this.getCoachProfile();
        break;
    }
  }

  private updateProfile(model, profile): void {
    model
      .updateCurrentUserProfile(profile)
      .pipe(
        tap((res) => {
          this.viewUtil.showToastSuccess('VIEW.MAIN.ACCOUNT.UPDATE.SUCCESS');
          this.editMode = false;
          this.userModel.getCurrentUser();
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  private getSeniorProfile(): void {
    this.seniorModel
      .getCurrentSeniorProfile()
      .pipe(
        map((profile) => {
          const seniorProfile = profile;
          if (!seniorProfile.address) {
            seniorProfile.address = new UserAddressDTO();
          }
          return seniorProfile;
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe((profile) => {
        this.userProfile = profile;
      });
  }

  private getUserProfile(): void {
    this.userModel
      .getCurrentUserProfile()
      .pipe(
        tap((user: UserProfileDTO) => {
          this.adminProfile = user;
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  private getCoachProfile(): void {
    this.coachProfileUpdate = new CoachProfileUpdateRequestDTO();
    this.coachProfileUpdate.coachCertifications = [ new CoachCertificateDTO() ];
    this.coachProfileUpdate.coachEducations = [ new CoachEducationDTO() ];
    this.coachProfileUpdate.coachHobbies = [ new CoachHobbyDTO() ];
    this.coachProfileUpdate.user = new CurrentUserProfileDTO();
    this.coachModel
      .getCurrentUserProfile()
      .pipe(
        tap(({ bio, coachCertifications, coachEducations, coachHobbies, location, user }) => {
          const { firstName, lastName, email, phone, nickname, avatar } = user;
          this.coachProfileUpdate = {
            bio,
            coachCertifications,
            coachEducations,
            coachHobbies,
            location,
            user: {
              firstName,
              lastName,
              email,
              phone,
              nickname,
              imageId: avatar?.id
            }
          };
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  private getYesterdayDate(): Date {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  }
}

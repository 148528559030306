<div *ngIf="user$ | async as senior" class="d-flex gap-6 flex-lg-row flex-column align-items-start">
  <div class="w-100 w-lg-auto text-center">
    <app-avatar [src]="senior.user?.avatar?.defaultAsset?.url" size="160px"></app-avatar>
  </div>

  <div class="flex-grow-1 word-break">
    <div class="row d-flex no-gutters mb-4 justify-content-between align-items-center">
      <div>
        <div class="text-sm-light">{{ 'VIEW.MAIN.USER.NAME' | translate }}</div>
        <h2 class="m-0">{{ senior.user | fullName }}</h2>
      </div>
      <button
        type="button"
        (click)="onResendPasswordClick()"
        *ngIf="!senior.user.hasSelfRegistered"
        id="back-button"
        class="btn btn-primary"
      >
        <span class="d-flex flex-row justify-content-center align-items-center">
          <span>{{ 'VIEW.MAIN.USER.RESEND_PASSWORD' | translate }}</span>
        </span>
      </button>
    </div>

    <hr>

    <div class="row no-gutters gap-4 mt-4">
      <div class="col d-flex flex-column">
        <div class="text-sm-light">{{ 'VIEW.MAIN.USER.FIRST_NAME' | translate }}</div>
        <div>{{ senior.user.firstName | replaceEmpty }}</div>
      </div>
      <div class="col d-flex flex-column">
        <div class="text-sm-light">{{ 'VIEW.MAIN.USER.LAST_NAME' | translate }}</div>
        <div>{{ senior.user.lastName | replaceEmpty }}</div>
      </div>
      <div class="col d-flex flex-column">
        <div class="text-sm-light">{{ 'VIEW.MAIN.USER.NICKNAME' | translate }}</div>
        <div>{{ senior.user?.nickname | replaceEmpty }}</div>
      </div>
      <div class="col d-flex flex-column">
        <div class="text-sm-light">{{ 'VIEW.MAIN.USER.GENDER' | translate }}</div>
        <div>{{ senior?.gender ? (senior.gender | prefix: 'ENUM.USER_GENDER.'  | translate) : '-' }}</div>
      </div>
    </div>
    <div class="row no-gutters gap-4 mt-4">
      <div class="col d-flex flex-column">
        <div class="text-sm-light">{{ 'VIEW.MAIN.USER.USERNAME_TYPE' | translate }}</div>
        <div>{{ senior.user.registrationType  | prefix: 'VIEW.MAIN.USER.' | translate }}</div>
      </div>

      <div class="col d-flex flex-column">
        <div class="text-sm-light">{{ 'VIEW.MAIN.USER.EMAIL' | translate }}</div>
        <div>{{ senior.user.email | replaceEmpty }}</div>
      </div>
      <div class="col d-flex flex-column">
        <div class="text-sm-light">{{ 'VIEW.MAIN.USER.PHONE' | translate }}</div>
        <div>{{ senior.user.phone | replaceEmpty }}</div>
      </div>
      <div class="col d-flex flex-column">
        <div class="text-sm-light">{{ 'VIEW.MAIN.USER.DATE_OF_BIRTH' | translate }}</div>
        <div>{{ senior.dateOfBirth | date: 'dd/MM/yyyy' }}</div>
      </div>
      <div class="col d-flex flex-column">
        <div class="text-sm-light">{{ 'VIEW.MAIN.USER.STATUS' | translate }}</div>
        <div>
          {{ (senior.user?.enabled ? 'VIEW.MAIN.USER.ACTIVE' : 'VIEW.MAIN.USER.INACTIVE') | translate | replaceEmpty }}
        </div>
      </div>
    </div>
    <div class="row no-gutters gap-4 mt-4">
      <ng-container *ngIf="senior?.address as address">
        <div *ngIf="address.street" class="col d-flex flex-column">
          <div class="text-sm-light">{{ 'VIEW.MAIN.USER.STREET' | translate }}</div>
          <div>{{ address.street }}</div>
        </div>
        <div *ngIf="address.city" class="col d-flex flex-column">
          <div class="text-sm-light">{{ 'VIEW.MAIN.USER.CITY' | translate }}</div>
          <div>{{ address.city }}</div>
        </div>
        <div *ngIf="address.state" class="col d-flex flex-column">
          <div class="text-sm-light">{{ 'VIEW.MAIN.USER.STATE' | translate }}</div>
          <div>{{ address?.state }}</div>
        </div>
        <div *ngIf="address.zipCode" class="col d-flex flex-column">
          <div class="text-sm-light">{{ 'VIEW.MAIN.USER.ZIP_CODE' | translate }}</div>
          <div>{{ address?.zipCode }}</div>
        </div>
      </ng-container>
    </div>

    <hr>

    <div class="row no-gutters gap-4 mt-4">
      <!--      Subscription data-->
      <ng-container *ngIf="senior?.user?.subscription as subscription">
        <div class="col d-flex flex-column w-100">
          <div class="text-sm-light">{{ 'DOMAIN.USER.DATA.SUBSCRIPTION.TYPE.LABEL' | translate }}</div>
          <div>{{ subscription.subscriptionType | prefix:'ENUM.SUBSCRIPTION_TYPE.' | translate }}</div>
        </div>

        <!--        If organization-->
        <ng-container *ngIf="subscription?.organization as organization">
          <div class="col d-flex flex-column w-100">
            <div class="text-sm-light">{{ 'DOMAIN.USER.DATA.SUBSCRIPTION.ORGANIZATION.LABEL' | translate }}</div>
            <div>{{ organization.name | replaceEmpty }}</div>
          </div>

          <div class="col d-flex flex-column">
            <div class="text-sm-light">{{ 'VIEW.MAIN.USER.MEMBER_CODE' | translate }}</div>
            <div>{{ subscription.memberCode | replaceEmpty }}</div>
          </div>
        </ng-container>
      </ng-container>

      <!--      Other data-->
      <div class="col d-flex flex-column">
        <div class="text-sm-light">{{ 'VIEW.MAIN.USER.USER_ID' | translate }}</div>
        <div>{{ senior.id }}</div>
      </div>
      <div class="col d-flex flex-column">
        <div class="text-sm-light">{{ 'COMMON.DATE_CREATED' | translate }}</div>
        <p class="m-0">{{ senior.user.createdDate | date: 'dd/MM/yyyy' }}</p>
      </div>
    </div>
  </div>
</div>

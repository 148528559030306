import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import {
  DataGridTemplateCellRendererComponent
} from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { WatchPartyPageCriteriaDTO } from 'app/data/dto/watchParty/WatchPartyPageCriteriaDTO';
import { WatchPartyModel } from 'app/model/WatchPartyModel';
import { StateUtil } from 'app/util/StateUtil';
import { SortDirection } from 'app/data/enum/SortDirection';
import { DataGridApis } from 'app/component/ui/dataGrid/DataGridApis';

@Component({
  selector: 'app-watch-party-available-list',
  templateUrl: './WatchPartyAvailableListComponent.html'
})
export class WatchPartyAvailableListComponent implements OnInit {
  @Output() public refresh: EventEmitter<void> = new EventEmitter<void>();

  public criteria: WatchPartyPageCriteriaDTO = new WatchPartyPageCriteriaDTO();
  public page: PageDTO<WatchPartyDTO>;
  public listWindowSize: number = 3;

  public dataGridApis: DataGridApis;

  public Constant: typeof Constant = Constant;
  public State: typeof State = State;
  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent =
    DataGridTemplateCellRendererComponent;

  constructor(public readonly watchPartyModel: WatchPartyModel,
              private readonly stateUtil: StateUtil) {
    this.criteria.pageSize = this.listWindowSize;
    this.criteria.type = 'OPEN';
    this.criteria.sortOrders = [ `startDate ${SortDirection.DESCENDING}` ];
  }

  public ngOnInit(): void {
    this.loadData();
  }

  public onGridReady(apis: DataGridApis): void {
    this.dataGridApis = apis;

    // resize the "actions" column to fit the content
    setTimeout(() => {
      const columnId: string = 'actions';
      const initialWidth: number = this.dataGridApis.columnApi.getColumn(columnId).getActualWidth();

      // adjust all columns to fit the container
      this.dataGridApis.gridApi.sizeColumnsToFit();

      // auto size the column to fit the content
      this.dataGridApis.columnApi.autoSizeColumn(columnId);

      // check if the new column width is smaller than initial
      const newWidth: number = this.dataGridApis.columnApi.getColumn(columnId).getActualWidth();
      if (newWidth < initialWidth) {
        this.dataGridApis.columnApi.setColumnWidth(columnId, initialWidth);
        // adjust the rest of the columns again
        this.dataGridApis.gridApi.sizeColumnsToFit();
      }
    });
  }

  public onCriteriaChanged(criteria: PageCriteriaDTO): void {
    this.criteria = criteria as WatchPartyPageCriteriaDTO;

    this.loadData();
  }

  public nextPage(): void {
    this.criteria.pageSize += this.listWindowSize;

    this.onCriteriaChanged(this.criteria);
  }

  public loadData(): void {
    this.watchPartyModel.getPage(this.criteria)
      .subscribe((result) => {
        this.page = result;
      });
  }

  public signup(data: WatchPartyDTO): void {
    this.watchPartyModel.signup(data.id)
      .subscribe(() => {
        this.loadData();
        this.refresh.emit();
      });
  }

  public join(data: WatchPartyDTO): void {
    this.stateUtil.goToState(State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.EVENT, { id: data.id });
  }

  public drop(data: WatchPartyDTO): void {
    this.watchPartyModel.drop(data.id)
      .subscribe(() => {
        this.loadData();
        this.refresh.emit();
      });
  }

  public cancel(data: WatchPartyDTO): void {
    this.watchPartyModel.cancel(data.id)
      .subscribe(() => {
        this.loadData();
        this.refresh.emit();
      });
  }
}

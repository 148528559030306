import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { ScheduledLiveClassDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsResponseDTO';
import { AdminModel } from 'app/model/AdminModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { PageDTO } from 'app/data/dto/PageDTO';
import { ScheduledLiveClassRatingAdminPageDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassRatingAdminPageDTO';
import { ScheduledLiveClassRatingAdminPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassRatingAdminPageCriteriaDTO';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-live-class-single-details-admin',
  templateUrl: './LiveClassSingleDetailsAdminComponent.html',
  styleUrls: [ './LiveClassSingleDetailsAdminComponent.scss' ]
})
export class LiveClassSingleDetailsAdminComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public scheduledLiveClass: ScheduledLiveClassDetailsResponseDTO;
  public scheduledLiveClassId: number;

  public scheduledLiveClassRatingPage: PageDTO<ScheduledLiveClassRatingAdminPageDTO>;
  public scheduledLiveClassRatingPageCriteria: ScheduledLiveClassRatingAdminPageCriteriaDTO = new ScheduledLiveClassRatingAdminPageCriteriaDTO();

  public readonly Constant: typeof Constant = Constant;
  public readonly IntensityLevel: typeof IntensityLevel = IntensityLevel;

  constructor(private transition: Transition,
              private adminModel: AdminModel,
              private stateService: StateService,
              private viewUtil: ViewUtil,
              public stateUtil: StateUtil,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.scheduledLiveClassRatingPageCriteria.scheduledLiveClassIdEq = this.scheduledLiveClassId = this.transition.params().id;
    this.scheduledLiveClassRatingPageCriteria.pageSize = 25;
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getScheduledLiveClassDetails();
    this.getScheduledLiveClassRatingPage();
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  private getScheduledLiveClassDetails(): void {
    this.adminModel.getScheduledLiveClassDetails(this.scheduledLiveClassId)
      .subscribe((scheduledLiveClass: ScheduledLiveClassDetailsResponseDTO) => {
          this.scheduledLiveClass = scheduledLiveClass;
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        });
  }

  public getScheduledLiveClassRatingPage(nextPage: boolean = false): void {
    if (nextPage) {
      if ((this.scheduledLiveClassRatingPage.totalPages - 1) > this.scheduledLiveClassRatingPageCriteria.pageNumber) {
        this.scheduledLiveClassRatingPageCriteria.pageNumber += 1;
      }
      else {
        return;
      }
    }

    this.adminModel.getScheduledLiveClassRatingPage(this.scheduledLiveClassRatingPageCriteria)
      .subscribe((page: PageDTO<ScheduledLiveClassRatingAdminPageDTO>) => {
          if (nextPage) {
            this.scheduledLiveClassRatingPage.content = this.scheduledLiveClassRatingPage.content.concat(page.content);
          }
          else {
            this.scheduledLiveClassRatingPage = page;
          }
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        });
  }

  public onBackClick(): void {
    history.back();
  }

  public onEditClick(): void {
    this.stateService.go(State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.EDIT, { id: this.scheduledLiveClassId });
  }
}

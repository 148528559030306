import { ZoomConfiguration } from 'app/data/local/zoom/ZoomConfiguration';

export class LiveClassZoomDTO implements ZoomConfiguration {
  public liveClassName: string;

  public signature: string;

  public sdkKey: string;

  public meetingNumber: string;

  public password: string;

  public url: string;
}
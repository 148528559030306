export enum VideoSortBy {
  ALPHABETIC = 'ALPHABETIC',
  MOST_RECENT = 'MOST_RECENT',
  RATING = 'RATING',
}

export enum SeniorVideoSortBy {
  ALPHABETIC = 'ALPHABETIC',
  MOST_RECENT = 'MOST_RECENT',
  RATING = 'RATING',
  AVAILABLE = 'AVAILABLE'
}
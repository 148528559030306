<ng-template #headingTemplate>
  <h1>{{ 'COMMON.EDIT' | translate }} <span *ngIf="video">{{ video.title }}</span></h1>

  <div class="d-flex flex-wrap gap-4">
    <button type="button" class="btn btn-outline-primary w-100 w-sm-auto" (click)="onBackClick()">
      {{ 'COMMON.BACK' | translate }}
    </button>

    <button type="button" class="btn btn-primary w-100 w-sm-auto" (click)="deleteVideo()">
      {{ 'COMMON.DELETE' | translate }}
    </button>

    <button type="button" class="btn btn-primary w-100 w-sm-auto" (click)="saveChanges()" [disabled]="uploadStarted">
      <span *ngIf="uploadStarted" class="loader-white"></span>
      <span>{{ 'COMMON.SAVE' | translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section">
  <!-- TODO 2nd phase?
  <app-tabs [tabs]="tabs" [translationPrefix]="''"></app-tabs> -->

  <form #form="ngForm" class="d-flex flex-column justify-content-start" novalidate (keyup.enter)="saveChanges()">
    <div class="row flex-row-reverse justify-content-between">
      <div class="custom-control custom-checkbox d-flex align-items-center ml-4">
        <input
          type="checkbox"
          class="custom-control-input"
          id="recommended"
          name="recommended"
          [(ngModel)]="videoData.recommended"
        />
        <label class="custom-control-label" for="recommended"
        >{{ 'VIEW.MAIN.ON_DEMAND.RECOMMENDED' | translate }}</label
        >
      </div>
      <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
        <label for="title">{{ 'VIEW.MAIN.ON_DEMAND.TITLE' | translate }}</label>
        <input
          type="text"
          id="title"
          name="title"
          #title="ngModel"
          class="form-control"
          placeholder="{{'VIEW.MAIN.ON_DEMAND.TITLE' | translate}}"
          [ngClass]="{'is-invalid': form.submitted && title.invalid, 'is-valid': form.submitted && title.valid }"
          [(ngModel)]="videoData.title"
          required
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_128"
        />
        <div class="invalid-feedback" *ngIf="form.submitted && title.invalid">
          <div *ngIf="title.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="title.errors['maxlength']">
            {{ 'VALIDATION.MAXLENGTH' | translate:{ requiredLength: title.errors.maxlength.requiredLength } }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-xl-8">
        <div id="vimeo"></div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
        <label>{{ 'VIEW.MAIN.ON_DEMAND.LANGUAGE' | translate }}</label>
        <ng-select
          id="language"
          name="language"
          #language="ngModel"
          placeholder="{{'VIEW.MAIN.ON_DEMAND.LANGUAGE' | translate}}"
          [searchable]="false"
          [ngClass]="{'is-invalid': form.submitted && language.invalid, 'is-valid': form.submitted && language.valid }"
          [(ngModel)]="videoData.language"
          required
        >
          <ng-option *ngFor="let language of languages" [value]="language.value"
          >{{ language.label | translate }}
          </ng-option
          >
        </ng-select>
        <div class="invalid-feedback" *ngIf="form.submitted && language.invalid">
          <div *ngIf="language.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
      <div class="form-group col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
        <label>{{ 'VIEW.MAIN.ON_DEMAND.CATEGORY' | translate }}</label>
        <ng-select
          id="category"
          name="category"
          #category="ngModel"
          placeholder="{{'VIEW.MAIN.ON_DEMAND.CATEGORY' | translate}}"
          [searchable]="false"
          [ngClass]="{'is-invalid': form.submitted && category.invalid, 'is-valid': form.submitted && category.valid }"
          [(ngModel)]="videoData.category"
          required
        >
          <ng-option *ngFor="let category of categories" [value]="category.value"
          >{{ category.label | translate }}
          </ng-option
          >
        </ng-select>
        <div class="invalid-feedback" *ngIf="form.submitted && category.invalid">
          <div *ngIf="category.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
        <label>{{ 'VIEW.MAIN.ON_DEMAND.COACH' | translate }}</label>
        <ng-select
          id="coach"
          name="coach"
          #coach="ngModel"
          [placeholder]="'VIEW.MAIN.ON_DEMAND.COACH' | translate"
          [searchable]="true"
          [multiple]="true"
          [ngClass]="{'is-invalid': form.submitted && coach.invalid, 'is-valid': form.submitted && coach.valid }"
          [(ngModel)]="videoData.coachIds"
          required
        >
          <ng-option *ngFor="let coach of coaches" [value]="coach.id"
          >{{ coach.user.firstName }} {{ coach.user.lastName }}
          </ng-option
          >
        </ng-select>
        <div class="invalid-feedback" *ngIf="form.submitted && coach.invalid">
          <div *ngIf="coach.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
      <div class="form-group col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
        <label>{{ 'VIEW.MAIN.ON_DEMAND.TYPE' | translate }}</label>
        <ng-select
          id="type"
          name="type"
          #type="ngModel"
          [placeholder]="'VIEW.MAIN.ON_DEMAND.TYPE' | translate"
          [searchable]="true"
          [multiple]="true"
          [ngClass]="{'is-invalid': form.submitted && type.invalid, 'is-valid': form.submitted && type.valid }"
          [(ngModel)]="videoData.subcategories"
          required
        >
          <ng-option *ngFor="let subcategory of subcategories" [value]="subcategory.value"
          >{{ subcategory.label | translate }}
          </ng-option
          >
        </ng-select>
        <div class="invalid-feedback" *ngIf="form.submitted && type.invalid">
          <div *ngIf="type.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
        <label>{{ 'VIEW.MAIN.ON_DEMAND.INTENSITY' | translate }}</label>
        <ng-select
          id="intensity"
          name="intensity"
          #intensity="ngModel"
          [placeholder]="'VIEW.MAIN.ON_DEMAND.INTENSITY' | translate"
          [searchable]="false"
          [ngClass]="{'is-invalid': form.submitted && intensity.invalid, 'is-valid': form.submitted && intensity.valid }"
          [(ngModel)]="videoData.intensity"
          required
        >
          <ng-option *ngFor="let option of intensities" [value]="option.value"
          >{{ option.label | translate }}
          </ng-option
          >
        </ng-select>
        <div class="invalid-feedback" *ngIf="form.submitted && intensity.invalid">
          <div *ngIf="intensity.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
      <div class="form-group col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
        <label>{{ 'VIEW.MAIN.ON_DEMAND.DURATION' | translate }}</label>
        <input
          type="text"
          id="duration"
          name="duration"
          #duration="ngModel"
          class="form-control"
          placeholder="{{'VIEW.MAIN.ON_DEMAND.DURATION' | translate}}"
          [ngClass]="{'is-invalid': form.submitted && duration.invalid, 'is-valid': form.submitted && duration.valid }"
          [(ngModel)]="videoData.duration"
          required
          [min]="1"
          [max]="360"
          appInputPositiveIntegerOnly
        />
        <div class="invalid-feedback" *ngIf="form.submitted && duration.invalid">
          <div *ngIf="duration.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="duration.errors['min']">{{ 'VALIDATION.MIN_VALUE' | translate:{ min: 1 } }}</div>
          <div *ngIf="duration.errors['max']">{{ 'VALIDATION.MAX_VALUE' | translate:{ max: 360 } }}</div>
        </div>
      </div>
    </div>

    <div class="row my-4">
      <div class="form-group col-12">
        <div class="custom-control custom-checkbox d-flex align-items-center">
          <input
            type="checkbox"
            class="custom-control-input"
            id="accessibleForFirstTier"
            name="accessibleForFirstTier"
            [(ngModel)]="videoData.accessibleForFirstTier"
          />
          <label class="custom-control-label" for="accessibleForFirstTier">{{'VIEW.MAIN.ON_DEMAND.AVAILABLE_FOR_TIER_1' | translate }}</label>
        </div>
      </div>
    </div>

    <div class="row"></div>
    <div class="row">
      <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
        <label>{{ 'VIEW.MAIN.ON_DEMAND.DESCRIPTION' | translate }}</label>
        <textarea
          id="description"
          name="description"
          #description="ngModel"
          class="form-control"
          placeholder="{{'VIEW.MAIN.ON_DEMAND.DESCRIPTION' | translate}}"
          rows="5"
          [ngClass]="{'is-invalid': form.submitted && description.invalid, 'is-valid': form.submitted && description.valid }"
          [(ngModel)]="videoData.description"
          required
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_512"
        ></textarea>
        <div class="invalid-feedback" *ngIf="form.submitted && description.invalid">
          <div *ngIf="description.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="description.errors['maxlength']">
            {{
              'VALIDATION.MAXLENGTH' | translate:{ requiredLength: description.errors.maxlength.requiredLength }
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="video">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
        <app-thumbnail-and-files-upload
          [thumbnailMode]="true"
          [attachmentMode]="true"
          [thumbnailUrl]="video.thumbnail.defaultAsset.url"
          [attachments]="video.attachments"
          (startUpload)="handleStartUpload($event)"
          (attachmentIds)="updateAttachments($event)"
          (imageId)="videoData.imageId = $event"
          [isOnDemand]="true"
        ></app-thumbnail-and-files-upload>
      </div>
    </div>
  </form>
</section>

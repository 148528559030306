import { DataGridComponent } from 'app/component/ui/dataGrid/DataGridComponent';
import { ResponsiveDataGridComponent } from 'app/component/ui/responsiveDataGrid/ResponsiveDataGridComponent';

export class DataGridContext {

  // filled with DataGrid components
  public dataGridHost?: DataGridComponent | ResponsiveDataGridComponent; // also available internally for cell renderers

  // filled with DataGrid components
  public dataGridAction?: (actionName: string, args: any[]) => void;

  // filled with components embedding DataGrid components
  public host?: any;

  // filled with components embedding DataGrid components
  public additionalData?: { [key: string]: any };

}

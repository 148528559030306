import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { PageDTO } from 'app/data/dto/PageDTO';
import { SecurityDataDTO } from 'app/data/dto/auth/SecurityDataDTO';
import { MyScheduleCriteriaDTO } from 'app/data/dto/schedule/MyScheduleCriteriaDTO';
import { CurrentSeniorProfileDTO } from 'app/data/dto/senior/CurrentSeniorProfileDTO';
import { SeniorActivityCriteriaDTO } from 'app/data/dto/senior/SeniorActivityCriteriaDTO';
import { SeniorActivityDTO } from 'app/data/dto/senior/SeniorActivityDTO';
import { SeniorUpdateRequestDTO } from 'app/data/dto/senior/SeniorUpdateRequestDTO';
import { SeniorDTO } from 'app/data/dto/senior/SeniorDTO';
import { SeniorPageCriteriaDTO } from 'app/data/dto/senior/SeniorPageCriteriaDTO';
import { SeniorPreferencesDTO } from 'app/data/dto/senior/SeniorPreferencesDTO';
import { SeniorPreferencesRequestDTO } from 'app/data/dto/senior/SeniorPreferencesRequestDTO';
import { SeniorProfileDTO } from 'app/data/dto/senior/SeniorProfileDTO';
import { SeniorProfileResponseAdminDTO } from 'app/data/dto/senior/SeniorProfileResponseAdminDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SeniorServiceInterface } from './interface/SeniorServiceInterface';
import { SeniorPreferencesResponseAdminDTO } from 'app/data/dto/senior/SeniorPreferencesResponseAdminDTO';
import { OrganizationListDTO } from 'app/data/dto/registration/OrganizationListDTO';
import { SubscriptionChangeRequestDTO } from 'app/data/dto/subscription/SubscriptionChangeRequestDTO';
import { BadgeResponseDTO } from 'app/data/dto/badge/BadgeResponseDTO';
import { MyScheduleResponseDTO } from 'app/data/dto/schedule/MyScheduleResponseDTO';
import { OfferDTO } from 'app/data/dto/stripe/OfferDTO';

@Injectable({
  providedIn: 'root'
})
export class SeniorService implements SeniorServiceInterface {
  constructor(private http: HttpClient) {
  }

  public updateCurrentSenior(senior: SeniorDTO): Observable<{ senior: SeniorDTO; securityData: SecurityDataDTO }> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/seniors/me`, ObjectUtil.classToPlain(senior)).pipe(
      map((response: any) => {
        const updatedSenior: SeniorDTO = ObjectUtil.plainToClass(SeniorDTO, response);
        return { senior: updatedSenior, securityData: undefined };
      })
    );
  }

  public getSeniorPage(criteria: SeniorPageCriteriaDTO): Observable<PageDTO<SeniorDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/seniors/page`, { params: params }).pipe(
      map((response: any) => ObjectUtil.plainToClassFromExisting(new PageDTO<SeniorDTO>(SeniorDTO), response))
    );
  }

  public createSenior(senior: SeniorUpdateRequestDTO): Observable<SeniorProfileResponseAdminDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/seniors`, ObjectUtil.classToPlain(senior)).pipe(
      map((response: any) => ObjectUtil.plainToClass(SeniorProfileResponseAdminDTO, response))
    );
  }

  public deleteSenior(seniorId: number): Observable<void> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/seniors/${ seniorId }`).pipe(
      map((_response: any) => {
        return;
      })
    );
  }

  public getCurrentSeniorProfile(): Observable<SeniorProfileDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/seniors/me/profile`).pipe(
      map((response: any) => ObjectUtil.plainToClass(SeniorProfileDTO, response))
    );
  }

  public getCurrentSeniorPreferences(): Observable<SeniorPreferencesDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/seniors/me/preferences`).pipe(
      map((response: any) => ObjectUtil.plainToClass(SeniorPreferencesDTO, response))
    );
  }

  public changeSubscriptionType(data: SubscriptionChangeRequestDTO): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/seniors/me/subscription`, ObjectUtil.classToPlain(data));
  }

  public updateCurrentSeniorPreferences(seniorPreferences: SeniorPreferencesRequestDTO): Observable<unknown> {
    return this.http.put(
      `${ ApplicationConfig.apiUrl }/seniors/me/preferences`,
      ObjectUtil.classToPlain(seniorPreferences)
    );
  }

  public updateCurrentSeniorProfile(seniorProfile: CurrentSeniorProfileDTO): Observable<unknown> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/seniors/me/profile`, ObjectUtil.classToPlain(seniorProfile));
  }

  public getMySchedule(criteria: MyScheduleCriteriaDTO): Observable<MyScheduleResponseDTO> {
    const params = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/seniors/me/schedule`, { params: params }).pipe(
      map((response: MyScheduleResponseDTO) => ObjectUtil.plainToClass(MyScheduleResponseDTO, response))
    );
  }

  public getCurrentSeniorActivity(criteria: SeniorActivityCriteriaDTO): Observable<SeniorActivityDTO> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });
    return this.http.get(`${ ApplicationConfig.apiUrl }/seniors/me/activity`, { params: params }).pipe(
      map((response: any) => ObjectUtil.plainToClass(SeniorActivityDTO, response))
    );
  }

  public getSeniorPreferencesById(id: number): Observable<SeniorPreferencesResponseAdminDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/seniors/${ id }/admins/preferences`).pipe(
      map((response) => ObjectUtil.plainToClass(SeniorPreferencesResponseAdminDTO, response))
    );
  }

  public getStripeOffers(): Observable<OfferDTO[]> {
    return this.http.get<OfferDTO[]>(`${ ApplicationConfig.apiUrl }/seniors/me/stripe/prices`)
      .pipe(
        map((result: OfferDTO[]) => ObjectUtil.plainToClassArray(OfferDTO, result))
      );
  }

  public checkout(priceId: string): Observable<string> {
    return this.http.post<{
      value: string
    }>(`${ ApplicationConfig.apiUrl }/seniors/me/stripe:checkout?price-id=${ priceId }`, undefined)
      .pipe(
        map(({ value }) => value)
      );
  }

  public getStripeUrlForMembershipManagement(): Observable<string> {
    return this.http.get<{ url: string }>(`${ ApplicationConfig.apiUrl }/seniors/me/customerPortalUrl`)
      .pipe(
        map((result) => result?.url)
      );
  }

  public getOrganizationsList(): Observable<OrganizationListDTO[]> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/organizations/list`)
      .pipe(
        map((response: OrganizationListDTO[]) => ObjectUtil.plainToClassArray(OrganizationListDTO, response))
      );
  };

  public getEarnedBadgesForCurrentSenior(): Observable<BadgeResponseDTO[]> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/badges/me`)
      .pipe(
        map((response: BadgeResponseDTO[]) => ObjectUtil.plainToClassArray(BadgeResponseDTO, response))
      );
  }

  public getAvailableBadgesForCurrentSenior(): Observable<BadgeResponseDTO[]> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/badges/me/nexttoaward`)
      .pipe(
        map((response: BadgeResponseDTO[]) => ObjectUtil.plainToClassArray(BadgeResponseDTO, response))
      );
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { State } from 'app/common/State';
import { ScheduledLiveClassSeniorDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsResponseDTO';
import { GroupedByDate } from 'app/data/local/generic/GroupedByDateItem';
import { LiveClassModel } from 'app/model/LiveClassModel';
import { UserModel } from 'app/model/UserModel';
import { DateUtil } from 'app/util/DateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { BsModalService } from 'ngx-bootstrap/modal';
import { of, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { SubscriptionModel } from 'app/model/SubscriptionModel';
import { StateUtil } from 'app/util/StateUtil';

@Component({
  selector: 'app-dashboard-senior-happening-now',
  templateUrl: './DashboardSeniorHappeningNowComponent.html',
  styleUrls: [ './DashboardSeniorHappeningNowComponent.scss' ]
})
export class DashboardSeniorHappeningNowComponent implements OnInit, OnDestroy {
  @Input()
  public guestMode: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  public happeningNow: GroupedByDate<ScheduledLiveClassSeniorDetailsResponseDTO>[] = [];
  public intensity: typeof IntensityLevel = IntensityLevel;

  constructor(
    private liveClassModel: LiveClassModel,
    private stateUtil: StateUtil,
    private userModel: UserModel,
    private viewUtil: ViewUtil,
    public modalService: BsModalService,
    private applicationModel: ApplicationModel,
    private subscriptionModel: SubscriptionModel
  ) {
  }

  ngOnInit(): void {
    this.getHappeningNowClasses();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public goToLiveClasses(): void {
    this.stateUtil.goToState(State.MAIN.SENIOR.LIVE_CLASSES.LIST);
  }

  public goToMySchedule(): void {
    this.stateUtil.goToState(State.MAIN.SENIOR.MY_SCHEDULE);
  }

  public shouldShowJoinButton(liveClass: ScheduledLiveClassSeniorDetailsResponseDTO): boolean {
    const enrollment: boolean = liveClass.currentSeniorEnrollment && !liveClass.currentSeniorEnrollment.dropped;

    return !this.guestMode && this.subscriptionModel.isSubscriptionActive && (enrollment || liveClass.isHappeningNow);
  }

  public shouldDisabledJoinButton(liveClass: ScheduledLiveClassSeniorDetailsResponseDTO): boolean {
    const enrollment: boolean = liveClass.currentSeniorEnrollment === null || liveClass.currentSeniorEnrollment.dropped;

    return (liveClass.isExceeded && enrollment) || !liveClass.isHappeningNow;
  }

  public shouldShowRegisterButton(liveClass: ScheduledLiveClassSeniorDetailsResponseDTO): boolean {
    const enrollment: boolean = !liveClass.currentSeniorEnrollment || (liveClass.currentSeniorEnrollment && liveClass.currentSeniorEnrollment.dropped);

    return !this.guestMode && this.subscriptionModel.isSubscriptionActive && enrollment && !liveClass.isHappeningNow;
  }

  public shouldShowDropButton(liveClass: ScheduledLiveClassSeniorDetailsResponseDTO): boolean {
    return liveClass.currentSeniorEnrollment && !liveClass.currentSeniorEnrollment.dropped;
  }

  public shouldDisabledRegisterButton(liveClass: ScheduledLiveClassSeniorDetailsResponseDTO): boolean {
    return liveClass.isExceeded && (liveClass.currentSeniorEnrollment === null || liveClass.currentSeniorEnrollment.dropped);
  }

  public onRegisterClick(selectedClass: ScheduledLiveClassSeniorDetailsResponseDTO): void {
    this.userModel.registerToLiveClass(selectedClass).subscribe(
      () => {
        this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        this.getHappeningNowClasses();
      },
      (error) => {
        this.viewUtil.handleServerError(error);
      }
    );
  }

  public onJoinClick(selectedClass: ScheduledLiveClassSeniorDetailsResponseDTO): void {
    let windowReference: Window = window.open('about:blank', '_blank');

    this.userModel
      .joinScheduledLiveClasses(selectedClass.id, this.applicationModel.platform)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const urlPromise: Promise<string> = Promise.resolve(selectedClass.url);
          urlPromise.then((url: string) => {
            if (!url.startsWith('https://') && url.startsWith('www.')) {
              url = 'https://' + url;
            }
            windowReference.location.href = url;
          });

          this.getHappeningNowClasses();
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public onDropClick(selectedClass: ScheduledLiveClassSeniorDetailsResponseDTO): void {
    this.userModel.dropFromLiveClass(selectedClass).subscribe(
      () => {
        this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        this.getHappeningNowClasses();
      },
      (error) => {
        this.viewUtil.handleServerError(error);
      }
    );
  }

  private getHappeningNowClasses(): void {
    this.liveClassModel
      .getHappeningNowClasses()
      .pipe(
        map((classes: ScheduledLiveClassSeniorDetailsResponseDTO[]) => {
          const groupedItems: GroupedByDate<ScheduledLiveClassSeniorDetailsResponseDTO>[] = [];
          classes.forEach((item: ScheduledLiveClassSeniorDetailsResponseDTO) => {
            const date: string = DateUtil.createDateString(item.startDate);
            const group: GroupedByDate<ScheduledLiveClassSeniorDetailsResponseDTO> = groupedItems.find(
              (groupedItem: GroupedByDate<ScheduledLiveClassSeniorDetailsResponseDTO>) => {
                return groupedItem.date === date;
              });

            if (!group) {
              groupedItems.push({ date: date, items: [ item ] });
            }
            else {
              group.items.push(item);
            }
          });
          return groupedItems;
        })
      )
      .subscribe((response: GroupedByDate<ScheduledLiveClassSeniorDetailsResponseDTO>[]) => {
        this.happeningNow = response;
      });
  }
}

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { Transition } from '@uirouter/angular';
import { UserModel } from 'app/model/UserModel';
import { LiveClassZoomDTO } from 'app/data/dto/scheduledLiveClass/LiveClassZoomDTO';
import { LiveClassZoomMeetingService } from 'app/service/LiveClassZoomMeetingService';

@Component({
  selector: 'app-live-classes-user-zoom',
  templateUrl: './LiveClassesUserZoomComponent.html',
  styleUrls: [ './LiveClassesUserZoomComponent.scss' ]
})
export class LiveClassesUserZoomComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;
  public title: string;

  public liveClassZoom: LiveClassZoomDTO;

  constructor(private portalUtil: PortalUtil,
              private transition: Transition,
              public userModel: UserModel,
              public liveClassZoomService: LiveClassZoomMeetingService,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.liveClassZoomService.getLiveClassZoomConfiguration(this.transition.params().id)
      .subscribe((liveClassZoom: LiveClassZoomDTO) => {
        this.title = liveClassZoom.liveClassName || 'Unknown';
        this.liveClassZoom = liveClassZoom;
      });
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public onBackClick(): void {
    history.back();
  }
}
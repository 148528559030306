import { Injectable, Injector } from '@angular/core';
import { PageDTO } from 'app/data/dto/PageDTO';
import { CoachProfileCreateAdminRequestDTO } from 'app/data/dto/coach/CoachProfileCreateAdminRequestDTO';
import { CoachProfileResponseAdminDTO } from 'app/data/dto/coach/CoachProfileResponseAdminDTO';
import { CoachProfileResponseDTO } from 'app/data/dto/coach/CoachProfileResponseDTO';
import { CoachProfileUpdateAdminRequestDTO } from 'app/data/dto/coach/CoachProfileUpdateAdminRequestDTO';
import { CoachProfileUpdateRequestDTO } from 'app/data/dto/coach/CoachProfileUpdateRequestDTO';
import { MyScheduleCriteriaDTO } from 'app/data/dto/schedule/MyScheduleCriteriaDTO';
import { ScheduledLiveClassCoachPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachPageCriteriaDTO';
import { ScheduledLiveClassDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsResponseDTO';
import { ScheduledLiveClassCoachDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachDetailsResponseDTO';
import { CoachListDTO } from 'app/data/dto/scheduledLiveClass/coach/CoachListDTO';
import { ScheduledLiveClassCoachDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachDetailsPageDTO';
import { CoachService } from 'app/service/CoachService';
import { ScheduledLiveClassService } from 'app/service/ScheduledLiveClassService';
import { Observable } from 'rxjs';
import { CoachRecentRatingsResponseDTO } from 'app/data/dto/coach/CoachRecentRatingsResponseDTO';
import { ProgramCoachPageCriteriaDTO } from 'app/data/dto/programs/ProgramCoachPageCriteriaDTO';
import { ProgramCoachDetailsPageDTO } from 'app/data/dto/programs/ProgramCoachDetailsPageDTO';
import { ProgramService } from 'app/service/ProgramService';

@Injectable({ providedIn: 'root' })
export class CoachModel {
  private coachService: CoachService;
  private programService: ProgramService;
  private scheduledLiveClassService: ScheduledLiveClassService;

  constructor(private injector: Injector) {
    this.setupService();
  }

  private setupService(): void {
    this.coachService = this.injector.get(CoachService);
    this.programService = this.injector.get(ProgramService);
    this.scheduledLiveClassService = this.injector.get(ScheduledLiveClassService);
  }

  public getCoachSelectList(): Observable<CoachListDTO[]> {
    return this.coachService.getActiveCoachesList();
  }

  public getScheduledLiveClassesPage(
    criteria: ScheduledLiveClassCoachPageCriteriaDTO
  ): Observable<PageDTO<ScheduledLiveClassCoachDetailsPageDTO>> {
    return this.scheduledLiveClassService.getScheduledLiveClassesPageCoach(criteria);
  }

  public getCoach(id: number): Observable<CoachProfileResponseAdminDTO> {
    return this.coachService.getCoach(id);
  }

  public updateCoach(id: number, coach: CoachProfileUpdateAdminRequestDTO): Observable<CoachProfileResponseAdminDTO> {
    return this.coachService.updateCoach(id, coach);
  }

  public deleteCoach(id: number[]): Observable<void> {
    return this.coachService.deleteCoach(id);
  }

  public createCoach(coach: CoachProfileCreateAdminRequestDTO): Observable<CoachProfileResponseAdminDTO> {
    return this.coachService.createCoach(coach);
  }

  public getScheduledLiveClassDetails(id: number): Observable<ScheduledLiveClassCoachDetailsResponseDTO> {
    return this.scheduledLiveClassService.getScheduledLiveClassDetailsCoach(id);
  }

  public getMeSchedule(criteria: MyScheduleCriteriaDTO): Observable<ScheduledLiveClassDetailsResponseDTO[]> {
    return this.coachService.getMeSchedule(criteria);
  }

  public joinScheduledLiveClasses(id: number): Observable<void> {
    return this.scheduledLiveClassService.joinScheduledLiveClassesCoach(id);
  }

  public finishScheduledLiveClasses(id: number): Observable<void> {
    return this.scheduledLiveClassService.finishScheduledLiveClassesCoach(id);
  }

  public getCurrentUserProfile(): Observable<CoachProfileResponseDTO> {
    return this.coachService.getCurrentUserProfile();
  }

  public updateCurrentUserProfile(user: CoachProfileUpdateRequestDTO): Observable<CoachProfileResponseDTO> {
    return this.coachService.updateCurrentUserProfile(user);
  }

  public getCurrentCoachRecentRatings(count: number): Observable<CoachRecentRatingsResponseDTO> {
    return this.coachService.getCurrentCoachRecentRatings(count);
  }

  public getProgramPage(criteria: ProgramCoachPageCriteriaDTO): Observable<PageDTO<ProgramCoachDetailsPageDTO>> {
    return this.programService.getProgramPageCoach(criteria);
  }
}

import { Component, OnDestroy } from '@angular/core';
import { UserModel } from 'app/model/UserModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ScheduledLiveClassJoinComponentInput } from './JoinUserInterface';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { CoachModel } from 'app/model/CoachModel';

@Component({
  selector: 'app-join-user',
  templateUrl: './JoinUserComponent.html',
  styleUrls: [ './JoinUserComponent.scss' ]
})
export class JoinUserComponent implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  public static DROP: string = 'drop';
  public static JOIN: string = 'join';

  public scheduledLiveClass: ScheduledLiveClassJoinComponentInput;
  public coachMode: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private userModel: UserModel,
    private coachModel: CoachModel,
    public viewUtil: ViewUtil,
    public stateUtil: StateUtil,
    private applicationModel: ApplicationModel
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public shouldShowJoinButton(): boolean {
    return (
      this.scheduledLiveClass.currentSeniorEnrollment &&
      !this.scheduledLiveClass.currentSeniorEnrollment.attended &&
      !this.scheduledLiveClass.currentSeniorEnrollment.dropped
    );
  }

  public disableJoinButton(): boolean {
    const now = new Date();
    const minStartDate = new Date(this.scheduledLiveClass.startDate);
    const endDate = new Date(this.scheduledLiveClass.startDate);
    minStartDate.setMinutes(minStartDate.getMinutes() - 15);
    endDate.setMinutes(endDate.getMinutes() + this.scheduledLiveClass.duration);

    return now < minStartDate || now > endDate;
  }

  public onCloseClick(): void {
    this.bsModalRef.hide();
  }

  public onJoinClick(): void {
    let windowReference;

    if (!this.coachMode) {
      windowReference = window.open('about:blank', '_blank');

      this.userModel
        .joinScheduledLiveClasses(this.scheduledLiveClass.id, this.applicationModel.platform)
        .pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.modalService.setDismissReason(JoinUserComponent.JOIN);
            this.viewUtil.showToastSuccess('COMMON.SUCCESS');
            const urlPromise = Promise.resolve(this.scheduledLiveClass.url);
            urlPromise.then((url) => {
              if (!url.startsWith('https://') && url.startsWith('www.')) {
                url = 'https://' + url;
              }
              windowReference.location.href = url;
            });

            this.bsModalRef.hide();
          }),
          catchError((err) => {
            windowReference.close();
            this.viewUtil.handleServerError(err);
            return of(null);
          })
        )
        .subscribe();
    }
    else {
      const urlPromise = Promise.resolve(this.scheduledLiveClass.url);
      this.stateUtil.goToUrl(urlPromise, true);
    }
  }

  public onDropClick(): void {
    this.userModel
      .dropFromLiveClass(this.scheduledLiveClass)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.modalService.setDismissReason(JoinUserComponent.DROP);
          this.bsModalRef.hide();
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }
}
import { Ng2StateDeclaration } from '@uirouter/angular';
import { ParamDeclaration } from '@uirouter/core/lib/params/interface';

export class ApplicationState {

  public state: Ng2StateDeclaration;
  public params?: { [key: string]: ParamDeclaration | any };

  constructor(state: Ng2StateDeclaration, params: { [key: string]: ParamDeclaration | any } = null) {
    this.state = state;
    this.params = params;
  }

}

import { formatDate } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { NotificationDTO } from 'app/data/dto/notifications/NotificationDTO';
import { NotificationPriority } from 'app/data/enum/notifications/NotificationPriority';
import { UserModel } from 'app/model/UserModel';
import { NotificationService } from 'app/service/NotificationService';
import { ViewUtil } from 'app/util/ViewUtil';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { NotificationType } from 'app/data/dto/notifications/NotificationType';
import { State } from 'app/common/State';
import { LiveClassModel } from 'app/model/LiveClassModel';
import { StateUtil } from 'app/util/StateUtil';
import { WatchPartyModel } from 'app/model/WatchPartyModel';
import { NotificationScheduledLiveClassDTO } from 'app/data/dto/notifications/NotificationScheduledLiveClassDTO';

@Component({
  selector: 'app-notifications',
  templateUrl: 'NotificationsComponent.html',
  styleUrls: [ 'NotificationsComponent.scss' ]
})
export class NotificationsComponent {
  @Output()
  public closeClicked: EventEmitter<any> = new EventEmitter();

  public notifications$: Observable<NotificationDTO[]> = this.notificationService.notifications$;

  public Priority: typeof NotificationPriority = NotificationPriority;
  public State: typeof State = State;
  public NotificationType: typeof NotificationType = NotificationType;

  public notificationTypeWithDedicatedActions: NotificationType[] = [
    NotificationType.WATCH_PARTY_INVITATION,
    NotificationType.NEW_FRIEND_INVITATION
  ];

  constructor(private readonly notificationService: NotificationService,
              public readonly liveClassModel: LiveClassModel,
              private readonly userModel: UserModel,
              public readonly viewUtil: ViewUtil,
              private readonly stateUtil: StateUtil,
              private readonly watchPartyModel: WatchPartyModel,
              private readonly applicationModel: ApplicationModel) {
  }

  public close(): void {
    this.closeClicked.emit(null);
  }

  public formatContent(content: string): string {
    //replace newlines with <br> tags
    let formattedContent = content.replace(/\n/g, '<br>');

    // Regex to match the ISO 8601 date format
    const regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/;
    const match = regex.exec(formattedContent);

    if (match) {
      const dateString = match[0];
      const date = new Date(dateString);

      // Format the date (example format: Jan 19 11:45 AM)
      const formattedDate = formatDate(date, 'MMM dd hh:mm a', 'en-US');

      // Replace the original date string with the formatted date
      formattedContent = formattedContent.replace(dateString, formattedDate);
    }

    return formattedContent;
  }

  public isClassOngoing(notification: NotificationDTO): boolean {
    if (notification.scheduledLiveClass == null) {
      return false;
    }
    const classStartTime = new Date(notification.scheduledLiveClass.startDate);
    const currentTime = new Date();
    const durationInMilliseconds = notification.scheduledLiveClass.duration * 60000; // Convert minutes to milliseconds

    return classStartTime.getTime() + durationInMilliseconds > currentTime.getTime();
  }

  public joinLiveClasses(liveClass: NotificationScheduledLiveClassDTO): void {
    let windowReference: Window = window.open('about:blank', '_blank');

    this.userModel
      .joinScheduledLiveClasses(liveClass.id, this.applicationModel.platform)
      .pipe(
        tap(() => {
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
          const urlPromise: Promise<string> = Promise.resolve(liveClass.url);
          urlPromise.then((url: string) => {
            if (!url.startsWith('https://') && url.startsWith('www.')) {
              url = 'https://' + url;
            }
            windowReference.location.href = url;
          });
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public markAsRead(id: number): void {
    this.userModel
      .markNotificationsAsRead(id)
      .pipe(
        switchMap(() => this.userModel.getNotification()),
        tap((notifications) => {
          this.notificationService.notifications = notifications;
        }),
        catchError((err) => {
          this.viewUtil.handleServerError(err);
          return of(null);
        })
      )
      .subscribe();
  }

  public acceptWatchPartyInvitation(notification: NotificationDTO): void {
    this.watchPartyModel.accept(notification.watchPartyId, false).subscribe(
      () => {
        this.markAsRead(notification.id);
        this.stateUtil.goToState(State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.LIST);
      },
      (error) => this.viewUtil.handleServerError(error)
    );
  }

  public rejectWatchPartyInvitation(notification: NotificationDTO): void {
    this.watchPartyModel.reject(notification.watchPartyId, false).subscribe(
      () => {
        this.markAsRead(notification.id);
        this.stateUtil.goToState(State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.LIST);
      },
      (error) => this.viewUtil.handleServerError(error)
    );
  }

  public goToFriendInvitations(notification: NotificationDTO): void {
    this.markAsRead(notification.id);
    this.close();
    this.stateUtil.goToState(State.MAIN.SENIOR.FRIEND.INVITATIONS);
  }

  private refreshNotificationData(): void {
    this.userModel.getNotification()
      .subscribe((notifications: NotificationDTO[]) => {
        this.notificationService.notifications = notifications;
      });
  }
}

import * as _ from 'lodash';
import {Pipe, PipeTransform} from '@angular/core';
import {SubscriptionTier} from 'app/data/enum/SubscriptionTier';

@Pipe({name: 'tiers'})
export class TierPipe implements PipeTransform {
    public transform(value: SubscriptionTier, expectedTier: SubscriptionTier[]): boolean {
        return value && expectedTier.length > 0 ? _.includes(expectedTier, value) : true;
    }

}

import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import momentDurationPluginInit from 'moment-duration-format';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'durationFormat' })
export class DurationFormatPipe implements PipeTransform {

  constructor() {
    momentDurationPluginInit(moment);
  }

  public transform(value: number, unit: string = 'seconds', format: string = 'hh:mm:ss', pluralize: boolean = true, trim: boolean = false): any {
    if (value) {
      // moment plugin initialization is not compatible with TS (the plugin function above dynamically attaches itself to moment),
      // it would normally complain, but @ts-ignore turns off TS/linter checking for the following line

      // @ts-ignore: TS2339: Property 'format' does not exist on type 'Duration'
      return moment.duration(value, unit).format(format, { usePlural: pluralize, trim: trim });
    }
    else {
      return '-';
    }
  }
}

import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'suffix' })
export class SuffixPipe implements PipeTransform {

  public transform(value: string, suffix: string): any {
    return value + suffix;
  }

}

import { Transform } from 'class-transformer';
import { DateUtil } from 'app/util/DateUtil';

export class WatchPartyEventDTO {
  @Transform((params) => DateUtil.dateTimeConversion(params, true))
  public clientTimestamp: Date;

  public vimeoTimestamp: number;

  public status: WatchPartyEventStatus;
}

export enum WatchPartyEventStatus {
  PLAY = 'PLAY',
  STOP = 'STOP'
}
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { WatchPartyCreateDTO } from 'app/data/dto/watchParty/WatchPartyCreateDTO';
import { WatchPartyService } from 'app/service/WatchPartyService';
import { WatchPartyUpdateDTO } from 'app/data/dto/watchParty/WatchPartyUpdateDTO';
import { switchMap, tap } from 'rxjs/operators';
import { ViewUtil } from 'app/util/ViewUtil';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { WatchPartyPageCriteriaDTO } from 'app/data/dto/watchParty/WatchPartyPageCriteriaDTO';
import { WatchPartyLogic } from 'app/component/domain/watchparty/service/WatchPartyLogic';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserModel } from 'app/model/UserModel';
import { WatchPartyEventDTO } from 'app/data/dto/watchParty/WatchPartyEventDTO';

@Injectable({
  providedIn: 'root'
})
export class WatchPartyModel {
  constructor(public readonly logic: WatchPartyLogic,
              private readonly injector: Injector,
              private readonly userModel: UserModel,
              private readonly watchPartyService: WatchPartyService,
              private readonly viewUtil: ViewUtil) {
  }

  public getById(id: number): Observable<WatchPartyDTO> {
    return this.watchPartyService.getById(id);
  }

  public getPage(criteria: WatchPartyPageCriteriaDTO): Observable<PageDTO<WatchPartyDTO>> {
    return this.watchPartyService.getPage(criteria);
  }

  public create(data: WatchPartyCreateDTO): Observable<void> {
    return this.watchPartyService.create(data)
      .pipe(
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.CREATE.SUCCESS');
          this.userModel.getCurrentUser();
        })
      );
  }

  public update(id: number, data: WatchPartyUpdateDTO): Observable<void> {
    return this.watchPartyService.update(id, data)
      .pipe(
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.UPDATE.SUCCESS');
        })
      );
  }

  public cancel(id: number): Observable<void> {
    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.WATCH_PARTY.MESSAGE.DELETE.TITLE',
        message: 'DOMAIN.WATCH_PARTY.MESSAGE.DELETE.DESCRIPTION',
        okText: 'COMMON.CONFIRM'
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.watchPartyService.delete(id)),
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.DELETE.SUCCESS');
          this.userModel.getCurrentUser();
        })
      );
  }

  public end(id: number): Observable<void> {
    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.WATCH_PARTY.MESSAGE.END.TITLE',
        message: 'DOMAIN.WATCH_PARTY.MESSAGE.END.DESCRIPTION',
        okText: 'COMMON.CONFIRM'
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.watchPartyService.end(id)),
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.END.SUCCESS');
          this.userModel.getCurrentUser();
        })
      );
  }

  public drop(id: number): Observable<void> {
    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.WATCH_PARTY.MESSAGE.DROP.TITLE',
        message: 'DOMAIN.WATCH_PARTY.MESSAGE.DROP.DESCRIPTION',
        okText: 'COMMON.CONFIRM'
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.watchPartyService.drop(id)),
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.DROP.SUCCESS');
          this.userModel.getCurrentUser();
        })
      );
  }

  public signup(id: number): Observable<void> {
    return this.injector.get(BsModalService).show(PopupConfirmationComponent, {
      initialState: {
        title: 'DOMAIN.WATCH_PARTY.MESSAGE.SIGN_UP.TITLE',
        message: 'DOMAIN.WATCH_PARTY.MESSAGE.SIGN_UP.DESCRIPTION',
        okText: 'COMMON.CONFIRM'
      },
      class: 'modal-dialog-centered'
    }).content.close$
      .pipe(
        switchMap(() => this.watchPartyService.signup(id)),
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.SIGN_UP.SUCCESS');
          this.userModel.getCurrentUser();
        })
      );
  }

  public accept(id: number, showConfirmation: boolean = true): Observable<void> {
    let observable: Observable<void>;

    if (showConfirmation) {
      observable = this.injector.get(BsModalService).show(PopupConfirmationComponent, {
        initialState: {
          title: 'DOMAIN.WATCH_PARTY.MESSAGE.ACCEPT.TITLE',
          message: 'DOMAIN.WATCH_PARTY.MESSAGE.ACCEPT.DESCRIPTION',
          okText: 'COMMON.CONFIRM'
        },
        class: 'modal-dialog-centered'
      }).content.close$
        .pipe(
          switchMap(() => this.watchPartyService.accept(id))
        );
    }
    else {
      observable = this.watchPartyService.accept(id);
    }

    return observable
      .pipe(
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.ACCEPT.SUCCESS');
          void this.userModel.getCurrentUser();
        })
      );
  }

  public reject(id: number, showConfirmation: boolean = true): Observable<void> {
    let observable: Observable<void>;

    if (showConfirmation) {
      observable = this.injector.get(BsModalService).show(PopupConfirmationComponent, {
        initialState: {
          title: 'DOMAIN.WATCH_PARTY.MESSAGE.REJECT.TITLE',
          message: 'DOMAIN.WATCH_PARTY.MESSAGE.REJECT.DESCRIPTION',
          okText: 'COMMON.CONFIRM'
        },
        class: 'modal-dialog-centered'
      }).content.close$
        .pipe(
          switchMap(() => this.watchPartyService.reject(id))
        );
    }
    else {
      observable = this.watchPartyService.reject(id);
    }

    return observable
      .pipe(
        tap(() => {
          this.viewUtil.showToastSuccess('DOMAIN.WATCH_PARTY.MESSAGE.REJECT.SUCCESS');
          void this.userModel.getCurrentUser();
        })
      );
  }

  public connect(id: number): Observable<WatchPartyEventDTO> {
    return this.watchPartyService.connect(id);
  }

  public getLastEvent(id: number): Observable<WatchPartyEventDTO> {
    return this.watchPartyService.getLastEvent(id);
  }

  public sendEvent(id: number, event: WatchPartyEventDTO): Observable<void> {
    return this.watchPartyService.sendEvent(id, event);
  }
}
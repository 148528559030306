import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EventManager } from 'app/util/other/EventManager';
import { Event } from 'app/common/Event';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './DashboardAdminComponent.html',
  styleUrls: [ './DashboardAdminComponent.scss' ]
})
export class DashboardAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public statisticsUrl: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer,
              private eventManager: EventManager,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.statisticsUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(ApplicationConfig.statisticsUrl);
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
  }

  public ngAfterViewInit(): void {
    // simulate iframe loading
    setTimeout(() => this.eventManager.broadcast(Event.SYSTEM.LOADING, true), 400);
    setTimeout(() => this.eventManager.broadcast(Event.SYSTEM.LOADING, false), 2000);
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }
}
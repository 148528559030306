import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'sanitizeStyle' })
export class SanitizeStylePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  public transform(value: any): any {
    return this.sanitizer.bypassSecurityTrustStyle(value);
  }

}

<ng-template #headingTemplate>
  <h1>{{ title | translate }}</h1>

  <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
    <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
    <span>{{ 'COMMON.BACK' | translate }}</span>
  </button>

</ng-template>

<ng-container *ngIf="liveClassZoom$ | async as liveClassZoom">
  <section class="d-flex justify-content-center align-items-center section" *ngIf="activeQuestion">
    <div class="question w-50">
      <h2>{{ 'VIEW.MAIN.LIVE_CLASSES.HOW_TO_JOIN_TO_THE_MEETING.HEADER' | translate }}</h2>
      <p>{{ 'VIEW.MAIN.LIVE_CLASSES.HOW_TO_JOIN_TO_THE_MEETING.DESCRIPTION' | translate }}</p>
      <div class="d-flex gap-4 justify-content-center">
        <button type="button"
                class="btn btn-outline-primary"
                [disabled]="!liveClassZoom.url"
                (click)="openOnDesktop(liveClassZoom.url)">{{ 'VIEW.MAIN.LIVE_CLASSES.HOW_TO_JOIN_TO_THE_MEETING.ACTION.DESKTOP' | translate }}
        </button>
        <button type="button"
                class="btn btn-primary"
                (click)="activeQuestion = false">{{ 'VIEW.MAIN.LIVE_CLASSES.HOW_TO_JOIN_TO_THE_MEETING.ACTION.BROWSER' | translate }}
        </button>
      </div>
    </div>
  </section>

  <section class="section" *ngIf="!activeQuestion">
    <div class="zoom-container">
      <app-zoom-meeting [config]="liveClassZoom"
                        [username]="userModel.currentUser.fullName"
                        (meetingEnd)="onBackClick()"></app-zoom-meeting>
    </div>
  </section>
</ng-container>
import { DateUtil } from 'app/util/DateUtil';
import { Exclude, Transform, Type } from 'class-transformer';
import { ImageResponseDTO } from '../images/ImageResponseDTO';
import _ from 'lodash';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';

export class CoachUserDetailsProfileResponseAdminDTO {
  @Type(() => ImageResponseDTO)
  avatar?: ImageResponseDTO;

  email: string;

  enabled: boolean;

  firstName: string;

  lastName: string;

  phone: string;

  public registrationType: RegistrationType;

  @Transform(DateUtil.dateTimeConversion)
  createdDate: Date;

  @Exclude()
  public get fullName(): string {
    return _.trim(`${ this.firstName ?? '' } ${ this.lastName ?? '' }`);
  }
}

import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'console' })
export class ConsolePipe implements PipeTransform {

  public transform(value: any, passThrough: boolean = true): any {
    console.log(value);

    if (passThrough) {
      return value;
    }
    else {
      return null;
    }
  }

}

import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject, Input,
  OnDestroy, OnInit,
  Output,
  ViewChild, ViewEncapsulation
} from '@angular/core';
import { Transition } from '@uirouter/angular';

import uitoolkit from "@zoom/videosdk-ui-toolkit";
import { ZoomConfigDTO } from 'app/component/ui/zoom/data/ZoomConfigDTO';
import { IZoomServiceInterface, ZOOM_SERVICE_TOKEN } from 'app/component/ui/zoom/IZoomServiceInterface';
import { ViewUtil } from 'app/util/ViewUtil';
import { UserDTO } from 'app/data/dto/user/UserDTO';

@Component({
  selector: 'app-zoom-complete-view',
  templateUrl: './zoom-complete-view.component.html',
  styleUrls: ['./zoom-complete-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZoomCompleteViewComponent implements OnInit, OnDestroy {
  @ViewChild('sessionContainer')
  public sessionContainer: ElementRef;

  public canShowJoinButton: boolean = true;

  public inSession: boolean = false;

  private config: ZoomConfigDTO;

  @Input()
  public autoInit: boolean = false;

  @Input()
  public hideEndButton: boolean = false;

  @Input()
  public features: Array<'audio' | 'chat' | 'preview' | 'settings' | 'share' | 'users' | 'video'>;

  @Input()
  public user: UserDTO;

  @Output()
  public sessionJoined: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(@Inject(ZOOM_SERVICE_TOKEN) private zoomService: IZoomServiceInterface,
              private readonly transition: Transition,
              private readonly viewUtil: ViewUtil,
              @Inject(DOCUMENT) document: any) {
    this.features = [ 'audio', 'preview', 'settings', 'share', 'users', 'video' ];
  }

  public ngOnInit(): void {
    if (this.autoInit) {
      this.canShowJoinButton = false;
      this.getVideoSDKJWT();
    }
  }

  public ngOnDestroy(): void {
    this.sessionClosed();
  }

  public getVideoSDKJWT(): void {
    const meetingId = this.transition.params().id;
    this.zoomService.getRoomData(meetingId).then((data) => {
      this.config = {
        videoSDKJWT: data.jwt,
        sessionName: data.sessionName,
        userName: this.user?.fullName || data.userIdentity,
        features: this.features,
        options: {
          init: {
            enforceMultipleVideos: true,
            enforceVirtualBackground: true // that's strange but without it, you have to manually toggle the video from webcam
          },
          audio: {},
          video: {},
          share: {
            hideShareAudioOption: false
          },
        },
        virtualBackground: {
          allowVirtualBackground: true,
          allowVirtualBackgroundUpload: true,
          virtualBackgrounds: [ 'https://images.unsplash.com/photo-1715490187538-30a365fa05bd?q=80&w=1945&auto=format&fit=crop' ]
        }
      };

      this.joinSession();
    })
      .catch((error) => {
        this.viewUtil.handleServerError(error);
      });
  }

  public joinSession(): void {
    uitoolkit.joinSession(this.sessionContainer.nativeElement, this.config);

    this.canShowJoinButton = false;

    uitoolkit.onSessionJoined(() => {
      this.inSession = true;
      this.sessionJoined.emit(this.inSession);
    });

    uitoolkit.onSessionClosed(this.sessionClosed);
  }

  public sessionClosed = (() => {
    uitoolkit.closeSession(this.sessionContainer.nativeElement);
    this.inSession = false;
    this.canShowJoinButton = true;
    this.sessionJoined.emit(this.inSession);
  });
}
<ng-template #headingTemplate>
  <h1 *ngIf="video" class="mb-0">{{ video.title }}</h1>

  <button class="btn btn-outline-primary w-100 w-sm-auto" (click)="goBack()">
    {{ 'COMMON.BACK' | translate }}
  </button>
</ng-template>

<section class="section space-y-4">
  <figure id="vimeo" class="mb-0"></figure>
  <app-rating-form *ngIf="video?.ratingEnabled" class="d-block" (rate)="rateVideo($event)"></app-rating-form>
</section>

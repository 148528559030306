import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[appInputToInteger]' })
export class InputToIntegerDirective {

  constructor(private el: ElementRef, private ngControl: NgControl) {
  }

  @HostListener('blur')
  public onInputBlur(): void {
    const value: any = this.el.nativeElement.value;
    let parsedValue: number = parseInt(value, 10);

    if (isNaN(parsedValue)) {
      parsedValue = null;
    }

    this.ngControl.control.setValue(parsedValue);
  }

}

import { BaseUserDTO } from 'app/data/dto/user/BaseUserDTO';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';

export class UserProfileDTO extends BaseUserDTO {
  public nickname: string;

  public email: string;

  public phone: string;

  public registrationType: RegistrationType;
}

<ng-template #headingTemplate>
  <h1>{{ currentSideBarItem?.label | translate }}</h1>
</ng-template>

<!-- View for unauthorized users -->
<ng-container *ngIf="viewAccess === ViewAccess.UNAUTHORIZED">
  <section class="section d-flex flex-column justify-content-center">
    <div class="flex-grow-0 text-center">
      <figure>
        <img class="img-thumbnail rounded-circle" src="asset/image/guest_placeholder.jpg" alt="">
      </figure>

      <h2 class="h1">{{ 'COMPONENT.CUSTOM_UI_VIEW.UNAUTHORIZED.TITLE' | translate }}</h2>
      <h3 class="h2">{{ 'COMPONENT.CUSTOM_UI_VIEW.UNAUTHORIZED.SUBTITLE' | translate }}</h3>
      <p class="font-size-lg mb-0">{{ 'COMPONENT.CUSTOM_UI_VIEW.UNAUTHORIZED.DESCRIPTION' | translate }}</p>

      <div class="d-flex justify-content-center gap-4 mt-4">
        <button type="button" class="btn btn-light" (click)="goToRegistration()">
          {{ 'COMMON.REGISTER' | translate }}
        </button>

        <button type="button" class="btn btn-primary" (click)="goToLogin()">
          {{ 'COMPONENT.CUSTOM_UI_VIEW.UNAUTHORIZED.LOGIN' | translate }}
        </button>
      </div>
    </div>
  </section>
</ng-container>

<!--Limited access -->
<ng-container *ngIf="viewAccess === ViewAccess.LIMITED">
  <section class="section d-flex flex-column justify-content-center">
    <div class="flex-grow-0 text-center">
      <figure>
        <img class="img-thumbnail rounded-circle" src="asset/image/guest_placeholder.jpg" alt="">
      </figure>

      <ng-container *ngIf="subscriptionModel.subscriptionExpired">
        <h2>{{ 'COMPONENT.CUSTOM_UI_VIEW.LIMITED.SUBSCRIPTION_EXPIRED.TITLE' | translate }}</h2>
      </ng-container>

      <ng-container *ngIf="!subscriptionModel.subscriptionPlanSelected">
        <h2>{{ 'COMPONENT.CUSTOM_UI_VIEW.LIMITED.NOT_PLAN_CHOSEN.TITLE' | translate }}</h2>
      </ng-container>

      <div class="d-flex justify-content-center gap-4 mt-4">
        <button type="button" class="btn btn-primary" (click)="goToAccountDetails()">
          {{ 'COMPONENT.CUSTOM_UI_VIEW.MANAGE_SUBSCRIPTION' | translate }}
        </button>
      </div>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="viewAccess === ViewAccess.LIMITED_BY_TIER_LEVEL">
  <section class="section d-flex flex-column justify-content-center align-items-center">
    <div class="flex-grow-0 text-center w-50">
      <figure>
        <img class="img-thumbnail rounded-circle" src="asset/image/guest_placeholder.jpg" alt="">
      </figure>

      <h2>{{ 'COMPONENT.CUSTOM_UI_VIEW.LIMITED_BY_TIER_LEVEL.TITLE' | translate }}</h2>
      <p>{{ 'COMPONENT.CUSTOM_UI_VIEW.LIMITED_BY_TIER_LEVEL.DESCRIPTION' | translate }}</p>
    </div>
  </section>
</ng-container>

<!--Full access-->
<ng-container *ngIf="viewAccess === ViewAccess.FULL">
  <ui-view class="custom-ui-view"></ui-view>
</ng-container>

import { WatchPartyType } from 'app/data/dto/watchParty/WatchPartyType';
import { BaseVideoDTO } from 'app/data/dto/onDemandVideo/BaseVideoDTO';
import { DateUtil } from 'app/util/DateUtil';
import { Expose, Transform, Type } from 'class-transformer';
import { TransformFnParams } from 'class-transformer/types/interfaces';
import { BaseUserDTO } from 'app/data/dto/user/BaseUserDTO';


export class WatchPartyCreateDTO {
  public type: WatchPartyType;

  @Transform((params: TransformFnParams) => params.value?.id)
  @Expose({ name: 'video', toPlainOnly: true })
  public videoId: BaseVideoDTO;

  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  @Type(() => BaseUserDTO)
  @Transform((params: TransformFnParams) => params.value?.map(s => s.id))
  @Expose({ name: 'participants', toPlainOnly: true })
  public participantIds: BaseUserDTO[];
}
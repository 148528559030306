import { LocalMessageType } from 'app/data/local/LocalMessageType';

export class LocalMessage {

  public title: string;

  public message: string;

  public details: string;

  public type: LocalMessageType;

  constructor(type: LocalMessageType, message: string, title: string = null, details: string = null) {
    this.type = type;
    this.message = message;
    this.title = title;
    this.details = details;
  }

}

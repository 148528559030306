import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class TranslateMultipleLineParser extends TranslateDefaultParser {

  // eslint-disable-next-line @typescript-eslint/ban-types
  public interpolate(expr: string | Function, params?: any): string {
    if (!_.isFunction(expr) && _.isObject(expr)) {
      const results: string[] = [];

      // eslint-disable-next-line @typescript-eslint/ban-types
      _.forEach(expr, (value: string | Function) => {
        if (value instanceof Function) {
          results.push(value(params));
        }
        else {
          results.push(value);
        }
      });

      return results.join(' ');
    }
    else {
      return super.interpolate(expr, params);
    }
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService, Transition } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { OrganizationEssentialsDTO } from 'app/data/dto/registration/OrganizationEssentialsDTO';
import { SeniorRegistrationDTO } from 'app/data/dto/registration/SeniorRegistrationDTO';
import { SeniorValidationRequestDTO } from 'app/data/dto/registration/SeniorValdiationRequestDTO';
import { SeniorValidationResponseDTO } from 'app/data/dto/registration/SeniorValidationResponseDTO';
import { UserRegistrationDTO } from 'app/data/dto/user/UserRegistrationDTO';
import { UserRegistrationModel } from 'app/model/UserRegistrationModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { RegistrationUserNotFoundComponent } from '../RegistrationUserNotFoundComponent';
import { SubscriptionType } from 'app/data/enum/subscription/SubscriptionType';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';

@Component({
  selector: 'app-registration-branded-start',
  templateUrl: './RegistrationBrandedStartComponent.html',
  styleUrls: [ './RegistrationBrandedStartComponent.scss' ]
})
export class RegistrationBrandedStartComponent implements OnInit {
  public organizationEssentials: OrganizationEssentialsDTO;
  public userRegistration: SeniorRegistrationDTO;
  public Constant: typeof Constant = Constant;
  public currentStep: number = 1;
  public noOfSteps: number = 3;
  product: string | null;
  @ViewChild('registrationForm')
  private registrationForm: NgForm;

  constructor(
    private stateService: StateService,
    public viewUtil: ViewUtil,
    private userRegistrationModel: UserRegistrationModel,
    private modalService: BsModalService,
    private transition: Transition
  ) {
    if (this.transition.params().userRegistration) {
      this.userRegistration = this.transition.params().userRegistration;
    }
    else {
      this.userRegistration = new SeniorRegistrationDTO();
      this.userRegistration.subscriptionType = SubscriptionType.ORGANIZATION;
      this.userRegistration.user = new UserRegistrationDTO();
      this.userRegistration.user.registrationType = RegistrationType.EMAIL;
    }
  }

  public ngOnInit(): void {
    this.getOrganizationEssentials();
  }

  public validateUserAndGoToNextStep(): void {
    this.registrationForm.onSubmit(null);
    if (this.registrationForm.form.valid) {
      this.validateUser().subscribe(
        (res) => {
          this.userRegistration.user.firstName = res.firstName;
          this.userRegistration.user.lastName = res.lastName;
          this.goNext();
        },
        (err) => this.showUserNotFoundModal(err)
      );
    }
  }

  public backToLogin(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }

  private getOrganizationEssentials(): void {
    this.userRegistrationModel.getOrganizationsDetails(this.transition.params().product).subscribe(
      (res) => {
        this.organizationEssentials = res;
        this.userRegistration.organizationId = res.id;
      },
      (_err) => {
        //for now if user provides wrong organization stub he will be redirected to normal registration, check with team if that's the desired behavior
        this.stateService.go(State.PRELIMINARY.REGISTRATION.START);
      }
    );
  }

  private validateUser(): Observable<SeniorValidationResponseDTO> {
    const seniorValidationRequest: SeniorValidationRequestDTO = new SeniorValidationRequestDTO();
    seniorValidationRequest.email = this.userRegistration.user.email;
    seniorValidationRequest.organizationId = this.userRegistration.organizationId;
    seniorValidationRequest.memberCode = this.userRegistration.memberCode;
    seniorValidationRequest.registrationType = this.userRegistration.user.registrationType;
    seniorValidationRequest.subscriptionType = this.userRegistration.subscriptionType;
    return this.userRegistrationModel.validateSenior(seniorValidationRequest);
  }

  private goNext(): void {
    if (this.registrationForm.form.valid) {
      this.stateService.go(State.PRELIMINARY.REGISTRATION_BRANDED.USER_DATA, {
        userRegistration: this.userRegistration,
        currentStep: this.currentStep + 1,
        noOfSteps: this.noOfSteps
      });
    }
  }

  private showUserNotFoundModal(error: any) {
    this.modalService.show(RegistrationUserNotFoundComponent, {
      initialState: { error: error }
    });
  }
}

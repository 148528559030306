import { StorageConfig as NgxPwaStorageConfig } from '@ngx-pwa/local-storage';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import * as _ from 'lodash';

export const StorageConfig: NgxPwaStorageConfig = {
  IDBDBName: createSafeApplicationStorageName(),
  LSPrefix: createSafeApplicationStorageName()
};

function createSafeApplicationStorageName(): string {
  return _.replace(ApplicationConfig.applicationOwner, ' ', '_') +
    '_' +
    _.replace(ApplicationConfig.applicationName, ' ', '_') +
    '_' +
    'Storage';
}

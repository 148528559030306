<ng-template #headingTemplate>
  <h1>{{ 'COMPONENT.ORGANIZATIONS.DETAILS' | translate }}</h1>

  <div class="d-flex flex-wrap gap-4">
    <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button type="button" class="btn btn-primary" (click)="onEditClick()">
      {{ 'COMMON.EDIT' | translate }}
    </button>
  </div>
</ng-template>

<section class="section">
  <div *ngIf="organization$ | async as organization" class="d-flex gap-6 flex-column flex-lg-row align-items-start">
    <app-avatar class="align-self-center align-self-lg-auto"
                [src]="organization?.logo?.defaultAsset?.url"
                placeholder="asset/image/icon/building-skyscraper.svg"
                size="160px">
    </app-avatar>

    <div class="flex-grow-1 word-break">
      <div class="row d-flex no-gutters mb-4 justify-content-between align-items-center">
        <div>
          <div class="text-sm-light">{{ 'VIEW.MAIN.USER.NAME' | translate }}</div>
          <h2 class="m-0">{{ organization.name }}</h2>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="row no-gutters gap-4 mt-4">
        <div class="col d-flex flex-column">
          <div class="text-sm-light">{{ "COMPONENT.ORGANIZATIONS.TYPE" | translate }}</div>
          <ng-container [ngSwitch]="organization.type">
            <p *ngSwitchCase="OrganizationType.OFFICIAL">{{ "ENUM.STATUS.ACTIVE" | translate }}</p>
            <p *ngSwitchCase="OrganizationType.DEMO">{{ "ENUM.STATUS.INACTIVE" | translate }}</p>
          </ng-container>
        </div>
        <div class="col d-flex flex-column">
          <div class="text-sm-light">{{ "COMPONENT.ORGANIZATIONS.CONTACT_NAME" | translate }}</div>
          {{ organization?.contactName }}
        </div>
        <div class="col d-flex flex-column">
          <div class="text-sm-light">{{ "COMPONENT.ORGANIZATIONS.PHONE" | translate }}</div>
          <div>{{ organization?.phone }}</div>
        </div>
        <div class="col d-flex flex-column">
          <div class="text-sm-light">{{ "COMPONENT.ORGANIZATIONS.USER_COUNT" | translate }}</div>
          <div>{{ organization.usersCount }}</div>
        </div>
      </div>

      <div class="row no-gutters gap-4">
        <div class="col d-flex flex-column">
          <div class="text-sm-light">{{ "DOMAIN.ORGANIZATION.DATA.SUBSCRIPTION_TIER.LABEL" | translate }}</div>
          <div>{{ organization.subscriptionTier | prefix: 'ENUM.SUBSCRIPTION_TIER.' | translate }}</div>
        </div>
      </div>

      <div class="row no-gutters gap-4 mt-4">
        <div class="col d-flex flex-column">
          <div class="text-sm-light">{{ "COMPONENT.ORGANIZATIONS.ADDRESS" | translate }}</div>
          <div>
            <div class="m-0 p-0">{{ organization?.address?.street }} {{ organization?.address?.city }}</div>
            <div>
              {{ organization?.address?.state }} {{ organization?.address?.zipCode }} {{ organization?.address?.country }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import { ScheduledLiveClassSeniorDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsResponseDTO';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { UserModel } from 'app/model/UserModel';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { addMinutes, isFuture, isPast, subMinutes } from 'date-fns';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-live-classes-details-user',
  templateUrl: './LiveClassDetailsUserComponent.html',
  styleUrls: [ './LiveClassDetailsUserComponent.scss' ]
})
export class LiveClassDetailsUserComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public scheduledLiveClass: ScheduledLiveClassSeniorDetailsResponseDTO;
  public scheduledLiveClassId: number;

  private isBeforeStartDate: boolean = false;
  private isAfterEndDate: boolean = false;

  private isCapacityExceeded: boolean = false;

  private isCurrentSeniorEnrolled: boolean = false;
  private isCurrentSeniorDropped: boolean = false;
  public isCurrentSeniorOnWaitingList: boolean = false;

  public isRegisterButtonAvailable: boolean = false;
  public isRegisterButtonDisabled: boolean = false;

  public isJoinButtonAvailable: boolean = false;
  public isJoinButtonDisabled: boolean = false;

  public isDropButtonAvailable: boolean = false;

  public isWaitingListButtonAvailable: boolean = false;
  public isWaitingListButtonDisabled: boolean = false;

  public readonly Constant: typeof Constant = Constant;
  public readonly IntensityLevel: typeof IntensityLevel = IntensityLevel;

  constructor(private applicationModel: ApplicationModel,
              private transition: Transition,
              private userModel: UserModel,
              private stateService: StateService,
              private viewUtil: ViewUtil,
              public stateUtil: StateUtil,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef) {
    this.scheduledLiveClassId = this.transition.params().id;
  }

  public ngOnInit(): void {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.SENIOR.LIVE_CLASSES.LIST);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getScheduledLiveClass(this.scheduledLiveClassId);
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public handleRegisterClick(): void {
    this.userModel.registerToLiveClass(this.scheduledLiveClass)
      .subscribe(() => {
          this.getScheduledLiveClass(this.scheduledLiveClassId);
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        },
        (err) => {
          this.viewUtil.handleServerError(err);
        });
  }

  public handleJoinClick(): void {
    const urlPromise: Promise<string> = Promise.resolve(this.scheduledLiveClass.url);
    this.stateUtil.goToUrl(urlPromise, true);
  }

  public handleDropClick(): void {
    this.userModel.dropFromLiveClass(this.scheduledLiveClass)
      .subscribe(() => {
          this.getScheduledLiveClass(this.scheduledLiveClassId);
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        },
        (err) => {
          this.viewUtil.handleServerError(err);
        });
  }

  public handleJoinWaitingListClick(): void {
    this.userModel.joinToScheduledLiveClassWaitingList(this.scheduledLiveClassId, this.applicationModel.platform)
      .subscribe(() => {
          this.getScheduledLiveClass(this.scheduledLiveClassId);
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        },
        (err) => {
          this.viewUtil.handleServerError(err);
        });
  }

  public handleDropWaitingListClick(): void {
    this.userModel.dropFromScheduledLiveClassWaitingList(this.scheduledLiveClassId)
      .subscribe(() => {
          this.getScheduledLiveClass(this.scheduledLiveClassId);
          this.viewUtil.showToastSuccess('COMMON.SUCCESS');
        },
        (err) => {
          this.viewUtil.handleServerError(err);
        });
  }

  public onBackClick(): void {
    history.back();
  }

  private getScheduledLiveClass(id: number): void {
    this.userModel.getScheduledLiveClassDetails(id)
      .subscribe((scheduledLiveClass: ScheduledLiveClassSeniorDetailsResponseDTO) => {
          this.scheduledLiveClass = scheduledLiveClass;

          this.isBeforeStartDate = isFuture(subMinutes(scheduledLiveClass.startDate, Constant.DEFAULT_MINUTES_THRESHOLD));
          this.isAfterEndDate = isPast(addMinutes(scheduledLiveClass.startDate, scheduledLiveClass.duration));

          this.isCapacityExceeded = scheduledLiveClass.capacity && (scheduledLiveClass.enrolledSeniorsCount >= scheduledLiveClass.capacity);
          this.isCurrentSeniorEnrolled = !!scheduledLiveClass.currentSeniorEnrollment;
          this.isCurrentSeniorDropped = scheduledLiveClass.currentSeniorEnrollment?.dropped;
          this.isCurrentSeniorOnWaitingList = scheduledLiveClass.isCurrentSeniorOnWaitingList;

          this.isRegisterButtonAvailable = !this.isCapacityExceeded && (!this.isCurrentSeniorEnrolled || this.isCurrentSeniorDropped);
          this.isRegisterButtonDisabled = this.isAfterEndDate;
          this.isJoinButtonAvailable = this.isCurrentSeniorEnrolled && !this.isCurrentSeniorDropped;
          this.isJoinButtonDisabled = this.isBeforeStartDate || this.isAfterEndDate;
          this.isDropButtonAvailable = this.isCurrentSeniorOnWaitingList || (this.isCurrentSeniorEnrolled && !this.isCurrentSeniorDropped);
          this.isWaitingListButtonAvailable = !this.isCurrentSeniorOnWaitingList && (!this.isCurrentSeniorEnrolled || this.isCurrentSeniorDropped) && this.isCapacityExceeded;
          this.isWaitingListButtonDisabled = this.isAfterEndDate;
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        });
  }
}

import { Component } from '@angular/core';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { Layout } from 'app/common/Layout';
import { State } from 'app/common/State';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { AccountDetailsComponent } from 'app/component/view/main/account/AccountDetailsComponent';
import { CoachesListComponent } from 'app/component/view/main/coaches/CoachesListComponent';
import { CoachesCreateComponent } from 'app/component/view/main/coaches/create/CoachesCreateComponent';
import { CoachesDetailsComponent } from 'app/component/view/main/coaches/details/CoachesDetailsComponent';
import { CoachesEditComponent } from 'app/component/view/main/coaches/edit/CoachesEditComponent';
import { HelpComponent } from 'app/component/view/main/help/HelpComponent';
import { LiveClassSingleListAdminComponent } from 'app/component/view/main/liveClasses/admin/single/list/LiveClassSingleListAdminComponent';
import { LiveClassSingleCreateAdminComponent } from 'app/component/view/main/liveClasses/admin/single/create/LiveClassSingleCreateAdminComponent';
import { LiveClassSingleDetailsAdminComponent } from 'app/component/view/main/liveClasses/admin/single/details/LiveClassSingleDetailsAdminComponent';
import { LiveClassSingleEditAdminComponent } from 'app/component/view/main/liveClasses/admin/single/edit/LiveClassSingleEditAdminComponent';
import { LiveClassesListCoachComponent } from 'app/component/view/main/liveClasses/coach/LiveClassesListCoachComponent';
import { LiveClassDetailsCoachComponent } from 'app/component/view/main/liveClasses/coach/details/LiveClassDetailsCoachComponent';
import { LiveClassesListUserComponent } from 'app/component/view/main/liveClasses/user/LiveClassesListUserComponent';
import { LiveClassDetailsUserComponent } from 'app/component/view/main/liveClasses/user/details/LiveClassDetailsUserComponent';
import { MyActivityComponent } from 'app/component/view/main/myActivity/user/MyActivityComponent';
import { AllBadgesComponent } from 'app/component/view/main/myActivity/user/allBadges/AllBadgesComponent';
import { ProgramAdminNewComponent } from 'app/component/view/main/onDemand/admin/programs/create/ProgramAdminNewComponent';
import { ProgramAdminDetailsComponent } from 'app/component/view/main/onDemand/admin/programs/details/ProgramAdminDetailsComponent';
import { ProgramAdminEditComponent } from 'app/component/view/main/onDemand/admin/programs/edit/ProgramAdminEditComponent';
import { OnDemandVideosAdminDetailsComponent } from 'app/component/view/main/onDemand/admin/videos/details/OnDemandVideosAdminDetailsComponent';
import { OnDemandVideosAdminEditComponent } from 'app/component/view/main/onDemand/admin/videos/edit/OnDemandVideosAdminEditComponent';
import { OnDemandVideosAdminListComponent } from 'app/component/view/main/onDemand/admin/videos/list/OnDemandVideosAdminListComponent';
import { OnDemandVideosAdminNewComponent } from 'app/component/view/main/onDemand/admin/videos/new/onDemand/OnDemandVideosAdminNewComponent';
import { OnDemandCoachListComponent } from 'app/component/view/main/onDemand/coach/list/OnDemandCoachListComponent';
import { OnDemandVideosPlayVideoComponent } from 'app/component/view/main/onDemand/senior/view/video/view/play/OnDemandVideosPlayVideoComponent';
import { OnDemandProgramPlayComponent } from 'app/component/view/main/onDemand/senior/view/program/view/play/OnDemandProgramPlayComponent';
import { OrganizationsListComponent } from 'app/component/view/main/organizations/OrganizationsListComponent';
import { OrganizationsCreateComponent } from 'app/component/view/main/organizations/create/OrganizationsCreateComponent';
import { OrganizationsDetailsComponent } from 'app/component/view/main/organizations/details/OrganizationsDetailsComponent';
import { OrganizationsEditComponent } from 'app/component/view/main/organizations/edit/OrganizationsEditComponent';
import { UserCreateComponent } from 'app/component/view/main/user/admin/create/UserCreateComponent';
import { UserDetailsComponent } from 'app/component/view/main/user/admin/details/UserDetailsComponent';
import { UserEditComponent } from 'app/component/view/main/user/admin/edit/UserEditComponent';
import { MyScheduleUserComponent } from 'app/component/view/main/user/senior/mySchedule/MyScheduleUserComponent';
import { UserListComponent } from 'app/component/view/main/user/admin/list/UserListComponent';
import { PreliminaryLayoutComponent } from 'app/component/view/preliminary/PreliminaryLayoutComponent';
import { ErrorAccessDeniedComponent } from 'app/component/view/preliminary/error/ErrorAccessDeniedComponent';
import { ErrorNotFoundComponent } from 'app/component/view/preliminary/error/ErrorNotFoundComponent';
import { HowDoesItWorkComponent } from 'app/component/view/preliminary/howDoesItWork/HowDoesItWorkComponent';
import { LoginComponent } from 'app/component/view/preliminary/login/LoginComponent';
import { FirstLoginPreferencesComponent } from 'app/component/view/preliminary/preferences/FirstLoginPreferencesComponent';
import { RegistrationCompleteComponent } from 'app/component/view/preliminary/registration/RegistrationCompleteComponent';
import { RegistrationConfirmComponent } from 'app/component/view/preliminary/registration/RegistrationConfirmComponent';
import { RegistrationPasswordComponent } from 'app/component/view/preliminary/registration/RegistrationPasswordComponent';
import { RegistrationBrandedStartComponent } from 'app/component/view/preliminary/registration/branded-path/RegistrationBrandedStartComponent';
import { RegistrationBrandedUserDataComponent } from 'app/component/view/preliminary/registration/branded-path/RegistrationBrandedUserDataComponent';
import { RegistrationStartComponent } from 'app/component/view/preliminary/registration/standard-path/RegistrationStartComponent';
import { ResetPasswordCompleteComponent } from 'app/component/view/preliminary/resetPassword/ResetPasswordCompleteComponent';
import { ResetPasswordStartComponent } from 'app/component/view/preliminary/resetPassword/ResetPasswordStartComponent';
import { VerificationCodeComponent } from 'app/component/view/preliminary/resetPassword/VerificationCodeComponent';
import { ResetPasswordRequiredComponent } from 'app/component/view/preliminary/resetPasswordRequired/ResetPasswordRequiredComponent';
import { WithoutVerificationComponent } from 'app/component/view/preliminary/withoutVerification/WithoutVerificationComponent';
import { RawLayoutComponent } from 'app/component/view/raw/RawLayoutComponent';
import { PermissionName } from 'app/data/enum/permission/PermissionName';
import { DashboardComponent } from 'app/component/view/main/dashboard/DashboardComponent';
import { UserType } from 'app/data/enum/user/UserType';
import { LiveClassSeriesListAdminComponent } from 'app/component/view/main/liveClasses/admin/series/list/LiveClassSeriesListAdminComponent';
import { LiveClassSeriesCreateAdminComponent } from 'app/component/view/main/liveClasses/admin/series/create/LiveClassSeriesCreateAdminComponent';
import { LiveClassSeriesEditAdminComponent } from 'app/component/view/main/liveClasses/admin/series/edit/LiveClassSeriesEditAdminComponent';
import { LiveClassSeriesDetailsAdminComponent } from 'app/component/view/main/liveClasses/admin/series/details/LiveClassSeriesDetailsAdminComponent';
import { FriendsListComponent } from 'app/component/view/main/friends/view/list/FriendsListComponent';
import { FriendsInvitationsComponent } from 'app/component/view/main/friends/view/invitations/FriendsInvitationsComponent';
import { WatchPartyListComponent } from 'app/component/view/main/onDemand/senior/view/watchParty/view/list/WatchPartyListComponent';
import { WatchPartyCreateComponent } from 'app/component/view/main/onDemand/senior/view/watchParty/view/create/WatchPartyCreateComponent';
import { OnDemandAllVideosComponent } from 'app/component/view/main/onDemand/senior/view/video/view/list/OnDemandAllVideosComponent';
import { OnDemandProgramListComponent } from 'app/component/view/main/onDemand/senior/view/program/view/list/OnDemandProgramListComponent';
import { WatchPartyEditComponent } from 'app/component/view/main/onDemand/senior/view/watchParty/view/edit/WatchPartyEditComponent';
import { WatchPartyDetailsComponent } from 'app/component/view/main/onDemand/senior/view/watchParty/view/details/WatchPartyDetailsComponent';
import { WatchPartyEventComponent } from 'app/component/view/main/onDemand/senior/view/watchParty/view/event/WatchPartyEventComponent';
import { LiveClassesUserZoomComponent } from 'app/component/view/main/liveClasses/user/zoom/LiveClassesUserZoomComponent';
import { LiveClassesCoachZoomComponent } from 'app/component/view/main/liveClasses/coach/zoom/LiveClassesCoachZoomComponent';
import { SubscriptionTier } from 'app/data/enum/SubscriptionTier';

@Component({ selector: 'app-abstract', template: '<ui-view></ui-view>' })
export class AbstractStateComponent {}

// @formatter:off
export const StateConfig: Ng2StateDeclaration[] = [
  { abstract: true, name: Layout.MAIN, component: MainLayoutComponent },
  { abstract: true, name: Layout.PRELIMINARY, component: PreliminaryLayoutComponent },
  { abstract: true, name: Layout.RAW, component: RawLayoutComponent },

  { url: '^/error', abstract: true, name: State.PRELIMINARY.ERROR.ERROR, component: AbstractStateComponent },
    { url: '/not-found', name: State.PRELIMINARY.ERROR.NOT_FOUND, component: ErrorNotFoundComponent },
    { url: '/access-denied', name: State.PRELIMINARY.ERROR.ACCESS_DENIED, component: ErrorAccessDeniedComponent },
  { url: '/how-does-it-work', name: State.PRELIMINARY.HOW_DOES_IT_WORK, component: HowDoesItWorkComponent },
  { url: '/login', name: State.PRELIMINARY.LOGIN, component: LoginComponent },
  { url: '/without-verification', name: State.PRELIMINARY.WITHOUT_VERIFICATION, component: WithoutVerificationComponent,
    params: { username: { value: null, squash: true } }
  },
  { url: '/forgot-password', name: State.PRELIMINARY.RESET_PASSWORD_START, component: ResetPasswordStartComponent },
  { url: '/verification-code/{username:[\+\!\#\$\&\'\*\=\?\^\`\{\|\}\~a-zA-Z0-9\%\@\.\_\-]+}', name: State.PRELIMINARY.RESET_PASSWORD_VERIFICATION_CODE, component: VerificationCodeComponent,
    params: { username: { value: null, squash: true }, verificationCode: { value: null, squash: true } }
  },
  { url: '/reset-password/{username:[\+\!\#\$\&\'\*\=\?\^\`\{\|\}\~a-zA-Z0-9\%\@\.\_\-]+}/{verificationCode:.+}', name: State.PRELIMINARY.RESET_PASSWORD_COMPLETE, component: ResetPasswordCompleteComponent,
    params: { username: { value: null, squash: true }, verificationCode: { value: null, squash: true } }
  },

  { url: '^/register', abstract: true, name: State.PRELIMINARY.REGISTRATION.REGISTRATION, component: AbstractStateComponent },
    { url: '/start', name: State.PRELIMINARY.REGISTRATION.START, component: RegistrationStartComponent,
      params: { userRegistration: {value: null, squash: true } }
    },
    { url: '/password', name: State.PRELIMINARY.REGISTRATION.PASSWORD, component: RegistrationPasswordComponent,
      params: { userRegistration: {value: null, squash: true }, currentStep: {value: null, squash: true }, noOfSteps: {value: null, squash: true } }
    },
  { url: '^/register/optum', abstract: true, name: State.PRELIMINARY.REGISTRATION_BRANDED.REGISTRATION_BRANDED, component: AbstractStateComponent },
    { url: '/start?product', name: State.PRELIMINARY.REGISTRATION_BRANDED.START, component: RegistrationBrandedStartComponent,
      params: { product: {
        value: null,
        squash: true
      }, userRegistration: { value: null, squash: true } }
    },
    { url: '/data', name: State.PRELIMINARY.REGISTRATION_BRANDED.USER_DATA, component: RegistrationBrandedUserDataComponent,
      params: { userRegistration: {value: null, squash: true }, currentStep: {value: null, squash: true }, noOfSteps: {value: null, squash: true } }
    },
    { url: '/password', name: State.PRELIMINARY.REGISTRATION_BRANDED.PASSWORD, component: RegistrationPasswordComponent,
      params: { userRegistration: {value: null, squash: true }, currentStep: {value: null, squash: true }, noOfSteps: {value: null, squash: true } }
    },

  { url: '/registration-complete/{username:[\+\!\#\$\&\'\*\=\?\^\`\{\|\}\~a-zA-Z0-9\%\@\.\_\-]+}', name: State.PRELIMINARY.REGISTRATION_COMPLETE, component: RegistrationCompleteComponent,
    params: { username: {value: null, squash: true } }
  },

  { url: '/confirm-registration/{username:[\+\!\#\$\&\'\*\=\?\^\`\{\|\}\~a-zA-Z0-9\%\@\.\_\-]+}/{confirmationCode:.+}', name: State.PRELIMINARY.REGISTRATION_CONFIRM, component: RegistrationConfirmComponent,
    params: { username: { value: null, squash: false }, confirmationCode: { value: null, squash: true } }
  },

  { url: '/preferences', name: State.PRELIMINARY.PREFERENCES, component: FirstLoginPreferencesComponent,
    data: { allowedUserTypes: [ UserType.SENIOR ] }
  },

  { url: '^/dashboard', name: State.MAIN.DASHBOARD, component: DashboardComponent,
    data: { availableToGuest: true, showGuestPlaceholder: false, tiers: [ SubscriptionTier.THIRD_TIER ] }
  },

  { url: '^/account', abstract: true, name: State.MAIN.ACCOUNT.ACCOUNT, component: AbstractStateComponent },
    { url: '/details?tab', name: State.MAIN.ACCOUNT.DETAILS, component: AccountDetailsComponent },
    { url: '/password-change-required', name:  State.PRELIMINARY.PASSWORD_CHANGE_REQUIRED, component: ResetPasswordRequiredComponent },

  { url: '^/friends', abstract: true, name: State.MAIN.SENIOR.FRIEND.FRIEND, component: AbstractStateComponent,
    data: { availableToGuest: true, showGuestPlaceholder: true, allowedUserTypes: [ UserType.SENIOR ], tiers: [ SubscriptionTier.THIRD_TIER ] } },
    { url: '/list', name: State.MAIN.SENIOR.FRIEND.LIST, component: FriendsListComponent },
    { url: '/invitations', name: State.MAIN.SENIOR.FRIEND.INVITATIONS, component: FriendsInvitationsComponent },

  { url: '^/user', abstract: true, name: State.MAIN.SENIOR.SENIOR, component: AbstractStateComponent },
    { url: '/my-activity', abstract: true, name: State.MAIN.SENIOR.MY_ACTIVITY.MY_ACTIVITY,
      component: AbstractStateComponent, data: { tiers: [ SubscriptionTier.THIRD_TIER ] } },
      { url: '/list', name: State.MAIN.SENIOR.MY_ACTIVITY.LIST, component: MyActivityComponent,
        data: { availableToGuest: true, showGuestPlaceholder: true, allowedUserTypes: [ UserType.SENIOR ] }
      },
      { url: '/all-badges', name: State.MAIN.SENIOR.MY_ACTIVITY.ALL_BADGES, component: AllBadgesComponent,
        data: { allowedUserTypes: [ UserType.SENIOR ] }
      },
    { url: '/live-classes', abstract: true, name: State.MAIN.SENIOR.LIVE_CLASSES.LIVE_CLASSES,
      component: AbstractStateComponent, data: { tiers: [ SubscriptionTier.THIRD_TIER ] } },
      { url: '/list', name: State.MAIN.SENIOR.LIVE_CLASSES.LIST, component: LiveClassesListUserComponent,
        data: {
          availableToGuest: true,
          showGuestPlaceholder: false,
          allowedUserTypes: [ UserType.SENIOR ],
          requiredPermissions: [ PermissionName.LIVE_CLASSES_READ, PermissionName.LIVE_CLASSES_FAVORITE, PermissionName.LIVE_CLASSES_SELF_ENROLL ]
        },
      },
      { url: '/{id:int}/details', name: State.MAIN.SENIOR.LIVE_CLASSES.DETAILS, component: LiveClassDetailsUserComponent,
        data: {
          availableToGuest: true,
          showGuestPlaceholder: true,
          allowedUserTypes: [ UserType.SENIOR ],
          requiredPermissions: [ PermissionName.LIVE_CLASSES_READ, PermissionName.LIVE_CLASSES_FAVORITE, PermissionName.LIVE_CLASSES_SELF_ENROLL ]
        }
      },
      { url: '/{id:int}/zoom', name: State.MAIN.SENIOR.LIVE_CLASSES.ZOOM, component: LiveClassesUserZoomComponent,
        data: { allowedUserTypes: [ UserType.SENIOR ], requiredPermissions: [ PermissionName.LIVE_CLASSES_READ, PermissionName.LIVE_CLASSES_SELF_ENROLL ] }
      },
    { url: '/on-demand', abstract: true, name: State.MAIN.SENIOR.ON_DEMAND.ON_DEMAND, component: AbstractStateComponent },
      { url: '/videos', abstract: true, name: State.MAIN.SENIOR.ON_DEMAND.VIDEO.VIDEO, component: AbstractStateComponent },
        { url: '', name: State.MAIN.SENIOR.ON_DEMAND.VIDEO.LIST, component: OnDemandAllVideosComponent,
          data: {
            availableToGuest: true,
            showGuestPlaceholder: false,
            allowedUserTypes: [ UserType.SENIOR ],
            requiredPermissions: [ PermissionName.VIDEOS_READ, PermissionName.VIDEOS_FAVORITE ]
          }
        },
        { url: '/{videoId:int}', name: State.MAIN.SENIOR.ON_DEMAND.VIDEO.PLAY, component: OnDemandVideosPlayVideoComponent,
          data: {
            availableToGuest: true,
            showGuestPlaceholder: true,
            allowedUserTypes: [ UserType.SENIOR ],
            requiredPermissions: [ PermissionName.VIDEOS_READ, PermissionName.VIDEOS_FAVORITE ]
          }
        },
        { url: '/favorites', name: State.MAIN.SENIOR.ON_DEMAND.VIDEO.FAVORITES, component: OnDemandAllVideosComponent,
          data: {
            availableToGuest: false,
            showGuestPlaceholder: true,
            allowedUserTypes: [ UserType.SENIOR ],
            requiredPermissions: [ PermissionName.VIDEOS_READ, PermissionName.VIDEOS_FAVORITE ]
          }
        },
      { url: '/programs', abstract: true, name: State.MAIN.SENIOR.ON_DEMAND.PROGRAM.PROGRAM, component: AbstractStateComponent },
        { url: '', name: State.MAIN.SENIOR.ON_DEMAND.PROGRAM.LIST, component: OnDemandProgramListComponent,
          data: {
            availableToGuest: false,
            showGuestPlaceholder: true,
            allowedUserTypes: [ UserType.SENIOR ],
            requiredPermissions: [ PermissionName.VIDEOS_READ, PermissionName.VIDEOS_FAVORITE ],
            tiers: [ SubscriptionTier.THIRD_TIER, SubscriptionTier.SECOND_TIER ]
          }
        },
        { url: '/{id:int}', name: State.MAIN.SENIOR.ON_DEMAND.PROGRAM.PLAY, component: OnDemandProgramPlayComponent,
          data: {
            availableToGuest: true,
            showGuestPlaceholder: true,
            allowedUserTypes: [ UserType.SENIOR ],
            requiredPermissions: [ PermissionName.PROGRAMS_READ ]
          }
        },
      { url: '/watch-parties', abstract: true, name: State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.WATCH_PARTY, component: AbstractStateComponent,
        data: {
          allowedUserTypes: [ UserType.SENIOR ],
          tiers: [ SubscriptionTier.THIRD_TIER ]
        }
      },
        { url: '', name: State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.LIST, component: WatchPartyListComponent },
        { url: '/create?video', name: State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.CREATE, component: WatchPartyCreateComponent },
        { url: '/{id:int}/edit', name: State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.EDIT, component: WatchPartyEditComponent },
        { url: '/{id:int}', name: State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.DETAILS, component: WatchPartyDetailsComponent },
        { url: '/{id:int}/event', name: State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.EVENT, component: WatchPartyEventComponent },

    { url: '/my-schedule', name: State.MAIN.SENIOR.MY_SCHEDULE, component: MyScheduleUserComponent,
      data: { availableToGuest: true, showGuestPlaceholder: true,
        allowedUserTypes: [ UserType.SENIOR ], tiers: [ SubscriptionTier.THIRD_TIER ] }
    },

  { url: '^/admin', abstract: true, name: State.MAIN.ADMIN.ADMIN, component: AbstractStateComponent },
    { url: '/on-demand', abstract: true, name: State.MAIN.ADMIN.ON_DEMAND.ON_DEMAND, component: AbstractStateComponent },
      { url: '/list', name: State.MAIN.ADMIN.ON_DEMAND.LIST, component: OnDemandVideosAdminListComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.ADMIN_VIDEOS_READ, PermissionName.VIDEOS_DELETE ] }
      },
      { url: '/{videoId:int}/details', name: State.MAIN.ADMIN.ON_DEMAND.DETAILS, component: OnDemandVideosAdminDetailsComponent,
        params: { isAdmin: {value: true, squash: true } },
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.ADMIN_VIDEOS_READ ] }
      },
      { url: '/{videoId:int}/edit', name: State.MAIN.ADMIN.ON_DEMAND.EDIT, component: OnDemandVideosAdminEditComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.VIDEOS_UPDATE ] }
      },
      { url: '/new-on-demand', name: State.MAIN.ADMIN.ON_DEMAND.NEW_ON_DEMAND, component: OnDemandVideosAdminNewComponent },

      { url: '/program', abstract: true, name: State.MAIN.ADMIN.PROGRAM.PROGRAM, component: AbstractStateComponent },
        { url: '/create', name: State.MAIN.ADMIN.PROGRAM.CREATE, component: ProgramAdminNewComponent,
         data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.PROGRAMS_CREATE ] }
        },
        { url: '/{id:int}/details', name: State.MAIN.ADMIN.PROGRAM.DETAILS, component: ProgramAdminDetailsComponent,
         data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.PROGRAMS_READ ] }
        },
        { url: '/{id:int}/edit', name: State.MAIN.ADMIN.PROGRAM.EDIT, component: ProgramAdminEditComponent,
         data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.PROGRAMS_UPDATE ] }
        },

    { url: '/live-classes', abstract: true, name: State.MAIN.ADMIN.LIVE_CLASSES.LIVE_CLASSES, component: AbstractStateComponent },
      { url: '/single', abstract: true, name: State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.SINGLE, component: AbstractStateComponent },
        { url: '/list', name: State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.LIST, component: LiveClassSingleListAdminComponent,
          data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.ADMIN_LIVE_CLASSES_READ ] }
        },
        { url: '/create', name: State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.CREATE, component: LiveClassSingleCreateAdminComponent,
          data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.LIVE_CLASSES_CREATE ] }
        },
        { url: '/{id:int}/details', name: State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.DETAILS, component: LiveClassSingleDetailsAdminComponent,
          data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.ADMIN_LIVE_CLASSES_READ ] }
        },
        { url: '/{id:int}/edit', name: State.MAIN.ADMIN.LIVE_CLASSES.SINGLE.EDIT, component: LiveClassSingleEditAdminComponent,
          data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.LIVE_CLASSES_UPDATE ] }
        },
      { url: '/series', abstract: true, name: State.MAIN.ADMIN.LIVE_CLASSES.SERIES.SERIES, component: AbstractStateComponent },
        { url: '/list', name: State.MAIN.ADMIN.LIVE_CLASSES.SERIES.LIST, component: LiveClassSeriesListAdminComponent,
          data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.ADMIN_LIVE_CLASSES_READ ] }
        },
        { url: '/create', name: State.MAIN.ADMIN.LIVE_CLASSES.SERIES.CREATE, component: LiveClassSeriesCreateAdminComponent,
          data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.LIVE_CLASSES_CREATE ] }
        },
        { url: '/{id:int}/details', name: State.MAIN.ADMIN.LIVE_CLASSES.SERIES.DETAILS, component: LiveClassSeriesDetailsAdminComponent,
          data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.ADMIN_LIVE_CLASSES_READ ] }
        },
        { url: '/{id:int}/edit', name: State.MAIN.ADMIN.LIVE_CLASSES.SERIES.EDIT, component: LiveClassSeriesEditAdminComponent,
          data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.LIVE_CLASSES_UPDATE ] }
        },
    { url: '/coaches', abstract: true, name: State.MAIN.ADMIN.COACHES.COACHES, component: AbstractStateComponent },
      { url: '/list', name: State.MAIN.ADMIN.COACHES.LIST, component: CoachesListComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.COACHES_READ ] }
      },
      { url: '/create', name: State.MAIN.ADMIN.COACHES.CREATE, component: CoachesCreateComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.COACHES_CREATE ] }
      },
      { url: '/{id:int}/edit', name: State.MAIN.ADMIN.COACHES.EDIT, component: CoachesEditComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.COACHES_UPDATE ] }
      },
      { url: '/{id:int}/details', name: State.MAIN.ADMIN.COACHES.DETAILS, component: CoachesDetailsComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.COACHES_READ ] }
      },
    { url:'/user', abstract: true, name: State.MAIN.ADMIN.USER.USER, component: AbstractStateComponent},
      { url: '/list', name: State.MAIN.ADMIN.USER.LIST, component: UserListComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.SENIORS_READ ] }
      },
      { url: '/create', name: State.MAIN.ADMIN.USER.CREATE, component: UserCreateComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.SENIORS_CREATE ] }
      },
      { url: '/{id:int}/edit', name: State.MAIN.ADMIN.USER.EDIT, component: UserEditComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.SENIORS_UPDATE ] }
      },
      { url: '/{id:int}/details', name: State.MAIN.ADMIN.USER.DETAILS, component: UserDetailsComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.SENIORS_READ ] }
      },
    { url: '/organizations',abstract: true, name: State.MAIN.ADMIN.ORGANIZATIONS.ORGANIZATIONS, component: AbstractStateComponent},
      { url: '/list', name: State.MAIN.ADMIN.ORGANIZATIONS.LIST, component:OrganizationsListComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.ORGANIZATIONS_READ ] }
      },
      { url: '/{id:int}/details', name: State.MAIN.ADMIN.ORGANIZATIONS.DETAILS, component: OrganizationsDetailsComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.ORGANIZATIONS_READ ] }
      },
      { url: '/{id:int}/edit', name: State.MAIN.ADMIN.ORGANIZATIONS.EDIT, component: OrganizationsEditComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.ORGANIZATIONS_UPDATE ] }
      },
      { url: '/create', name: State.MAIN.ADMIN.ORGANIZATIONS.CREATE, component: OrganizationsCreateComponent,
        data: { allowedUserTypes: [ UserType.ADMIN ], requiredPermissions: [ PermissionName.ORGANIZATIONS_CREATE ] }
      },

  { url: '^/coach', abstract: true, name: State.MAIN.COACH.COACH, component: AbstractStateComponent },
    { url: '/live-classes', abstract: true, name: State.MAIN.COACH.LIVE_CLASSES.LIVE_CLASSES, component: AbstractStateComponent },
      { url: '/list', name: State.MAIN.COACH.LIVE_CLASSES.LIST, component: LiveClassesListCoachComponent,
        data: { allowedUserTypes: [ UserType.COACH ], requiredPermissions: [ PermissionName.LIVE_CLASSES_READ, PermissionName.LIVE_CLASSES_COACH ] }
      },
      { url: '/{id:int}/details', name: State.MAIN.COACH.LIVE_CLASSES.DETAILS, component: LiveClassDetailsCoachComponent,
        data: { allowedUserTypes: [ UserType.COACH ], requiredPermissions: [ PermissionName.LIVE_CLASSES_READ, PermissionName.LIVE_CLASSES_COACH ] }
      },
      { url: '/{id:int}/zoom', name: State.MAIN.COACH.LIVE_CLASSES.ZOOM, component: LiveClassesCoachZoomComponent,
        data: { allowedUserTypes: [ UserType.COACH ], requiredPermissions: [ PermissionName.LIVE_CLASSES_READ, PermissionName.LIVE_CLASSES_COACH ] }
      },
    { url: '/on-demand', abstract: true, name: State.MAIN.COACH.ON_DEMAND.ON_DEMAND, component: AbstractStateComponent },
      { url: '/list', name: State.MAIN.COACH.ON_DEMAND.LIST, component: OnDemandCoachListComponent,
        data: { allowedUserTypes: [ UserType.COACH ], requiredPermissions: [ PermissionName.VIDEOS_READ, PermissionName.COACH_VIDEOS_READ ] }
      },
      { url: '/{videoId:int}/details', name: State.MAIN.COACH.ON_DEMAND.DETAILS, component: OnDemandVideosAdminDetailsComponent,
        params: { isAdmin: { value: null, squash: true } },
        data: { allowedUserTypes: [ UserType.COACH ], requiredPermissions: [ PermissionName.VIDEOS_READ, PermissionName.COACH_VIDEOS_READ ] }
      },
    { url: '/program/{id:int}', name: State.MAIN.COACH.ON_DEMAND.PROGRAM, component: ProgramAdminDetailsComponent,
      data: { allowedUserTypes: [ UserType.COACH ], requiredPermissions: [ PermissionName.PROGRAMS_READ ] }
    },
    { url: '/help', name: State.MAIN.HELP, component: HelpComponent },
    { url: '/faq', name: State.MAIN.FAQ, component: HelpComponent }
];
// @formatter:on

<app-data-grid
  *ngIf="page"
  [data]="page?.content"
  [criteria]="criteria"
  [showFilters]="false"
  [showSearch]="false"
  [showPagination]="false"
  [pageTotal]="page?.totalPages"
  [itemTotal]="page?.totalElements"
  [showItemsPerPage]="true"
  [showTotalItems]="false"
  [selectionEnabled]="true"
  [multiSelectionEnabled]="true"
  [useCheckboxSelection]="true"
  (criteriaChanged)="onCriteriaChanged($event)"
  (gridReady)="onGridReady($event)"
  [columns]="$any([
    { colId: 'date', headerName: ('COMMON.DATE' | translate), maxWidth: 90, field: 'startDate', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: dateTemplate } },
    { colId: 'time', headerName: ('COMMON.TIME' | translate), maxWidth: 90, field: 'startDate', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: timeTemplate } },
    { colId: 'title', headerName: ('VIEW.MAIN.ON_DEMAND.TITLE' | translate), flex: 1, field: 'video.title' },
    { colId: 'host', headerName: ('DOMAIN.WATCH_PARTY.DATA.HOST.LABEL' | translate), flex: 1, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: hostTemplate } },
    { colId: 'capacity', headerName: ('DOMAIN.WATCH_PARTY.DATA.CAPACITY.LABEL' | translate), maxWidth: 100, field: 'capacity', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: capacityTemplate } },
    { colId: 'category', headerName: ('VIEW.MAIN.ON_DEMAND.CATEGORY' | translate), maxWidth: 120, field: 'video.category', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: videoCategoryTemplate } },
    { colId: 'actions', headerName: '', headerClass: 'header-right', flex: 1, minWidth: 100, cellClass: 'cell-fullwidth', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: actionsTemplate } },
  ])">
</app-data-grid>

<div class="d-flex justify-content-center" *ngIf="page?.isMore">
  <button type="button" class="btn btn-link" (click)="nextPage()">{{ 'COMMON.LOAD_MORE' | translate }}</button>
</div>

<!--Grid templates-->
<ng-template #dateTemplate let-value="value">{{ value | date:'shortDate' | replaceEmpty }}</ng-template>
<ng-template #timeTemplate let-value="value">{{ value | date:'shortTime' | replaceEmpty }}</ng-template>
<ng-template #hostTemplate let-data>
  <div class="d-flex align-items-center h-100 line-height-normal gap-3">
    <app-avatar [src]="data.host?.avatar?.thumbnailAsset?.url" size="40px" [circle]="true"></app-avatar>
    <span class="cut-long-text">{{ data.host?.fullName | replaceEmpty }}</span>
  </div>
</ng-template>
<ng-template #videoCategoryTemplate
             let-value="value">{{ value | prefix: 'ENUM.VIDEO_CATEGORY.' | translate | replaceEmpty }}
</ng-template>
<ng-template #capacityTemplate let-data let-value="value">
  <span>{{ data.participants?.length | replaceEmpty:'0' }}/{{ value | replaceEmpty:'0' }}</span>
</ng-template>

<ng-template #actionsTemplate let-data>
  <div class="d-flex justify-content-end align-items-center h-100 gap-3">
    <button type="button" class="btn btn-sm btn-link"
            [uiSref]="State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.DETAILS"
            [uiParams]="{id: data.id}">
      <svg-icon src="asset/image/icon/eye.svg"></svg-icon>
      <span>{{ 'COMMON.DETAILS' | translate }}</span>
    </button>

    <button type="button" class="btn btn-sm btn-link text-danger"
            *appCanPerformAction="watchPartyModel.logic.isDroppable;data:data"
            (click)="drop(data)">
      <svg-icon src="asset/image/icon/circle-off.svg"></svg-icon>
      <span>{{ 'DOMAIN.WATCH_PARTY.ACTION.DROP' | translate }}</span>
    </button>

    <button type="button" class="btn btn-sm btn-link text-danger"
            *appCanPerformAction="watchPartyModel.logic.isCancelable;data:data"
            (click)="cancel(data)">
      <svg-icon src="asset/image/icon/circle-minus.svg"></svg-icon>
      <span>{{ 'DOMAIN.WATCH_PARTY.ACTION.CANCEL' | translate }}</span>
    </button>

    <button type="button" class="btn btn-sm btn-link"
            *appCanPerformAction="watchPartyModel.logic.isJoinable;data:data"
            (click)="join(data)">
      <svg-icon src="asset/image/icon/circle-chevron-right.svg"></svg-icon>
      <span>{{ 'DOMAIN.WATCH_PARTY.ACTION.JOIN' | translate }}</span>
    </button>

    <ng-container *appCanPerformAction="watchPartyModel.logic.isPendingAcceptance;data:data">
      <button type="button" class="btn btn-sm btn-link text-danger"
              (click)="reject(data)">
        <svg-icon src="asset/image/icon/circle-minus.svg"></svg-icon>
        <span>{{ 'DOMAIN.WATCH_PARTY.ACTION.REJECT_INVITATION' | translate }}</span>
      </button>

      <button type="button" class="btn btn-sm btn-link"
              (click)="accept(data)">
        <svg-icon src="asset/image/icon/circle-chevron-right.svg"></svg-icon>
        <span>{{ 'DOMAIN.WATCH_PARTY.ACTION.ACCEPT_INVITATION' | translate }}</span>
      </button>
    </ng-container>
  </div>
</ng-template>

import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { StateService } from '@uirouter/core';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { VideoCategory } from 'app/data/enum/video/VideoCategory';
import { PageUtil } from 'app/util/PageUtil';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserModel } from 'app/model/UserModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import _ from 'lodash';
import { OnDemandVideoFilterModalComponent } from 'app/component/view/main/onDemand/common/filters/OnDemandVideoFilterModalComponent';
import { filter } from 'rxjs/operators';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ProgramSeniorDetailsPageDTO } from 'app/data/dto/programs/ProgramSeniorDetailsPageDTO';
import { SeniorModel } from 'app/model/SeniorModel';
import { ProgramSeniorPageCriteriaDTO } from 'app/data/dto/programs/ProgramSeniorPageCriteriaDTO';
import { ProgramsFilterModalComponent } from 'app/component/view/main/onDemand/common/filters/ProgramsFilterModalComponent';
import { SeniorVideoSortBy, VideoSortBy } from 'app/data/enum/video/VideoSortBy';

@Component({
  selector: 'app-on-demand-program-list',
  templateUrl: './OnDemandProgramListComponent.html',
  styleUrls: [ './OnDemandProgramListComponent.scss' ]
})
export class OnDemandProgramListComponent implements OnInit {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public readonly maxNumberOfPages: number = 5;

  public currentUser: UserDTO;

  public internalPage: number = 1;

  public page: PageDTO<ProgramSeniorDetailsPageDTO>;

  public criteria: ProgramSeniorPageCriteriaDTO;

  public excludedSortBy: SeniorVideoSortBy[] = [ SeniorVideoSortBy.AVAILABLE ];

  public selectedCategory: VideoCategory;

  constructor(private modalService: BsModalService,
              private userModel: UserModel,
              private seniorModel: SeniorModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef,
              private stateService: StateService) {
    this.currentUser = this.userModel.currentUser;
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    const criteria = PageUtil.prepareCriteria(ProgramSeniorPageCriteriaDTO, this.stateService.params.criteria);

    this.onCriteriaChanged(criteria);
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public onCriteriaChanged(criteria: ProgramSeniorPageCriteriaDTO): void {
    this.criteria = criteria;
    this.criteria.pageNumber = 0;

    this.getOnDemandProgramPage();
  }

  public getOnDemandProgramPage(): void {
    PageUtil.updateURL(this.criteria)
    this.seniorModel.getProgramPage(this.criteria)
      .subscribe((page) => {
        this.page = page;
      });
  }

  public changeIsFavoriteProgram(program: ProgramSeniorDetailsPageDTO): void {
    if (program.isFavorite) {
      this.seniorModel
        .removeProgramsFromFavorites({ ids: [ program.id ] })
        .subscribe(() => this.getOnDemandProgramPage());
    }
    else {
      this.seniorModel.addProgramToFavorites({ ids: [ program.id ] }).subscribe(() => this.getOnDemandProgramPage());
    }
  }

  public changeCategory({ category, favorite }: { category: VideoCategory, favorite: boolean }): void {
    const criteria = _.cloneDeep(this.criteria);

    this.selectedCategory = category;

    criteria.anyOfCategories = category ? [ category ] : undefined;
    criteria.favorites = favorite || undefined;

    this.onCriteriaChanged(criteria);
  }

  public openFiltersModal(): void {
    const modal: BsModalRef = this.modalService.show(ProgramsFilterModalComponent, {
      initialState: {
        anyOfCoachIds: this.criteria.anyOfCoachIds,
        anyOfCategories: this.criteria.anyOfCategories
      },
      class: 'modal-dialog-centered'
    });
    modal.onHide
      .pipe(
        filter(
          (reason) =>
            reason === OnDemandVideoFilterModalComponent.FILTER_DATA ||
            reason === OnDemandVideoFilterModalComponent.CLEAR_FILTERS
        )
      )
      .subscribe(() => {
        const filters = modal.content.filters;
        const criteria = _.cloneDeep(this.criteria);

        criteria.anyOfCategories = filters.categories.selectedCategories;
        criteria.anyOfCoachIds = filters.coaches.selectedCoaches;

        this.onCriteriaChanged(criteria);
      });
  }

  public onPageChanged(page: PageChangedEvent): void {
    this.criteria.pageNumber = page.page - 1;
    this.getOnDemandProgramPage();
  }
}

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { State } from 'app/common/State';
import { VideoSeniorDTO } from 'app/data/dto/onDemandVideo/senior/VideoSeniorDTO';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { first } from 'rxjs/operators';

import { OnDemandVideosDetailsModalComponent } from '../../modal/OnDemandVideosDetailsModalComponent';
import { IntensityLevel } from 'app/data/enum/IntensityLevel';
import { SubscriptionTier } from 'app/data/enum/SubscriptionTier';

@Component({
  selector: 'app-on-demand-videos-item',
  templateUrl: './OnDemandVideosItemComponent.html',
  styleUrls: [ './OnDemandVideosItemComponent.scss' ],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('0.5s ease-out', style({ height: '*', opacity: '*' }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: '*' }),
        animate('0.5s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class OnDemandVideosItemComponent implements OnInit {
  @Input()
  public data: VideoSeniorDTO;

  @Input()
  public guestMode: boolean = false;

  @Input()
  public subscriptionTier: SubscriptionTier | null;

  @Output()
  public favoriteClicked: EventEmitter<void> = new EventEmitter();

  public readonly maxRating: number = 5;
  public readonly minRatingToDisplay: number = 4;

  public displayOverlay: boolean = false;
  public mappedIntensity: number;
  public subscriptionRequired: boolean = false;

  constructor(
    private stateService: StateService,
    private modalService: BsModalService
  ) {
  }

  public ngOnInit(): void {
    switch (this.data.intensity) {
      case IntensityLevel.ADVANCED: {
        this.mappedIntensity = 3;
        break;
      }
      case IntensityLevel.INTERMEDIATE: {
        this.mappedIntensity = 2;
        break;
      }
      case IntensityLevel.BEGINNER: {
        this.mappedIntensity = 1;
        break;
      }
      case IntensityLevel.NOT_APPLICABLE: {
        this.mappedIntensity = 0;
        break;
      }
    }

    if (this.subscriptionTier) {
      this.subscriptionRequired = this.subscriptionTier === SubscriptionTier.FIRST_TIER ? !this.data.accessibleForFirstTier : false;
    }
  }

  public playVideo(): void {
    this.stateService.go(State.MAIN.SENIOR.ON_DEMAND.VIDEO.PLAY, { videoId: this.data.id });
  }

  public showDetailsModal(): void {
    const modal: BsModalRef = this.modalService.show(OnDemandVideosDetailsModalComponent, {
      initialState: {
        videoId: this.data.id
      },
      class: 'modal-dialog-centered'
    });

    modal.onHide.pipe(first()).subscribe((result: string) => {
      if (result === OnDemandVideosDetailsModalComponent.PLAY_VIDEO) {
        this.playVideo();
      }
    });
  }

  public changeIsFavorite(): void {
    this.favoriteClicked.emit(null);
  }
}

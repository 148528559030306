import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { StorageModule } from '@ngx-pwa/local-storage';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Application } from 'app/Application';
import { ApplicationComponent } from 'app/component/ApplicationComponent';
import { DataGridComponent } from 'app/component/ui/dataGrid/DataGridComponent';
import { DataGridBooleanCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridBooleanCellRendererComponent';
import { DataGridEmailCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridEmailCellRendererComponent';
import { DataGridFileCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridFileCellRendererComponent';
import { FileUploadPopupComponent } from 'app/component/ui/fileUpload/FileUploadPopupComponent';
import { FormCustomComponent } from 'app/component/ui/formCustomComponent/FormCustomComponent';
import { FormNestedComponent } from 'app/component/ui/formNestedComponent/FormNestedComponent';
import { InternalUrlBrowserComponent } from 'app/component/ui/internalUrlBrowser/InternalUrlBrowserComponent';
import { InternalUrlBrowserPopupComponent } from 'app/component/ui/internalUrlBrowser/popup/InternalUrlBrowserPopupComponent';
import { PopupAlertComponent } from 'app/component/ui/popup/PopupAlertComponent';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { ResponsiveDataGridComponent } from 'app/component/ui/responsiveDataGrid/ResponsiveDataGridComponent';
import { ResponsiveDataGridDynamicElementComponent } from 'app/component/ui/responsiveDataGrid/ResponsiveDataGridDynamicElementComponent';
import { ZoomCompleteViewComponent } from 'app/component/ui/zoom/liveclasses-zoom-complete-view/zoom-complete-view.component';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { AccountDetailsComponent } from 'app/component/view/main/account/AccountDetailsComponent';
import { AccountChangePasswordComponent } from 'app/component/view/main/account/password/AccountChangePasswordComponent';
import { UserListComponent } from 'app/component/view/main/user/admin/list/UserListComponent';
import { PreliminaryLayoutComponent } from 'app/component/view/preliminary/PreliminaryLayoutComponent';
import { ErrorAccessDeniedComponent } from 'app/component/view/preliminary/error/ErrorAccessDeniedComponent';
import { ErrorNotFoundComponent } from 'app/component/view/preliminary/error/ErrorNotFoundComponent';
import { LoginComponent } from 'app/component/view/preliminary/login/LoginComponent';
import { RegistrationCompleteComponent } from 'app/component/view/preliminary/registration/RegistrationCompleteComponent';
import { RegistrationStartComponent } from 'app/component/view/preliminary/registration/standard-path/RegistrationStartComponent';
import { ResetPasswordCompleteComponent } from 'app/component/view/preliminary/resetPassword/ResetPasswordCompleteComponent';
import { ResetPasswordStartComponent } from 'app/component/view/preliminary/resetPassword/ResetPasswordStartComponent';
import { RawLayoutComponent } from 'app/component/view/raw/RawLayoutComponent';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { RouterConfig } from 'app/config/RouterConfig';
import { AbstractStateComponent, StateConfig } from 'app/config/StateConfig';
import { StorageConfig } from 'app/config/StorageConfig';
import { TranslateConfig } from 'app/config/TranslateConfig';
import { LanguageVersion } from 'app/data/local/LanguageVersion';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { AuthModel } from 'app/model/AuthModel';
import { FileModel } from 'app/model/FileModel';
import { UserModel } from 'app/model/UserModel';
import { UserRegistrationModel } from 'app/model/UserRegistrationModel';
import { InputCheckBoxArrayValueAccessorDirective } from 'app/util/directive/InputCheckBoxArrayValueAccessorDirective';
import { InputFileValueAccessorDirective } from 'app/util/directive/InputFileValueAccessorDirective';
import { InputMultipleFileValueAccessorDirective } from 'app/util/directive/InputMultipleFileValueAccessorDirective';
import { InputNoSpaceDirective } from 'app/util/directive/InputNoSpaceDirective';
import { InputNumberOnlyDirective } from 'app/util/directive/InputNumberOnlyDirective';
import { InputPasswordToggleDirective } from 'app/util/directive/InputPasswordToggleDirective';
import { InputPositiveIntegerOnlyDirective } from 'app/util/directive/InputPositiveIntegerOnlyDirective';
import { InputToIntegerDirective } from 'app/util/directive/InputToIntegerDirective';
import { InputToNumberDirective } from 'app/util/directive/InputToNumberDirective';
import { AppLanguageInterceptor } from 'app/util/interceptor/AppLanguageInterceptor';
import { AppTypeInterceptor } from 'app/util/interceptor/AppTypeInterceptor';
import { AppVersionInterceptor } from 'app/util/interceptor/AppVersionInterceptor';
import { AuthInterceptor } from 'app/util/interceptor/AuthInterceptor';
import { ErrorInterceptor } from 'app/util/interceptor/ErrorInterceptor';
import { LoadingInterceptor } from 'app/util/interceptor/LoadingInterceptor';
import { SuppressInterceptor } from 'app/util/interceptor/SuppressInterceptor';
import { TimezoneInterceptor } from 'app/util/interceptor/TimezoneInterceptor';
import { ArrayJoinPipe } from 'app/util/pipe/ArrayJoinPipe';
import { BirthDateToAgePipe } from 'app/util/pipe/BirthDateToAgePipe';
import { BooleanPipe } from 'app/util/pipe/BooleanPipe';
import { ConsolePipe } from 'app/util/pipe/ConsolePipe';
import { DateNowRelationPipe } from 'app/util/pipe/DateNowRelationPipe';
import { DistanceFormatPipe } from 'app/util/pipe/DistanceFormatPipe';
import { DurationFormatPipe } from 'app/util/pipe/DurationFormatPipe';
import { FileIdLinkPipe } from 'app/util/pipe/FileIdLinkPipe';
import { FileUrlLinkPipe } from 'app/util/pipe/FileUrlLinkPipe';
import { MinutesToTimePipe } from 'app/util/pipe/MinutesToTimePipe';
import { Nl2BrPipe } from 'app/util/pipe/Nl2BrPipe';
import { PrefixPipe } from 'app/util/pipe/PrefixPipe';
import { ReplaceEmptyPipe } from 'app/util/pipe/ReplaceEmptyPipe';
import { SanitizeHtmlPipe } from 'app/util/pipe/SanitizeHtmlPipe';
import { SanitizeStylePipe } from 'app/util/pipe/SanitizeStylePipe';
import { SanitizeUrlPipe } from 'app/util/pipe/SanitizeUrlPipe';
import { SplitPipe } from 'app/util/pipe/SplitPipe';
import { SuffixPipe } from 'app/util/pipe/SuffixPipe';
import { UnescapePipe } from 'app/util/pipe/UnescapePipe';
import { AgeOver18Validator } from 'app/util/validator/AgeOver18Validator';
import { CheckboxArrayRequiredValidator } from 'app/util/validator/CheckboxArrayRequiredValidator';
import { FileAllowedExtensionsValidator } from 'app/util/validator/FileAllowedExtensionsValidator';
import { FileMaxSizeValidator } from 'app/util/validator/FileMaxSizeValidator';
import { FileRequiredValidator } from 'app/util/validator/FileRequiredValidator';
import { MaxDateTimeValidator } from 'app/util/validator/MaxDateTimeValidator';
import { MaxDateValidator } from 'app/util/validator/MaxDateValidator';
import { MaxLengthValidator } from 'app/util/validator/MaxLengthValidator';
import { MinDateTimeValidator } from 'app/util/validator/MinDateTimeValidator';
import { MinDateValidator } from 'app/util/validator/MinDateValidator';
import { PasswordRequirementsValidator } from 'app/util/validator/PasswordRequirementsValidator';
import { ReasonableBirthdateValidator } from 'app/util/validator/ReasonableBirthdateValidator';
import * as moment from 'moment';
import { ChartsModule } from 'ng2-charts';
import { FileUploadModule } from 'ng2-file-upload';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CustomFormsModule as NgValidatorsModule } from 'ngx-custom-validators-fork';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgPipesModule } from 'ngx-pipes';
import { ToastrModule } from 'ngx-toastr';
import { NgxTranslateCutModule } from 'ngx-translate-cut';
import { MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { LiveClassSingleListAdminComponent } from 'app/component/view/main/liveClasses/admin/single/list/LiveClassSingleListAdminComponent';
import { LiveClassSingleCreateAdminComponent } from 'app/component/view/main/liveClasses/admin/single/create/LiveClassSingleCreateAdminComponent';
import { LiveClassSingleDetailsAdminComponent } from 'app/component/view/main/liveClasses/admin/single/details/LiveClassSingleDetailsAdminComponent';
import { LiveClassSingleEditAdminComponent } from 'app/component/view/main/liveClasses/admin/single/edit/LiveClassSingleEditAdminComponent';
import { LiveClassesListCoachComponent } from './component/view/main/liveClasses/coach/LiveClassesListCoachComponent';
import { LiveClassDetailsCoachComponent } from './component/view/main/liveClasses/coach/details/LiveClassDetailsCoachComponent';
import { LiveClassesSelectedActionBarComponent } from './component/view/main/liveClasses/components/actionBar/LiveClassesSelectedActionBarComponent';
import { LiveClassesListActionCellRendererComponent } from './component/view/main/liveClasses/components/cellRenderer/LiveClassesListActionCellRendererComponent';
import { LiveClassesListCoachCellRendererComponent } from './component/view/main/liveClasses/components/cellRenderer/LiveClassesListCoachCellRendererComponent';
import { IntensityCellRendererComponent } from './component/view/main/liveClasses/components/cellRenderer/intensity/IntensityCellRendererComponent';
import { CoachDetailsComponent } from './component/view/main/liveClasses/components/coachDetailsModal/CoachDetailsComponent';
import { LiveClassesFilterComponent } from './component/view/main/liveClasses/components/filter/LiveClassesFilterComponent';
import { JoinUserComponent } from './component/view/main/liveClasses/components/join/JoinUserComponent';
import { ThankYouComponent } from './component/view/main/liveClasses/components/thankYou/ThankYouComponent';
import { LiveClassesListUserComponent } from './component/view/main/liveClasses/user/LiveClassesListUserComponent';
import { LiveClassDetailsUserComponent } from './component/view/main/liveClasses/user/details/LiveClassDetailsUserComponent';
import { OnDemandVideosItemComponent } from 'app/component/view/main/onDemand/senior/view/video/component/videoListItem/OnDemandVideosItemComponent';
import { HowDoesItWorkComponent } from './component/view/preliminary/howDoesItWork/HowDoesItWorkComponent';
import { RegistrationPasswordComponent } from './component/view/preliminary/registration/RegistrationPasswordComponent';
import { RegistrationUserNotFoundComponent } from './component/view/preliminary/registration/RegistrationUserNotFoundComponent';
import { RegistrationBrandedStartComponent } from './component/view/preliminary/registration/branded-path/RegistrationBrandedStartComponent';
import { RegistrationBrandedUserDataComponent } from './component/view/preliminary/registration/branded-path/RegistrationBrandedUserDataComponent';
import { VerificationCodeComponent } from './component/view/preliminary/resetPassword/VerificationCodeComponent';
import { ContentAndImageColumnLayoutComponent } from './component/view/preliminary/subLayouts/ContentAndImageColumnLayoutComponent';
import { WithoutVerificationComponent } from './component/view/preliminary/withoutVerification/WithoutVerificationComponent';
import { RepeatElementDirective } from './util/directive/RepeatElementDirective';

import { DynamicInputComponent } from './component/ui/dynamicInputs/DynamicInputComponent';
import { ThumbnailAndFilesUploadComponent } from './component/ui/fileUpload/ThumbnailAndFilesUploadComponent';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { NotificationsComponent } from './component/view/main/notifications/NotificationsComponent';
import { PageHeaderComponent } from './component/ui/pageHeader/PageHeaderComponent';
import { RatingStarsComponent } from 'app/component/ui/rating/RatingStarsComponent';
import { TabsComponent } from './component/ui/tabs/TabsComponent';
import { AccountDetailsPreferencesComponent } from './component/view/main/account/preferences/AccountDetailsPreferencesComponent';
import { AccountDetailsProfileComponent } from './component/view/main/account/profile/AccountDetailsProfileComponent';
import { AccountReferFriendComponent } from './component/view/main/account/referFriend/AccountReferFriendComponent';
import { CoachesListComponent } from './component/view/main/coaches/CoachesListComponent';
import { CoachesListActionCellRendererComponent } from './component/view/main/coaches/components/cellRenderer/menu/CoachesListActionCellRendererComponent';
import { RatingComponent } from './component/view/main/coaches/components/cellRenderer/rating/RatingComponent';
import { CoachesCreateComponent } from './component/view/main/coaches/create/CoachesCreateComponent';
import { CoachesDetailsComponent } from './component/view/main/coaches/details/CoachesDetailsComponent';
import { CoachesEditComponent } from './component/view/main/coaches/edit/CoachesEditComponent';
import { DashboardCoachComponent } from './component/view/main/dashboard/DashboardCoachComponent';
import { DashboardSeniorAllRecommendedModalComponent } from 'app/component/view/main/dashboard/senior/modal/DashboardSeniorAllRecommendedModalComponent';
import { UsersCapacityCellRendererComponent } from './component/view/main/liveClasses/components/cellRenderer/usersCapacity/UsersCapacityCellRendererComponent';
import { MyActivityComponent } from './component/view/main/myActivity/user/MyActivityComponent';
import { AllBadgesComponent } from './component/view/main/myActivity/user/allBadges/AllBadgesComponent';
import { BadgeTemplateComponent } from './component/view/main/myActivity/user/component/badgeTemplate/BadgeTemplateComponent';
import { ProgressionBarComponent } from './component/view/main/myActivity/user/component/progressionBar/ProgressionBarComponent';
import { RecentActivitiesToRateComponent } from './component/view/main/myActivity/user/component/recentActivitiesToRate/RecentActivitiesToRateComponent';
import { OnDemandVideosListAdminActionCellRendererComponent } from 'app/component/view/main/onDemand/admin/videos/cellRenderer/OnDemandVideosListAdminActionCellRendererComponent';
import { OnDemandVideosAdminDetailsComponent } from 'app/component/view/main/onDemand/admin/videos/details/OnDemandVideosAdminDetailsComponent';
import { OnDemandVideosAdminEditComponent } from 'app/component/view/main/onDemand/admin/videos/edit/OnDemandVideosAdminEditComponent';
import { OnDemandVideosAdminListComponent } from 'app/component/view/main/onDemand/admin/videos/list/OnDemandVideosAdminListComponent';
import { OnDemandVideosAdminNewComponent } from 'app/component/view/main/onDemand/admin/videos/new/onDemand/OnDemandVideosAdminNewComponent';
import { OnDemandVideosUploadToVimeoComponent } from 'app/component/view/main/onDemand/admin/videos/uploadToVimeo/OnDemandVideosUploadToVimeoComponent';
import { OnDemandCoachListComponent } from 'app/component/view/main/onDemand/coach/list/OnDemandCoachListComponent';
import { OnDemandVideosListCoachActionCellRendererComponent } from 'app/component/view/main/onDemand/coach/list/cellRenderer/OnDemandVideosListCoachActionCellRendererComponent';
import { OnDemandVideosBulkActionsComponent } from 'app/component/view/main/onDemand/common/bulkActions/OnDemandVideosBulkActionsComponent';
import { OnDemandVideoFilterModalComponent } from 'app/component/view/main/onDemand/common/filters/OnDemandVideoFilterModalComponent';
import { OnDemandVideosDetailsModalComponent } from 'app/component/view/main/onDemand/senior/view/video/modal/OnDemandVideosDetailsModalComponent';
import { OnDemandVideosPlayVideoComponent } from 'app/component/view/main/onDemand/senior/view/video/view/play/OnDemandVideosPlayVideoComponent';
import { UsersSelectedActionBarComponent } from './component/view/main/user/components/actionBar/UsersSelectedActionBarComponent';
import { UserDetailsComponent } from './component/view/main/user/admin/details/UserDetailsComponent';
import { UserDetailsProfileComponent } from './component/view/main/user/admin/details/UserDetailsProfileComponent';
import { MyScheduleUserComponent } from 'app/component/view/main/user/senior/mySchedule/MyScheduleUserComponent';
import { FirstLoginPreferencesComponent } from './component/view/preliminary/preferences/FirstLoginPreferencesComponent';
import { RegistrationConfirmComponent } from './component/view/preliminary/registration/RegistrationConfirmComponent';
import { TimeAgoPipe } from './util/pipe/TimeAgoPipe';
import { FileSizePipe } from './util/pipe/FileSizePipe';
import { FullNamePipe } from './util/pipe/FullNamePipe';
import { MemberCodeValidator } from './util/validator/MemberCodeValidator';
import { UserListActionsCellRendererComponent } from './component/view/main/user/components/cellRenderer/UserListActionsCellRendererComponent';
import { UserEditComponent } from 'app/component/view/main/user/admin/edit/UserEditComponent';
import { UserCreateComponent } from 'app/component/view/main/user/admin/create/UserCreateComponent';
import { DashboardSeniorComponent } from './component/view/main/dashboard/DashboardSeniorComponent';
import { CoachesSelectedActionBarComponent } from './component/view/main/coaches/components/actionBar/CoachesSelectedActionBarComponent';
import { OrganizationsDetailsComponent } from './component/view/main/organizations/details/OrganizationsDetailsComponent';
import { DashboardSeniorHappeningNowComponent } from 'app/component/view/main/dashboard/senior/DashboardSeniorHappeningNowComponent';
import { DashboardSeniorRecommendedComponent } from 'app/component/view/main/dashboard/senior/DashboardSeniorRecommendedComponent';
import { ResetPasswordRequiredComponent } from './component/view/preliminary/resetPasswordRequired/ResetPasswordRequiredComponent';
import { OrganizationsEditComponent } from './component/view/main/organizations/edit/OrganizationsEditComponent';
import { OrganizationsCreateComponent } from './component/view/main/organizations/create/OrganizationsCreateComponent';
import { OrganizationsListComponent } from './component/view/main/organizations/OrganizationsListComponent';
import { HelpFAQComponent } from './component/view/main/help/faq/HelpFAQComponent';
import { HelpFAQItemComponent } from './component/view/main/help/faq/HelpFAQItemComponent';
import { HelpComponent } from './component/view/main/help/HelpComponent';
import { HelpTutorialComponent } from './component/view/main/help/HelpTutorialComponent';
import { HelpContactComponent } from './component/view/main/help/HelpContactComponent';
import { ValidPropertiesPipe } from './util/pipe/ValidPropertiesPipe';
import { IsFilePipe } from './util/pipe/IsFilePipe';
import { ChangePhotoComponent } from './component/view/main/account/components/ChangePhotoComponent';
import { AdminModel } from 'app/model/AdminModel';
import { CoachModel } from 'app/model/CoachModel';
import { OrganizationModel } from 'app/model/OrganizationModel';
import { LiveClassModel } from 'app/model/LiveClassModel';
import { SeniorModel } from 'app/model/SeniorModel';
import { CustomUiViewComponent } from 'app/component/ui/customUiView/CustomUiViewComponent';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { LayoutModule } from '@angular/cdk/layout';
import { DashboardComponent } from 'app/component/view/main/dashboard/DashboardComponent';
import { DashboardAdminComponent } from 'app/component/view/main/dashboard/DashboardAdminComponent';
import { ProgramAdminNewComponent } from 'app/component/view/main/onDemand/admin/programs/create/ProgramAdminNewComponent';
import { AddToProgramModalComponent } from 'app/component/view/main/onDemand/admin/programs/components/AddToProgramModalComponent';
import { ProgramAdminDetailsComponent } from 'app/component/view/main/onDemand/admin/programs/details/ProgramAdminDetailsComponent';
import { ProgramAdminEditComponent } from 'app/component/view/main/onDemand/admin/programs/edit/ProgramAdminEditComponent';
import { ProgramAdminListComponent } from 'app/component/view/main/onDemand/admin/programs/ProgramAdminListComponent';
import { ProgramListActionsComponent } from 'app/component/view/main/onDemand/admin/programs/components/cellRender/ProgramListActionsComponent';
import { ProgramListItemComponent } from 'app/component/view/main/onDemand/senior/view/program/component/listItem/ProgramListItemComponent';
import { ProgramItemDetailsModalComponent } from 'app/component/view/main/onDemand/senior/view/program/modal/ProgramItemDetailsModalComponent';
import { OnDemandProgramPlayComponent } from 'app/component/view/main/onDemand/senior/view/program/view/play/OnDemandProgramPlayComponent';
import { ProgramsFilterModalComponent } from 'app/component/view/main/onDemand/common/filters/ProgramsFilterModalComponent';
import { SubscriptionStoreComponent } from 'app/component/SubscriptionStoreComponent';
import { RouteTabsComponent } from './component/ui/routeTabs/RouteTabsComponent';
import { LiveClassSeriesListAdminComponent } from 'app/component/view/main/liveClasses/admin/series/list/LiveClassSeriesListAdminComponent';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { LiveClassSeriesCreateAdminComponent } from 'app/component/view/main/liveClasses/admin/series/create/LiveClassSeriesCreateAdminComponent';
import { DaysFormComponent } from 'app/component/ui/form/days/DaysFormComponent';
import { MustBeEmptyValidator } from 'app/util/validator/MustBeEmptyValidator';
import { LiveClassSeriesEditAdminComponent } from 'app/component/view/main/liveClasses/admin/series/edit/LiveClassSeriesEditAdminComponent';
import { LiveClassSeriesDetailsAdminComponent } from 'app/component/view/main/liveClasses/admin/series/details/LiveClassSeriesDetailsAdminComponent';
import { DaysPipe } from 'app/util/pipe/DaysPipe';
import { LiveClassTypeIconComponent } from './component/view/main/liveClasses/components/typeIcon/LiveClassTypeIconComponent';
import { LiveClassSeriesDecisionModalComponent } from './component/view/main/liveClasses/components/modal/LiveClassSeriesDecisionModalComponent';
import { DataGridStarRatingCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridStarRatingCellRendererComponent';
import { AgGridModule } from 'ag-grid-angular';
import { AvatarComponent } from 'app/component/ui/avatar/AvatarComponent';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RatingFormComponent } from 'app/component/ui/rating/RatingFormComponent';
import { ProgramCoachListTabComponent } from 'app/component/view/main/onDemand/coach/list/tab/ProgramCoachListTabComponent';
import { ProgramCoachListActionsComponent } from 'app/component/view/main/onDemand/coach/list/cellRenderer/ProgramCoachListActionsComponent';
import { ProgramCoachFilterModalComponent } from 'app/component/view/main/onDemand/coach/list/modal/ProgramCoachFilterModalComponent';
import { IncludesPipe } from 'app/util/pipe/IncludesPipe';
import { AccountBillingInfoComponent } from './component/view/main/account/billingInfo/AccountBillingInfoComponent';
import { MembershipPlansModalComponent } from './component/view/main/account/billingInfo/modal/MembershipPlansModalComponent';
import { AccountSubscriptionChangePartialComponent } from './component/view/main/account/billingInfo/partial/AccountSubscriptionChangePartialComponent';
import { SelectedFiltersComponent } from './component/ui/filter/SelectedFiltersComponent';
import { UserFilterComponent } from './component/view/main/user/components/filter/UserFilterComponent';
import { UserSelectedFilterComponent } from './component/view/main/user/components/filter/UserSelectedFilterComponent';
import { LiveClassesUserZoomComponent } from 'app/component/view/main/liveClasses/user/zoom/LiveClassesUserZoomComponent';
import { LiveClassesCoachZoomComponent } from 'app/component/view/main/liveClasses/coach/zoom/LiveClassesCoachZoomComponent';
import { FriendsListComponent } from './component/view/main/friends/view/list/FriendsListComponent';
import { FriendsInvitationsComponent } from './component/view/main/friends/view/invitations/FriendsInvitationsComponent';
import { FriendsSentInvitationsComponent } from './component/view/main/friends/component/sentInvitations/FriendsSentInvitationsComponent';
import { FriendsPendingInvitationsComponent } from './component/view/main/friends/component/pendingInvitations/FriendsPendingInvitationsComponent';
import { AddFriendModalComponent } from './component/view/main/friends/modal/addFriend/AddFriendModalComponent';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MapCollectionPipe } from 'app/util/pipe/MapCollectionPipe';
import { NgxMaskModule } from 'ngx-mask';
import { AttachmentsComponent } from 'app/component/ui/attachments/AttachmentsComponent';
import { LiveClassCapacityPipe } from 'app/util/pipe/LiveClassCapacityPipe';
import { WatchPartyListComponent } from 'app/component/view/main/onDemand/senior/view/watchParty/view/list/WatchPartyListComponent';
import { WatchPartyCreateComponent } from 'app/component/view/main/onDemand/senior/view/watchParty/view/create/WatchPartyCreateComponent';
import { OnDemandAllVideosComponent } from 'app/component/view/main/onDemand/senior/view/video/view/list/OnDemandAllVideosComponent';
import { OnDemandProgramListComponent } from 'app/component/view/main/onDemand/senior/view/program/view/list/OnDemandProgramListComponent';
import { OnDemandVideoCategoryComponent } from 'app/component/view/main/onDemand/senior/component/videoCategory/OnDemandVideoCategoryComponent';
import { OnDemandTabsComponent } from 'app/component/view/main/onDemand/senior/component/tab/OnDemandTabsComponent';
import { OnDemandFilterListComponent } from 'app/component/view/main/onDemand/senior/component/filter/OnDemandFilterListComponent';
import { DateTimePickerComponent } from './component/ui/dateTimePicker/DateTimePickerComponent';
import { SelectFriendComponent } from './component/view/main/friends/component/selectFriend/SelectFriendComponent';
import { WatchPartyEditComponent } from './component/view/main/onDemand/senior/view/watchParty/view/edit/WatchPartyEditComponent';
import { WatchPartyFormComponent } from './component/view/main/onDemand/senior/view/watchParty/component/form/WatchPartyFormComponent';
import { WatchPartyDetailsComponent } from './component/view/main/onDemand/senior/view/watchParty/view/details/WatchPartyDetailsComponent';
import { IntensityLevelComponent } from 'app/component/ui/intensityLevel/IntensityLevelComponent';
import { WatchPartyAvailableListComponent } from './component/view/main/onDemand/senior/view/watchParty/component/availableList/WatchPartyAvailableListComponent';
import { WatchPartyPlannedListComponent } from './component/view/main/onDemand/senior/view/watchParty/component/plannedList/WatchPartyPlannedListComponent';
import { CanPerformActionDirective } from './util/directive/CanPerformActionDirective';
import { WatchPartyEventComponent } from './component/view/main/onDemand/senior/view/watchParty/view/event/WatchPartyEventComponent';
import { WatchPartyDetailsModalComponent } from './component/view/main/onDemand/senior/view/watchParty/modal/WatchPartyDetailsModalComponent';
import { TourWizardModule } from 'ngx-tour-wizard';
import { FullScreenToggleComponent } from 'app/component/ui/fullScreenToggle/FullScreenToggleComponent';
import { ZoomMeetingComponent } from 'app/component/ui/zoomMeeting/ZoomMeetingComponent';
import { TierPipe } from 'app/util/pipe/TierPipe';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

const uiComponents: any[] = [
  AvatarComponent,
  AttachmentsComponent,
  CustomUiViewComponent,
  SelectedFiltersComponent,
  DataGridBooleanCellRendererComponent,
  DataGridComponent,
  DataGridEmailCellRendererComponent,
  DataGridFileCellRendererComponent,
  DataGridStarRatingCellRendererComponent,
  DataGridTemplateCellRendererComponent,
  DynamicInputComponent,
  FileUploadPopupComponent,
  FormCustomComponent,
  FormNestedComponent,
  FullScreenToggleComponent,
  InternalUrlBrowserComponent,
  InternalUrlBrowserPopupComponent,
  PageHeaderComponent,
  PopupAlertComponent,
  PopupConfirmationComponent,
  ResponsiveDataGridComponent,
  ResponsiveDataGridDynamicElementComponent,
  RatingFormComponent,
  RatingStarsComponent,
  TabsComponent,
  RouteTabsComponent,
  DaysFormComponent,
  ThumbnailAndFilesUploadComponent,
  IntensityLevelComponent,
  DateTimePickerComponent,
  ZoomCompleteViewComponent,
  ZoomMeetingComponent
];

const layoutComponents: any[] = [
  ContentAndImageColumnLayoutComponent,
  MainLayoutComponent,
  PreliminaryLayoutComponent,
  RawLayoutComponent
];

const authComponents: any[] = [
  FirstLoginPreferencesComponent,
  HowDoesItWorkComponent,
  LoginComponent,
  RegistrationBrandedStartComponent,
  RegistrationBrandedUserDataComponent,
  RegistrationCompleteComponent,
  RegistrationConfirmComponent,
  RegistrationPasswordComponent,
  RegistrationStartComponent,
  RegistrationUserNotFoundComponent,
  ResetPasswordCompleteComponent,
  ResetPasswordStartComponent,
  ResetPasswordRequiredComponent,
  VerificationCodeComponent,
  WithoutVerificationComponent
];

const errorComponents: any[] = [
  ErrorAccessDeniedComponent,
  ErrorNotFoundComponent
];

const accountComponents: any[] = [
  AccountChangePasswordComponent,
  AccountDetailsComponent,
  AccountDetailsPreferencesComponent,
  AccountDetailsProfileComponent,
  AccountReferFriendComponent,
  ChangePhotoComponent,
  AccountBillingInfoComponent,
  MembershipPlansModalComponent,
  AccountSubscriptionChangePartialComponent
];

const coachComponents: any[] = [
  CoachesCreateComponent,
  CoachesDetailsComponent,
  CoachesEditComponent,
  CoachesListComponent,
  CoachesListActionCellRendererComponent,
  CoachesSelectedActionBarComponent,
  RatingComponent
];

const userComponents: any[] = [
  MyScheduleUserComponent,
  UserCreateComponent,
  UserDetailsComponent,
  UserDetailsProfileComponent,
  UserEditComponent,
  UserListActionsCellRendererComponent,
  UserListComponent,
  UsersSelectedActionBarComponent,
  UserFilterComponent,
  UserSelectedFilterComponent
];

const dashboardComponents: any[] = [
  DashboardComponent,
  DashboardAdminComponent,
  DashboardCoachComponent,
  DashboardSeniorComponent,
  DashboardSeniorHappeningNowComponent,
  DashboardSeniorRecommendedComponent,
  DashboardSeniorAllRecommendedModalComponent
];

const helpComponents: any[] = [
  HelpComponent,
  HelpFAQComponent,
  HelpFAQItemComponent,
  HelpContactComponent,
  HelpTutorialComponent
];

const liveClassComponents: any[] = [
  CoachDetailsComponent,
  LiveClassesFilterComponent,
  IntensityCellRendererComponent,
  JoinUserComponent,
  LiveClassSingleListAdminComponent,
  LiveClassSeriesListAdminComponent,
  LiveClassSingleCreateAdminComponent,
  LiveClassSeriesCreateAdminComponent,
  LiveClassSingleDetailsAdminComponent,
  LiveClassSeriesDetailsAdminComponent,
  LiveClassSingleEditAdminComponent,
  LiveClassSeriesEditAdminComponent,
  LiveClassDetailsCoachComponent,
  LiveClassDetailsUserComponent,
  LiveClassesListActionCellRendererComponent,
  LiveClassesListCoachCellRendererComponent,
  LiveClassesListCoachComponent,
  LiveClassesListUserComponent,
  LiveClassesSelectedActionBarComponent,
  LiveClassSeriesDecisionModalComponent,
  LiveClassTypeIconComponent,
  LiveClassesUserZoomComponent,
  LiveClassesCoachZoomComponent,
  ThankYouComponent,
  UsersCapacityCellRendererComponent
];

const myActivityComponents: any[] = [
  AllBadgesComponent,
  BadgeTemplateComponent,
  MyActivityComponent,
  ProgressionBarComponent,
  RecentActivitiesToRateComponent
];

const notificationComponents: any[] = [
  NotificationsComponent
];

const onDemandVideoComponents: any[] = [
  AddToProgramModalComponent,
  OnDemandCoachListComponent,
  OnDemandVideoFilterModalComponent,
  OnDemandVideosAdminDetailsComponent,
  OnDemandVideosAdminEditComponent,
  OnDemandVideosAdminListComponent,
  OnDemandVideosAdminNewComponent,
  OnDemandVideosBulkActionsComponent,
  OnDemandVideosDetailsModalComponent,
  OnDemandVideosItemComponent,
  OnDemandVideosListAdminActionCellRendererComponent,
  OnDemandVideosListCoachActionCellRendererComponent,
  OnDemandVideosPlayVideoComponent,
  OnDemandVideosUploadToVimeoComponent,
  ProgramAdminDetailsComponent,
  ProgramAdminEditComponent,
  ProgramAdminListComponent,
  ProgramAdminNewComponent,
  ProgramCoachFilterModalComponent,
  ProgramCoachListActionsComponent,
  ProgramCoachListTabComponent,
  ProgramItemDetailsModalComponent,
  ProgramListActionsComponent,
  ProgramListItemComponent,
  OnDemandProgramPlayComponent,
  ProgramsFilterModalComponent,
  OnDemandAllVideosComponent,
  OnDemandProgramListComponent,
  OnDemandVideoCategoryComponent,
  OnDemandTabsComponent,
  OnDemandFilterListComponent,
];

const organizationComponents: any[] = [
  OrganizationsCreateComponent,
  OrganizationsDetailsComponent,
  OrganizationsEditComponent,
  OrganizationsListComponent
];

const watchPartyComponents: any[] = [
  WatchPartyListComponent,
  WatchPartyCreateComponent,
  WatchPartyEditComponent,
  WatchPartyFormComponent,
  WatchPartyDetailsComponent,
  WatchPartyDetailsModalComponent,
  WatchPartyAvailableListComponent,
  WatchPartyPlannedListComponent,
  WatchPartyEventComponent
];

const friendComponents: any[] = [
  FriendsListComponent,
  FriendsInvitationsComponent,
  FriendsSentInvitationsComponent,
  FriendsPendingInvitationsComponent,
  AddFriendModalComponent,
  SelectFriendComponent
];

const components: any[] = [
  ApplicationComponent,
  AbstractStateComponent,
  SubscriptionStoreComponent,
  ...uiComponents,
  ...layoutComponents,
  ...authComponents,
  ...errorComponents,
  ...accountComponents,
  ...coachComponents,
  ...userComponents,
  ...dashboardComponents,
  ...helpComponents,
  ...liveClassComponents,
  ...myActivityComponents,
  ...notificationComponents,
  ...onDemandVideoComponents,
  ...organizationComponents,
  ...watchPartyComponents,
  ...friendComponents,
];

const directives: any[] = [
  InputCheckBoxArrayValueAccessorDirective,
  InputFileValueAccessorDirective,
  InputMultipleFileValueAccessorDirective,
  InputNoSpaceDirective,
  InputNumberOnlyDirective,
  InputPasswordToggleDirective,
  InputPositiveIntegerOnlyDirective,
  InputToIntegerDirective,
  InputToNumberDirective,
  RepeatElementDirective,
  CanPerformActionDirective
];

const pipes: any[] = [
  ArrayJoinPipe,
  MapCollectionPipe,
  BirthDateToAgePipe,
  BooleanPipe,
  LiveClassCapacityPipe,
  DaysPipe,
  ConsolePipe,
  DateNowRelationPipe,
  DistanceFormatPipe,
  DurationFormatPipe,
  FileIdLinkPipe,
  FileSizePipe,
  FileUrlLinkPipe,
  FullNamePipe,
  IncludesPipe,
  TierPipe,
  IsFilePipe,
  MinutesToTimePipe,
  Nl2BrPipe,
  PrefixPipe,
  ReplaceEmptyPipe,
  SanitizeHtmlPipe,
  SanitizeStylePipe,
  SanitizeUrlPipe,
  SplitPipe,
  SuffixPipe,
  TimeAgoPipe,
  UnescapePipe,
  ValidPropertiesPipe
];

const validators: any[] = [
  AgeOver18Validator,
  CheckboxArrayRequiredValidator,
  FileAllowedExtensionsValidator,
  FileMaxSizeValidator,
  FileRequiredValidator,
  MaxDateTimeValidator,
  MaxDateValidator,
  MaxLengthValidator,
  MemberCodeValidator,
  MinDateTimeValidator,
  MustBeEmptyValidator,
  MinDateValidator,
  PasswordRequirementsValidator,
  ReasonableBirthdateValidator
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...pipes,
    ...validators,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UIRouterModule.forRoot({ config: RouterConfig, states: StateConfig, useHash: false }),
    NgValidatorsModule,
    NgPipesModule,
    TranslateModule.forRoot(TranslateConfig),
    NgxTranslateCutModule,
    TooltipModule.forRoot(),
    NgxUiLoaderModule.forRoot({
      fgsColor: '#407417',
      fgsPosition: 'center-center',
      fgsSize: 70,
      fgsType: 'cube-grid',
      blur: 5,
      delay: 0,
      fastFadeOut: true,
      hasProgressBar: false,
      overlayBorderRadius: '0',
      overlayColor: 'rgba(0,0,0,0.3)'
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      extendedTimeOut: moment.duration(3, 'seconds').as('milliseconds'),
      enableHtml: true,
      closeButton: true
    }),
    NgxFilesizeModule,
    AngularSvgIconModule.forRoot(),
    NgSelectModule,
    ButtonsModule.forRoot(), ModalModule.forRoot(), TabsModule.forRoot(), PaginationModule.forRoot(), ProgressbarModule.forRoot(),
    BsDatepickerModule.forRoot(), TimepickerModule.forRoot(), BsDropdownModule.forRoot(),
    NgxSliderModule,
    FileUploadModule,
    ChartsModule,
    StorageModule.forRoot(StorageConfig),
    AgGridModule.withComponents([]),
    ReactiveFormsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    LayoutModule,
    InfiniteScrollModule,
    CollapseModule.forRoot(),
    NgxMaskModule.forRoot({ validation: false }),
    TourWizardModule
  ],
  providers: [
    DatePipe,
    //{ provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: NgSelectConfig, useValue: { disableVirtualScroll: true, openOnEnter: false } },
    { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: [ LanguageVersion.EN, LanguageVersion.PL ] } },
    { provide: LOCALE_ID, useValue: ApplicationConfig.defaultLanguage },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: SuppressInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: LoadingInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: TimezoneInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AppLanguageInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AppTypeInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AppVersionInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AuthInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: ErrorInterceptor },
    {
      provide: APP_INITIALIZER, multi: true,
      useFactory: (
        _applicationModel: ApplicationModel,
        _adminModel: AdminModel,
        _authModel: AuthModel,
        _coachModel: CoachModel,
        _fileModel: FileModel,
        _onDemandVideoModel: OnDemandVideoModel,
        _organizationModel: OrganizationModel,
        _liveClassModel: LiveClassModel,
        _seniorModel: SeniorModel,
        _userModel: UserModel,
        _userRegistrationModel: UserRegistrationModel,
        _application: Application
      ) => (): void => {},
      deps: [
        ApplicationModel,
        AdminModel,
        AuthModel,
        CoachModel,
        FileModel,
        OnDemandVideoModel,
        OrganizationModel,
        LiveClassModel,
        SeniorModel,
        UserModel,
        UserRegistrationModel,
        Application
      ]
    }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [ ApplicationComponent ]
})
export class ApplicationModule {}

import { Injectable, Pipe, PipeTransform } from '@angular/core';

// {{ STUFF | boolean | translate }}

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'boolean' })
export class BooleanPipe implements PipeTransform {

  public transform(value: any, trueText: string = 'COMMON.YES', falseText: string = 'COMMON.NO'): any {
    if ((value === undefined) || (value === null) || (value === '')) {
      return null;
    }
    else {
      if (value === true) {
        return trueText;
      }
      else {
        return falseText;
      }
    }
  }
}

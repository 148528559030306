import { Transform, Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '../IdentifiableObjectDTO';
import { DateUtil } from 'app/util/DateUtil';
import { NotificationPriority } from 'app/data/enum/notifications/NotificationPriority';
import { NotificationScheduledLiveClassDTO } from './NotificationScheduledLiveClassDTO';
import { NotificationType } from 'app/data/dto/notifications/NotificationType';

export class NotificationDTO extends IdentifiableObjectDTO<number> {
  public title: string;

  public content: string;

  public priority: NotificationPriority;

  public read: boolean;

  public type: NotificationType;

  @Type(() => NotificationScheduledLiveClassDTO)
  public scheduledLiveClass?: NotificationScheduledLiveClassDTO;

  public watchPartyId?: number;

  @Transform(DateUtil.dateTimeConversion)
  public createdDate: Date;
}

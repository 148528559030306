import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { SecurityDataDTO } from 'app/data/dto/auth/SecurityDataDTO';
import { SeniorUpdateRequestDTO } from 'app/data/dto/senior/SeniorUpdateRequestDTO';
import { SeniorProfileResponseAdminDTO } from 'app/data/dto/senior/SeniorProfileResponseAdminDTO';
import { CurrentUserProfileDTO } from 'app/data/dto/user/CurrentUserProfileDTO';
import { TutorialType } from 'app/data/dto/user/TutorialType';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { UserProfileDTO } from 'app/data/dto/user/UserProfileDTO';
import { UserServiceInterface } from 'app/service/interface/UserServiceInterface';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegistrationType } from 'app/data/enum/user/RegistrationType';
import { UserDetailsDTO } from 'app/data/dto/user/UserDetailsDTO';

@Injectable({ providedIn: 'root' })
export class UserService implements UserServiceInterface {
  constructor(private http: HttpClient) {
  }

  public getCurrentUser(): Observable<{ user: UserDTO; securityData: SecurityDataDTO }> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/users/me`).pipe(
      map((response: UserDTO) => {
        const user: UserDTO = ObjectUtil.plainToClass(UserDTO, response);
        return { user: user, securityData: undefined };
      })
    );
  }

  public createUser(user: SeniorUpdateRequestDTO): Observable<SeniorProfileResponseAdminDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/seniors`, ObjectUtil.classToPlain(user)).pipe(
      map((response: any) => {
        const createdUser: SeniorProfileResponseAdminDTO = ObjectUtil.plainToClass(
          SeniorProfileResponseAdminDTO,
          response
        );
        return createdUser;
      })
    );
  }

  public setUserEnabledStatus(userId: number, enabled: boolean): Observable<UserDTO> {
    const request: {
      enabled: boolean;
    } = {
      enabled: enabled
    };

    return this.http.put(`${ ApplicationConfig.apiUrl }/users/${ userId }/enabled`, request).pipe(
      map((response: any) => {
        const user: UserDTO = ObjectUtil.plainToClass(UserDTO, response);
        return user;
      })
    );
  }

  // not currently implemented on backend
  public adminResetPassword(userId: number): Observable<void> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/users/${ userId }/password`, null).pipe(
      map((_response: any) => {
        return;
      })
    );
  }

  public getCurrentUserProfile(): Observable<UserProfileDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/users/me/profile`).pipe(
      map((response: any) => {
        return ObjectUtil.plainToClass(UserProfileDTO, response);
      })
    );
  }

  public updateCurrentUserProfile(user: CurrentUserProfileDTO): Observable<UserProfileDTO> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/users/me/profile`, ObjectUtil.classToPlain(user)).pipe(
      map((response: any) => {
        return ObjectUtil.plainToClass(UserProfileDTO, response);
      })
    );
  }

  public resendConfirmationCode(username: string, registrationType: RegistrationType): Observable<unknown> {
    return this.http.post(
      `${ ApplicationConfig.apiUrl }/users/${ encodeURIComponent(username) }/confirmationMessageResend`,
      { registrationType: registrationType }
    );
  }

  public setCurrentUserTutorialCompletion(value: TutorialType): Observable<UserDetailsDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/users/me/complete-tutorial`, { completedTutorial: value }).pipe(
      map((response: any) => {
        return ObjectUtil.plainToClass(UserDetailsDTO, response);
      })
    );
  }
}

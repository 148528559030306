import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({ providedIn: 'root' })
export class EventManager implements OnDestroy {

  private listeners: { [key: string]: any } = {};
  private eventBus: Subject<{ name: string, args: Array<any> }> = new Subject();
  private eventBusSubscription: Subscription;

  constructor() {
    this.eventBusSubscription = this.eventBus.subscribe(({ name, args }) => {
      if (this.listeners[name]) {
        for (const listener of this.listeners[name]) {
          listener(...args);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.listeners = {};
    this.eventBusSubscription.unsubscribe();
    this.eventBus.complete();
  }

  public on(name: string, listener: any): void {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }

    this.listeners[name].push(listener);
  }

  public broadcast(name: string, ...args: Array<any>): void {
    this.eventBus.next({ name, args });
  }

  public destroyListener(name: string, listener: any): void {
    if (this.listeners[name]?.indexOf(listener) > -1) {
      this.listeners[name].splice(this.listeners[name].indexOf(listener), 1);
    }
  }
}


import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-internal-url-browser',
  templateUrl: 'InternalUrlBrowserComponent.html',
  styleUrls: [ 'InternalUrlBrowserComponent.scss' ]
})
export class InternalUrlBrowserComponent implements OnChanges {

  @Input()
  public url: string;

  public desanitizedURI: string;

  constructor(public sanitizer: DomSanitizer) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.url?.currentValue) {
      this.desanitizedURI = this.sanitizer.bypassSecurityTrustResourceUrl(changes.url.currentValue) as string;
    }
  }

}

<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.LIVE_CLASSES.CREATE' | translate }}</h1>

  <div class="d-flex flex-wrap gap-4">
    <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
      <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>

    <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="uploadStarted">
      <span *ngIf="uploadStarted" class="loader-white"></span>
      <span>{{ 'COMMON.SAVE' | translate }}</span>
    </button>
  </div>
</ng-template>

<section class="section">
  <form #form="ngForm" (keyup.enter)="submit()">
    <div class="row mb-4">
      <div class="form-group col-12 col-lg-9">
        <label for="title">{{ 'VIEW.MAIN.LIVE_CLASSES.TITLE'| translate }}</label>
        <input
          type="text"
          id="title"
          name="title"
          #title="ngModel"
          class="form-control"
          placeholder="{{'VIEW.MAIN.LIVE_CLASSES.TITLE' | translate}}"
          [(ngModel)]="scheduledLiveClass.title"
          required
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_128"
          [ngClass]="{'is-invalid': form.submitted && title.invalid, 'is-valid': form.submitted && title.valid }"
        />
        <div class="invalid-feedback" *ngIf="form.submitted && title.invalid">
          <div *ngIf="title.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="title.errors['maxLength']">
            {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_128} }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="form-group col-12 col-lg-3">
        <label for="date">{{ 'VIEW.MAIN.LIVE_CLASSES.DATE' | translate }}</label>
        <div id="iconbox" class="form-group mb-0">
          <svg-icon class="search-icon" src="asset/image/icon/search.svg"></svg-icon>
          <input
            type="text"
            id="date"
            name="date"
            #date="ngModel"
            class="form-control date-icon"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.DATE' | translate}}"
            [ngClass]="{'is-invalid': form.submitted && date.invalid, 'is-valid': form.submitted && date.valid }"
            [(ngModel)]="scheduledLiveClass.startDate"
            required
            [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-custom',minDate: minDateOfCreateLiveClass }"
            [appMinDateTime]="minDateOfCreateLiveClass"
            (ngModelChange)="onDateChange()"
            bsDatepicker
          />
          <div class="invalid-feedback" *ngIf="form.submitted && date.invalid">
            <div *ngIf="date.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="date.errors['minDate']">
              {{ 'VALIDATION.DATE_MAX' | translate: {dateString: minDateOfCreateLiveClass.toDateString()} }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group col-12 col-lg-3">
        <label for="time">{{ 'VIEW.MAIN.LIVE_CLASSES.TIME' | translate }}</label>
        <timepicker
          id="time"
          name="time"
          #time="ngModel"
          [(ngModel)]="scheduledLiveClass.startDate"
          [ngClass]="{'is-invalid': form.submitted && time.invalid, 'is-valid': form.submitted && time.valid }"
          [minuteStep]="1"
          [showSpinners]="false"
          [showMeridian]="true"
          [mousewheel]="true"
          [appMinDateTime]="minDateOfCreateLiveClass"
          (ngModelChange)="validateTime(time)"
          required
        ></timepicker>
        <div class="invalid-feedback" *ngIf="form.submitted && time.invalid">
          <div *ngIf="form.controls.time.errors?.['minDateTime']">
            {{ 'VALIDATION.TIME_CANT_BE_IN_THE_PAST' | translate }}
          </div>
          <div *ngIf="time.errors?.['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
      <div class="form-group col-12 col-lg-3">
        <!-- <label for="frequency">{{'VIEW.MAIN.LIVE_CLASSES.FREQUENCY' | translate }}</label>
        <ng-select
          id="frequency"
          name="frequency"
          #frequency="ngModel"
          placeholder="{{'VIEW.MAIN.LIVE_CLASSES.FREQUENCY' | translate}}"
          [searchable]="false"
          [ngClass]="{'is-invalid': form.submitted && frequency.invalid, 'is-valid': form.submitted && frequency.valid }"
          [(ngModel)]="scheduledLiveClass.cyclical"
          required
        >
          <ng-option *ngFor="let frequency of frequencies" [value]="frequency.value === 'CYCLICAL' ? true : null"
            >{{ frequency.label | translate }}
          </ng-option>
        </ng-select> -->
      </div>
    </div>
    <div class="row mb-4">
      <div class="d-flex flex-column flex-lg-row gap-lg-12 col-12 col-lg-9">
        <div class="form-group w-100">
          <label for="coach">{{ 'VIEW.MAIN.LIVE_CLASSES.COACH' | translate }}</label>
          <ng-select
            id="coach"
            name="coach"
            #coach="ngModel"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.CHOOSE_A_COACH' | translate}}"
            [searchable]="false"
            [ngClass]="{'is-invalid': form.submitted && coach.invalid, 'is-valid': form.submitted && coach.valid }"
            [(ngModel)]="scheduledLiveClass.coachIds"
            [multiple]="true"
            required
          >
            <ng-option *ngFor="let coach of coaches" [value]="coach.value"
            >{{ coach.label | translate }}
            </ng-option>
          </ng-select>
          <div class="invalid-feedback" *ngIf="form.submitted && coach.invalid">
            <div *ngIf="coach.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>

        <div class="form-group w-100">
          <label for="embed">{{ 'VIEW.MAIN.LIVE_CLASSES.EMBED' | translate }}</label>
          <input
            type="text"
            id="embed"
            name="embed"
            #embed="ngModel"
            class="form-control"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.PLACE_EMBED_LINK_HERE' | translate}}"
            [(ngModel)]="scheduledLiveClass.url"
            required
            [appMaxLength]="Constant.TEXT_MAX_LENGTH_255"
            [ngClass]="{'is-invalid': form.submitted && embed.invalid, 'is-valid': form.submitted && embed.valid }"
          />
          <div class="invalid-feedback" *ngIf="form.submitted && embed.invalid">
            <div *ngIf="embed.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            <div *ngIf="embed.errors['maxLength']">
              {{ 'VALIDATION.CHAR_MAX' | translate: {max: Constant.TEXT_MAX_LENGTH_255} }}
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="row mb-4">
      <div class="d-flex flex-column flex-lg-row gap-lg-12 col-12 col-lg-9">
        <div class="form-group w-100">
          <label for="intensity">{{ 'VIEW.MAIN.LIVE_CLASSES.INTENSITY' | translate }}</label>
          <ng-select
            id="intensity"
            name="intensity"
            #intensity="ngModel"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.CHOSE_THE_INTENSITY_LEVEL' | translate}}"
            [searchable]="false"
            [ngClass]="{'is-invalid': form.submitted && intensity.invalid, 'is-valid': form.submitted && intensity.valid }"
            [(ngModel)]="scheduledLiveClass.intensity"
            required
          >
            <ng-option *ngFor="let intensity of intensities" [value]="intensity.value"
            >{{ intensity.label | translate }}
            </ng-option>
          </ng-select>
          <div class="invalid-feedback" *ngIf="form.submitted && intensity.invalid">
            <div *ngIf="intensity.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
        <div class="form-group w-100">
          <label for="classLength">{{ 'VIEW.MAIN.LIVE_CLASSES.LENGTH' | translate }}</label>
          <ng-select
            id="classLength"
            name="classLength"
            #classLength="ngModel"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.CHOOSE_CLASS_LENGTH' | translate}}"
            [searchable]="false"
            [ngClass]="{'is-invalid': form.submitted && classLength.invalid, 'is-valid': form.submitted && classLength.valid }"
            [(ngModel)]="scheduledLiveClass.duration"
            required
          >
            <ng-option *ngFor="let length of lengths" [value]="length.value">{{ length.value }}</ng-option>
          </ng-select>
          <div class="invalid-feedback" *ngIf="form.submitted && classLength.invalid">
            <div *ngIf="classLength.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="d-flex flex-column flex-lg-row gap-lg-12 col-12 col-lg-9">
        <div class="form-group w-100">
          <label for="language">{{ 'VIEW.MAIN.LIVE_CLASSES.LANGUAGE' | translate }}</label>
          <ng-select
            id="language"
            name="language"
            #language="ngModel"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.CHOOSE_A_LANGUAGE' | translate}}"
            [searchable]="false"
            [ngClass]="{'is-invalid': form.submitted && language.invalid, 'is-valid': form.submitted && language.valid }"
            [(ngModel)]="scheduledLiveClass.language"
            required
          >
            <ng-option *ngFor="let language of languages" [value]="language.value"
            >{{ language.label | translate }}
            </ng-option>
          </ng-select>
          <div class="invalid-feedback" *ngIf="form.submitted && language.invalid">
            <div *ngIf="language.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
        <div class="form-group w-100">
          <label for="capacity">{{ 'VIEW.MAIN.LIVE_CLASSES.CAPACITY' | translate }}</label>
          <ng-select
            id="capacity"
            name="capacity"
            #capacity="ngModel"
            placeholder="{{ 'VIEW.MAIN.LIVE_CLASSES.NUMBER_OF_SEATS_AVAILABLE' | translate }}"
            [searchable]="false"
            [ngClass]="{'is-invalid': form.submitted && capacity.invalid, 'is-valid': form.submitted && capacity.valid }"
            [(ngModel)]="scheduledLiveClass.capacity"
          >
            <ng-option *ngFor="let capacity of capacities" [value]="capacity.value">
              {{ capacity.label | translate }}
            </ng-option>
          </ng-select>
          <div class="text-sm-light color-orange ml-4 mt-1">* {{ 'COMMON.OPTIONAL' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="d-flex flex-column flex-lg-row gap-lg-12 col-12 col-lg-9">
        <div class="form-group w-100">
          <label for="category">{{ 'VIEW.MAIN.LIVE_CLASSES.CATEGORY' | translate }}</label>
          <ng-select
            id="category"
            name="category"
            #category="ngModel"
            placeholder="{{'VIEW.MAIN.LIVE_CLASSES.CHOOSE_A_CATEGORY' | translate}}"
            [searchable]="false"
            [ngClass]="{'is-invalid': form.submitted && category.invalid, 'is-valid': form.submitted && category.valid }"
            [(ngModel)]="scheduledLiveClass.category"
            required
          >
            <ng-option *ngFor="let category of categories" [value]="category.value"
            >{{ category.label | translate }}
            </ng-option>
          </ng-select>
          <div class="invalid-feedback" *ngIf="form.submitted && category.invalid">
            <div *ngIf="category.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
        <div class="w-100"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-9 mb-4">
        <div class="form-group">
          <label for="description">{{ 'VIEW.MAIN.LIVE_CLASSES.DESCRIPTION' | translate }}</label>
          <textarea
            id="description"
            name="description"
            #description="ngModel"
            class="form-control"
            placeholder="Text"
            rows="5"
            [maxlength]="Constant.TEXT_MAX_LENGTH_512"
            [ngClass]="{'is-invalid': form.submitted && description.invalid, 'is-valid': form.submitted && description.valid }"
            [(ngModel)]="scheduledLiveClass.description"
            required
          ></textarea>
          <div class="invalid-feedback" *ngIf="form.submitted && description.invalid">
            <div *ngIf="description.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
          <div class="d-flex justify-content-end">
            {{ description.value ? description.value.length : 0 }}/{{ Constant.TEXT_MAX_LENGTH_512 }}
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-9 mb-4">
        <app-dynamic-input
          [label]="'VIEW.MAIN.LIVE_CLASSES.RECOMMENDED_EQUIPMENT' | translate"
          [items]="scheduledLiveClass.scheduledLiveClassNeeds"
          keyName="need"
          [addButtonText]="'VIEW.MAIN.LIVE_CLASSES.ADD_NEEDS' | translate"
          [placeholder]="'VIEW.MAIN.LIVE_CLASSES.ADD_NEEDS' | translate"
          (updatedItems)="handleUpdatedNeeds($event)"
          [appMaxLength]="Constant.TEXT_MAX_LENGTH_128"
          [formSubmitted]="form.submitted"
        ></app-dynamic-input>
        <app-thumbnail-and-files-upload
          [thumbnailPlaceholder]="'VIEW.MAIN.LIVE_CLASSES.THUMBNAIL' | translate"
          [thumbnailMode]="true"
          [attachmentMode]="true"
          (startUpload)="handleStartUpload($event)"
          (imageId)="handleImageId($event)"
          (attachmentIds)="handleAttachmentIds($event)"
          [formSubmitted]="form.submitted"
          [isThumbnailRequired]="true"
          (thumbnailFormStatus)="handleThumbnailFormStatus($event)"
        ></app-thumbnail-and-files-upload>
      </div>
    </div>
  </form>
</section>
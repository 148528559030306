import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { PageDTO } from 'app/data/dto/PageDTO';
import { ScheduledLiveClassSeniorPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassSeniorPageCriteriaDTO';
import { ScheduledLiveClassAttachmentCreateRequestDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassAttachmentCreateRequestDTO';
import { ScheduledLiveClassAttachmentResponseDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassAttachmentResponseDTO';
import { ScheduledLiveClassCoachPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachPageCriteriaDTO';
import { ScheduledLiveClassIdRequestDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassIdRequestDTO';
import { ScheduledLiveClassRatingRequestDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassRatingRequestDTO';
import { ScheduledLiveClassRatingResponseDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassRatingResponseDTO';
import { ScheduledLiveClassUnratedResponseDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassUnratedResponseDTO';
import { ScheduledLiveClassPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassPageCriteriaDTO';
import { ScheduledLiveClassCreateRequestDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassCreateRequestDTO';
import { ScheduledLiveClassDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsPageDTO';
import { ScheduledLiveClassDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassDetailsResponseDTO';
import { ScheduledLiveClassUpdateRequestDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassUpdateRequestDTO';
import { ScheduledLiveClassCoachDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachDetailsResponseDTO';
import { ScheduledLiveClassCoachDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassCoachDetailsPageDTO';
import { ScheduledLiveClassJoiningResponseDTO } from 'app/data/dto/scheduledLiveClass/coach/ScheduledLiveClassJoiningResponseDTO';
import { ScheduledLiveClassSeniorDetailsPageDTO } from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsPageDTO';
import { ScheduledLiveClassSeniorDetailsResponseDTO } from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsResponseDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { CustomHttpParameterEncoder } from 'app/util/other/CustomHttpParameterEncoder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlatformDataRequestDTO } from 'app/data/dto/PlatformDataRequestDTO';
import { ScheduledLiveClassEnrollmentRequestDTO } from 'app/data/dto/scheduledLiveClass/ScheduledLiveClassEnrollmentRequestDto';
import { ScheduledLiveClassRatingAdminPageCriteriaDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassRatingAdminPageCriteriaDTO';
import { ScheduledLiveClassRatingAdminPageDTO } from 'app/data/dto/scheduledLiveClass/admin/ScheduledLiveClassRatingAdminPageDTO';
import { ScheduledLiveClassInterface } from 'app/service/interface/ScheduledLiveClassInterface';

@Injectable({
  providedIn: 'root'
})
export class ScheduledLiveClassService implements ScheduledLiveClassInterface {
  constructor(private http: HttpClient) {
  }

  public getScheduledLiveClassesPageUser(
    criteria: ScheduledLiveClassSeniorPageCriteriaDTO
  ): Observable<PageDTO<ScheduledLiveClassSeniorDetailsPageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/seniors/page`, { params: params }).pipe(
      map((response: any) => {
        const page: PageDTO<ScheduledLiveClassSeniorDetailsPageDTO> = ObjectUtil.plainToClassFromExisting(
          new PageDTO<ScheduledLiveClassSeniorDetailsPageDTO>(ScheduledLiveClassSeniorDetailsPageDTO),
          response
        );
        return page;
      })
    );
  }

  public getScheduledLiveClassesPageAdmin(
    criteria: ScheduledLiveClassPageCriteriaDTO
  ): Observable<PageDTO<ScheduledLiveClassDetailsPageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });
    return this.http.get(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/admins/page`, { params: params }).pipe(
      map((response: any) => {
        const page: PageDTO<ScheduledLiveClassDetailsPageDTO> = ObjectUtil.plainToClassFromExisting(
          new PageDTO<ScheduledLiveClassDetailsPageDTO>(ScheduledLiveClassDetailsPageDTO),
          response
        );
        return page;
      })
    );
  }

  public getScheduledLiveClassesPageCoach(
    criteria: ScheduledLiveClassCoachPageCriteriaDTO
  ): Observable<PageDTO<ScheduledLiveClassCoachDetailsPageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });
    return this.http.get(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/coaches/page`, { params: params }).pipe(
      map((response: any) => {
        const page: PageDTO<ScheduledLiveClassCoachDetailsPageDTO> = ObjectUtil.plainToClassFromExisting(
          new PageDTO<ScheduledLiveClassCoachDetailsPageDTO>(ScheduledLiveClassCoachDetailsPageDTO),
          response
        );
        return page;
      })
    );
  }

  public createScheduledLiveClassAdmin(
    scheduledLiveClass: ScheduledLiveClassCreateRequestDTO
  ): Observable<ScheduledLiveClassDetailsResponseDTO> {
    return this.http
      .post(`${ ApplicationConfig.apiUrl }/scheduledliveclasses`, ObjectUtil.classToPlain(scheduledLiveClass))
      .pipe(
        map((response: any) => {
          const createdScheduledLiveClass: ScheduledLiveClassDetailsResponseDTO = ObjectUtil.plainToClass(
            ScheduledLiveClassDetailsResponseDTO,
            response
          );
          return createdScheduledLiveClass;
        })
      );
  }

  public getScheduledLiveClassDetailsAdmin(id: number): Observable<ScheduledLiveClassDetailsResponseDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ id }/admins/details`).pipe(
      map((response: any) => {
        const scheduledLiveClass: ScheduledLiveClassDetailsResponseDTO = ObjectUtil.plainToClass(
          ScheduledLiveClassDetailsResponseDTO,
          response
        );
        return scheduledLiveClass;
      })
    );
  }

  public getScheduledLiveClassDetailsUser(id: number): Observable<ScheduledLiveClassSeniorDetailsResponseDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ id }/seniors/details`).pipe(
      map((response: any) => {
        const scheduledLiveClass: ScheduledLiveClassSeniorDetailsResponseDTO = ObjectUtil.plainToClass(
          ScheduledLiveClassSeniorDetailsResponseDTO,
          response
        );
        return scheduledLiveClass;
      })
    );
  }

  public getScheduledLiveClassDetailsCoach(id: number): Observable<ScheduledLiveClassCoachDetailsResponseDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ id }/coaches/details`).pipe(
      map((response: any) => {
        const scheduledLiveClass: ScheduledLiveClassCoachDetailsResponseDTO = ObjectUtil.plainToClass(
          ScheduledLiveClassCoachDetailsResponseDTO,
          response
        );
        return scheduledLiveClass;
      })
    );
  }

  public registerToScheduledLiveClasses(enrollmentRequest: ScheduledLiveClassEnrollmentRequestDTO): Observable<void> {
    return this.http
      .post(
        `${ ApplicationConfig.apiUrl }/scheduledliveclasses/me/enroll`,
        ObjectUtil.classToPlain(enrollmentRequest)
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public registerToLiveClassSeries(enrollmentRequest: ScheduledLiveClassEnrollmentRequestDTO): Observable<void> {
    return this.http
      .post(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/me/enrollSeries`, ObjectUtil.classToPlain(enrollmentRequest))
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public joinScheduledLiveClasses(id: number, platformData: PlatformDataRequestDTO): Observable<ScheduledLiveClassJoiningResponseDTO> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ id }/me/join`, ObjectUtil.classToPlain(platformData))
      .pipe(
        map((response: any) => {
          const scheduledLiveClass: ScheduledLiveClassJoiningResponseDTO = ObjectUtil.plainToClass(
            ScheduledLiveClassJoiningResponseDTO,
            response
          );
          return scheduledLiveClass;
        })
      );
  }

  public joinScheduledLiveClassesCoach(id: number): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ id }/coaches/join`, undefined);
  }

  public finishScheduledLiveClassesCoach(id: number): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ id }/coaches/finish`, undefined);
  }

  public dropFromLiveClassSeries(id: number): Observable<void> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ id }/me/dropSeries`, undefined).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public dropFromScheduledLiveClasses(id: number): Observable<void> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ id }/me/drop`, undefined).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public addScheduledLiveClassesToFavorite(
    scheduledLiveClassIdRequestDTO: ScheduledLiveClassIdRequestDTO[]
  ): Observable<void> {
    return this.http
      .post(
        `${ ApplicationConfig.apiUrl }/scheduledliveclasses/me/favorite`,
        ObjectUtil.classToPlain(scheduledLiveClassIdRequestDTO)
      )
      .pipe(
        map((response: any) => {
          return;
        })
      );
  }

  public removeScheduledLiveClassesFromFavorite(id: number): Observable<void> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ id }/me/unfavorite`).pipe(
      map((response: any) => {
        return;
      })
    );
  }

  public getUnratedScheduledLiveClasses(): Observable<ScheduledLiveClassUnratedResponseDTO[]> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/me/unrated/list`).pipe(
      map((response: any[]) => {
        return response.map((item) => ObjectUtil.plainToClass(ScheduledLiveClassUnratedResponseDTO, item));
      })
    );
  }

  public getSingleUnratedScheduledLiveClass(): Observable<ScheduledLiveClassUnratedResponseDTO> {
    return this.http
      .post(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/me/unrated`, ObjectUtil.classToPlain(null))
      .pipe(
        map((response: any[]) => {
          const page: ScheduledLiveClassUnratedResponseDTO = ObjectUtil.plainToClassFromExisting(
            new ScheduledLiveClassUnratedResponseDTO(),
            response
          );
          return page;
        })
      );
  }

  public updateScheduledLiveClassAdmin(
    id: number,
    scheduledLiveClass: ScheduledLiveClassUpdateRequestDTO
  ): Observable<ScheduledLiveClassDetailsResponseDTO> {
    return this.http
      .put(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ id }`, ObjectUtil.classToPlain(scheduledLiveClass))
      .pipe(
        map((response: any) => {
          const updatedScheduledLiveClass: ScheduledLiveClassDetailsResponseDTO = ObjectUtil.plainToClass(
            ScheduledLiveClassDetailsResponseDTO,
            response
          );
          return updatedScheduledLiveClass;
        })
      );
  }

  public deleteScheduledLiveClass(ids: number[]): Observable<void> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ ids }`).pipe(
      map((response: any) => {
        return;
      })
    );
  }

  public getHappeningNowClasses(): Observable<ScheduledLiveClassSeniorDetailsResponseDTO[]> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/scheduledliveclasses/happeningnow`).pipe(
      map((response: any[]) => {
        return response.map((item) => ObjectUtil.plainToClass(ScheduledLiveClassSeniorDetailsResponseDTO, item));
      })
    );
  }

  public getScheduledLiveClassRatingPage(criteria: ScheduledLiveClassRatingAdminPageCriteriaDTO): Observable<PageDTO<ScheduledLiveClassRatingAdminPageDTO>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParameterEncoder(),
      fromObject: ObjectUtil.valueAsString(ObjectUtil.classToPlain(criteria, true, true, true, true))
    });

    return this.http.get(`${ ApplicationConfig.apiUrl }/scheduledliveclassratings/admins/page`, { params: params }).pipe(
      map((response: any) => {
        return ObjectUtil.plainToClassFromExisting(new PageDTO<ScheduledLiveClassRatingAdminPageDTO>(ScheduledLiveClassRatingAdminPageDTO), response);
      })
    );
  }

  public rateScheduledLiveClass(
    scheduledLiveClassId: number,
    ratingRequest: ScheduledLiveClassRatingRequestDTO
  ): Observable<ScheduledLiveClassRatingResponseDTO> {
    return this.http
      .put(
        `${ ApplicationConfig.apiUrl }/scheduledliveclasses/${ scheduledLiveClassId }/me/rate`,
        ObjectUtil.classToPlain(ratingRequest)
      )
      .pipe(
        map((response: any) => {
          const ratingResponse: ScheduledLiveClassRatingResponseDTO = ObjectUtil.plainToClass(
            ScheduledLiveClassRatingResponseDTO,
            response
          );
          return ratingResponse;
        })
      );
  }

  public createAttachmentAsset(asset: ScheduledLiveClassAttachmentCreateRequestDTO): Observable<ScheduledLiveClassAttachmentResponseDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/scheduledliveclassattachments`, ObjectUtil.classToPlain(asset)).pipe(
      map((response: any) => {
        const attachment: ScheduledLiveClassAttachmentResponseDTO = ObjectUtil.plainToClass(
          ScheduledLiveClassAttachmentResponseDTO,
          response
        );
        return attachment;
      })
    );
  }

  public joinWaitingList(scheduledLiveClassId: number, platformData: PlatformDataRequestDTO): Observable<void> {
    return this.http.put(
      `${ ApplicationConfig.apiUrl }/scheduledliveclasswaitinglists/me/join/${ scheduledLiveClassId }`,
      ObjectUtil.classToPlain(platformData)
    )
      .pipe(
        map((_response: any) => {
          return;
        })
      );
  }

  public dropWaitingList(scheduledLiveClassId: number): Observable<void> {
    return this.http.delete(
      `${ ApplicationConfig.apiUrl }/scheduledliveclasswaitinglists/me/drop/${ scheduledLiveClassId }`)
      .pipe(
        map((_response: any) => {
          return;
        })
      );
  }
}

<ng-template #headingTemplate>
  <h1 class="mb-0">{{ 'VIEW.MAIN.ON_DEMAND.WATCH_PARTY.EVENT.TITLE' | translate }}</h1>

  <div class="space-x-4">
    <button *ngIf="inZoomSession && watchPartyDetails?.isHost"
            class="btn btn-outline-danger w-100 w-sm-auto"
            (click)="endWatchParty()"
    >
      {{ 'DOMAIN.WATCH_PARTY.ACTION.END' | translate }}
    </button>

    <button class="btn btn-outline-primary w-100 w-sm-auto" (click)="stateUtil.goBack()">
      {{ 'DOMAIN.WATCH_PARTY.ACTION.LEAVE' | translate }}
    </button>
  </div>
</ng-template>

<section class="section" #section>
  <div class="d-flex justify-content-between align-items-start gap-4 mb-4">
    <p class="mb-0">{{ watchPartyDetails?.video?.title | replaceEmpty }}</p>
    <app-full-screen-toggle [target]="section"></app-full-screen-toggle>
  </div>

  <div class="container-fluid px-0">
    <div class="row">
      <div class="col-12 col-lg-6 align-self-start">
        <figure id="vimeo" class="mb-0" [ngClass]="{ 'disabled': !inZoomSession }"></figure>
      </div>
      <div class="col-12 col-lg-6 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
        <app-zoom-complete-view [autoInit]="true"
                                [features]="[ 'audio', 'preview', 'settings', 'users', 'video' ]"
                                [hideEndButton]="true"
                                [user]="currentUser"
                                (sessionJoined)="connect($event)">
        </app-zoom-complete-view>
      </div>
    </div>
  </div>
</section>

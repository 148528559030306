import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './TabsComponent.html',
  styleUrls: [ './TabsComponent.scss' ]
})
export class TabsComponent implements OnInit {
  @Input()
  public translationPrefix: string;

  @Input()
  public tabs: string[];

  @Input()
  public tabDefinition: any[];

  @Input()
  public activeTab: string;

  @Output()
  public tabChanged: EventEmitter<string> = new EventEmitter<string>();


  ngOnInit(): void {
    if (this.tabs?.length && !this.activeTab) {
      this.activeTab = this.tabs[0];
    }
  }

  public onTabChanged(tabName: string): void {
    this.activeTab = tabName;
    this.tabChanged.emit(tabName);
  }
}

import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {PortalUtil} from 'app/util/PortalUtil';
import {MainLayoutComponent} from 'app/component/view/main/MainLayoutComponent';
import {TemplatePortal} from '@angular/cdk/portal';
import {Observable} from 'rxjs';
import {Transition} from '@uirouter/angular';
import {tap} from 'rxjs/operators';
import {UserModel} from 'app/model/UserModel';
import {LiveClassZoomDTO} from 'app/data/dto/scheduledLiveClass/LiveClassZoomDTO';
import {LiveClassZoomMeetingService} from 'app/service/LiveClassZoomMeetingService';

@Component({
    selector: 'app-live-classes-coach-zoom',
    templateUrl: './LiveClassesCoachZoomComponent.html',
    styleUrls: ['./LiveClassesCoachZoomComponent.scss']
})
export class LiveClassesCoachZoomComponent implements OnInit, OnDestroy {
    @ViewChild('headingTemplate', {static: true})
    private readonly headingTemplate: TemplateRef<any>;
    public title: string;
    public liveClassZoom$: Observable<LiveClassZoomDTO> = this.liveClassZoomService.getLiveClassZoomConfiguration(this.transition.params().id)
        .pipe(
            tap((liveClassZoom: LiveClassZoomDTO) => {
                this.title = liveClassZoom.liveClassName || 'Unknown';
            })
        );

    public activeQuestion: boolean = true;

    constructor(private portalUtil: PortalUtil,
                private transition: Transition,
                public userModel: UserModel,
                public liveClassZoomService: LiveClassZoomMeetingService,
                private viewContainerRef: ViewContainerRef) {
    }

    public ngOnInit(): void {
        this.portalUtil.attachPortalTo(
            MainLayoutComponent.PORTAL_OUTLET.HEADING,
            new TemplatePortal(this.headingTemplate, this.viewContainerRef)
        );
    }

    public ngOnDestroy(): void {
        this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
    }

    public openOnDesktop(url: string): void {
        if (url) {
            window.open(url, '_blank');
        }
    }

    public onBackClick(): void {
        history.back();
    }
}
<ng-template #headingTemplate>
  <h1>{{ title | translate }}</h1>

  <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
    <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
    <span>{{ 'COMMON.BACK' | translate }}</span>
  </button>

</ng-template>

<section class="section" *ngIf="liveClassZoom">
  <app-zoom-meeting [config]="liveClassZoom"
                    [username]="userModel.currentUser.fullName"
                    (meetingEnd)="onBackClick()"></app-zoom-meeting>
</section>
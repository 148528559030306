<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.DASHBOARD.STATISTICS' | translate }}</h1>
</ng-template>

<section class="section">
  <iframe [src]="statisticsUrl"
          allowfullscreen
          credentialless
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
  </iframe>
</section>
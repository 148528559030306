import { BreakpointObserver } from '@angular/cdk/layout';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Constant } from 'app/common/Constant';
import { State } from 'app/common/State';
import {
  DataGridTemplateCellRendererComponent
} from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { RouteTab } from 'app/component/ui/routeTabs/RouteTab';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { FriendDTO } from 'app/data/dto/friend/FriendDTO';
import { PageCriteriaDTO } from 'app/data/dto/PageCriteriaDTO';
import { PageDTO } from 'app/data/dto/PageDTO';
import { TutorialType } from 'app/data/dto/user/TutorialType';
import { UserDetailsDTO } from 'app/data/dto/user/UserDetailsDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { FriendsTourWizardAnchor } from 'app/data/local/FriendsTourWizardAnchor';
import { FriendInvitationModel } from 'app/model/friend/FriendInvitationModel';
import { FriendModel } from 'app/model/friend/FriendModel';
import { UserModel } from 'app/model/UserModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { TourWizardService, TourWizardStep } from 'ngx-tour-wizard';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-friends-list',
  templateUrl: './FriendsListComponent.html',
  styleUrls: ['./FriendsListComponent.scss']
})
export class FriendsListComponent implements OnInit {
  public readonly FriendsTourWizardAnchor: typeof FriendsTourWizardAnchor = FriendsTourWizardAnchor;
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public criteria: PageCriteriaDTO = new PageCriteriaDTO();
  public page: PageDTO<FriendDTO>;
  public currentUser: UserDTO = this.userModel.currentUser;
  public listWindowSize: number = 3;

  public Constant: typeof Constant = Constant;
  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent =
    DataGridTemplateCellRendererComponent;

  readonly tabs: RouteTab[] = [
    {
      name: 'VIEW.MAIN.FRIENDS.TABS.ALL',
      state: State.MAIN.SENIOR.FRIEND.LIST
    },
    {
      name: 'VIEW.MAIN.FRIENDS.TABS.PENDING',
      state: State.MAIN.SENIOR.FRIEND.INVITATIONS
    }
  ];

  private onboardingTourStepList: TourWizardStep[] = [];

  private readonly canDisplayOnboardingTour: boolean = false;

  constructor(private readonly portalUtil: PortalUtil,
              private readonly friendModel: FriendModel,
              private readonly friendInvitationModel: FriendInvitationModel,
              private readonly userModel: UserModel,
              private readonly viewContainerRef: ViewContainerRef,
              private readonly tourWizardService: TourWizardService,
              private readonly viewUtil: ViewUtil,
              private readonly breakpointObserver: BreakpointObserver) {
    this.criteria.pageSize = this.listWindowSize;

    this.currentUser = this.userModel.currentUser;

    this.canDisplayOnboardingTour = this.breakpointObserver.isMatched('(min-width: 768px)');

    this.onboardingTourStepList = [
      {
        anchorId: FriendsTourWizardAnchor.FRIENDS_TAB,
        title: this.viewUtil.translateInstant('VIEW.MAIN.FRIENDS.TAB'),
        content: this.viewUtil.translateInstant('VIEW.MAIN.FRIENDS.TAB_DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      },
      {
        anchorId: FriendsTourWizardAnchor.TRACK_PROGRESS,
        title: this.viewUtil.translateInstant('VIEW.MAIN.FRIENDS.TRACK_PROGRESS'),
        content: this.viewUtil.translateInstant('VIEW.MAIN.FRIENDS.TRACK_PROGRESS_DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.TOP,
          positionFixed: true
        }
      },
      {
        anchorId: FriendsTourWizardAnchor.ADD_FRIEND,
        title: this.viewUtil.translateInstant('VIEW.MAIN.FRIENDS.ACTION.ADD'),
        content: this.viewUtil.translateInstant('VIEW.MAIN.FRIENDS.ACTION.ADD_DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM,
          positionFixed: true
        }
      }
    ];
  }

  ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.loadData();
  }

  public addFriend(): void {
    this.friendInvitationModel.invite().subscribe({
      next: () => this.loadData()
    });
  }

  public removeFriend(friend: FriendDTO): void {
    this.friendModel.removeFriend(friend).subscribe({
      next: () => this.loadData()
    });
  }

  public onCriteriaChanged(criteria: PageCriteriaDTO): void {
    this.criteria = criteria;

    this.loadData();
  }

  public nextPage(): void {
    this.criteria.pageSize += this.listWindowSize;

    this.onCriteriaChanged(this.criteria);
  }

  public loadData(): void {
    this.friendModel.getFriendsPage(this.criteria)
      .subscribe((result) => {
        this.page = result;
        this.startOnboardingTour();
      });
  }

  private startOnboardingTour(): void {
    if (this.currentUser?.completedTutorials?.includes(TutorialType.FRIENDS) || !this.canDisplayOnboardingTour) {
      return;
    }

    setTimeout(() => {
      this.tourWizardService.initialize(this.onboardingTourStepList);
      this.tourWizardService.start();

      // add progress per step
      this.onboardingTourStepList.forEach((step: TourWizardStep, index: number) => {
        const tourStepElement: HTMLElement = document.querySelector('[tourWizardAnchor="' + step.anchorId + '"] + tour-wizard-popper-component');
        tourStepElement.classList.add('tour-wizard-progress');
        tourStepElement.style.setProperty('--current-step', (index + 1).toString());
        tourStepElement.style.setProperty('--all-steps', (this.onboardingTourStepList.length).toString());
      });

      this.tourWizardService.end$.pipe(
        first(),
        switchMap(() => this.userModel.setCurrentUserTutorialCompletion(TutorialType.FRIENDS))
      ).subscribe((userDetails: UserDetailsDTO) => {
        this.currentUser.completedTutorials = userDetails.completedTutorials;
      });
    });
  }
}

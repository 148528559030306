<div class="notification-container p-3">
  <div class="d-flex justify-content-between">
    <h3>{{ "VIEW.MAIN.NOTIFICATIONS.NOTIFICATIONS" | translate }}</h3>
    <button type="button" id="close-button" (click)="close()">
      <svg-icon class="d-flex" src="asset/image/icon/x.svg"></svg-icon>
    </button>
  </div>
  <div class="scroll-container">
    <ng-container *ngIf="notifications$ | async as notifications">
      <ng-container *ngIf="notifications.length > 0; else noNotificationsTemplate">
        <div
          *ngFor="let notification of notifications"
          class="d-flex flex-column notification-card p-4 gap-6"
          [ngClass]="{'important' : notification.priority === Priority.CRITICAL, 'disabled' : notification.priority === Priority.NORMAL}"
        >
          <div class="d-flex flex-column">
            <div class="d-flex gap-2">
              <svg-icon
                *ngIf="notification.priority !== Priority.CRITICAL"
                src="asset/image/icon/arrow-right.svg"
              ></svg-icon>
              <svg-icon *ngIf="notification.priority === Priority.CRITICAL" src="asset/image/icon/alert.svg"></svg-icon>
              <h4 class="m-0">{{ notification.title }}</h4>
            </div>
            <div class="text-sm color-grey mt-2 time-ago">{{ notification.createdDate | timeAgo }}</div>
            <div class="text-sm color-grey mt-2 time-ago" [innerHTML]="formatContent(notification.content)"></div>
          </div>

          <div *ngIf="!notification.read" class="d-flex justify-content-end w-100 gap-3">
            <ng-container *ngTemplateOutlet="(notificationTypeWithDedicatedActions | includes: notification.type) ? dedicatedActionsTemplate : otherActionsTemplate; context: { $implicit: notification }">
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<!--Templates-->
<ng-template #dedicatedActionsTemplate let-notification>
  <ng-container [ngSwitch]="notification.type">
    <ng-container *ngSwitchCase="NotificationType.WATCH_PARTY_INVITATION">
      <button type="button"
              class="btn btn-outline-primary"
              (click)="rejectWatchPartyInvitation(notification)"
      >
        <span>{{ 'DOMAIN.WATCH_PARTY.ACTION.REJECT_INVITATION' | translate }}</span>
      </button>

      <button type="button"
              class="btn btn-primary"
              (click)="acceptWatchPartyInvitation(notification)"
      >
        <span>{{ 'DOMAIN.WATCH_PARTY.ACTION.ACCEPT_INVITATION' | translate }}</span>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="NotificationType.WATCH_PARTY_CANCELED">
      <button type="button"
              class="btn"
              [ngClass]="notification.priority === Priority.CRITICAL ? 'btn-danger' : 'btn-primary'"
              (click)="markAsRead(notification.id)"
      >
        <span>{{ 'COMMON.MARK_AS_READ' | translate }}</span>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="NotificationType.NEW_FRIEND_INVITATION">
      <button type="button" class="btn btn-outline-primary"
              (click)="goToFriendInvitations(notification)"
      >
        <span>{{ 'COMMON.SHOW' | translate }}</span>
      </button>

      <button type="button"
              class="btn"
              [ngClass]="notification.priority === Priority.CRITICAL ? 'btn-danger' : 'btn-primary'"
              (click)="markAsRead(notification.id)"
      >
        <span>{{ 'COMMON.MARK_AS_READ' | translate }}</span>
      </button>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #otherActionsTemplate let-notification>
  <button type="button"
          *appCanPerformAction="liveClassModel.logic.isJoinable;data:notification?.scheduledLiveClass"
          (click)="joinLiveClasses(notification.scheduledLiveClass.id)"
          class="btn"
          [ngClass]="{'btn-danger' : notification.priority === Priority.CRITICAL, 'btn-primary' : notification.priority !== Priority.CRITICAL}"
  >
    <span>{{ 'COMMON.JOIN' | translate }}</span>
  </button>

  <button type="button"
          class="btn"
          [ngClass]="notification.priority === Priority.CRITICAL ? 'btn-danger' : 'btn-primary'"
          (click)="markAsRead(notification.id)"
  >
    <span>{{ 'COMMON.MARK_AS_READ' | translate }}</span>
  </button>
</ng-template>

<ng-template #noNotificationsTemplate>
  <div class="d-flex flex-column no-notification-card p-4 gap-6 align-items-center justify-content-center p-12">
    <h3 class="mt-4 text-center">{{ "VIEW.MAIN.NOTIFICATIONS.NO_NOTIFICATIONS" | translate }}</h3>
  </div>
</ng-template>
<ng-template #headingTemplate>
  <h1>{{ 'VIEW.MAIN.LIVE_CLASSES.LIVE_CLASSES' | translate }}</h1>
</ng-template>

<section class="section">
  <ng-container *ngIf="page$ | async as page">
    <app-tabs
      [translationPrefix]="'VIEW.MAIN.LIVE_CLASSES.'"
      [tabs]="tabs"
      (tabChanged)="onTabChanged($event)"
    ></app-tabs>
    <div [tourWizardAnchor]="TourWizardAnchor.HEART">
      <app-data-grid
              [data]="page.content"
              [criteria]="criteria"
              [context]="{ host: this, additionalData: { currentUser } }"
              [selectionEnabled]="true"
              [showFilters]="true"
              [showPagination]="true"
              [pageTotal]="page.totalPages"
              [itemTotal]="page.totalElements"
              [showItemsPerPage]="true"
              [showTotalItems]="false"
              [multiSelectionEnabled]="true"
              [useCheckboxSelection]="true"
              (gridAction)="onGridAction($event)"
              (itemsSelected)="onSelectionChange($event)"
              (criteriaChanged)="onCriteriaChanged($event)"
              [tourWizardAnchor]="TourWizardAnchor.BULK_REGISTER"
              [columns]="$any([
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.DATE' | translate), headerClass: 'margin-l-50 pl-0', field: 'startDate', minWidth: 120, maxWidth: 120, checkboxSelection: checkboxSelectionBinder, valueFormatter: startDateValueFormatter},
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.TIME' | translate), field: 'startDate', minWidth: 100, maxWidth: 100, cellClass: 'text-right text-lg-left', valueFormatter: startTimeValueFormatter },
        { headerName: '', field: 'liveClassSeriesId', minWidth: 50, maxWidth: 50, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: typeIconTemplate } },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.NAME' | translate), field: 'title', tooltipField: 'title', minWidth: 200, maxWidth: 300 },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.COACH' | translate), field: 'coach', minWidth: 215, maxWidth: 250, cellClass: 'text-right text-lg-left', cellRendererFramework: LiveClassesListCoachCellRendererComponent },
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.CATEGORY' | translate), field: 'category', minWidth: 100, valueFormatter: enumCategoryFormatter},
        { headerName: ('VIEW.MAIN.LIVE_CLASSES.INTENSITY' | translate), field: 'intensity', minWidth: 95, maxWidth: 95, cellClass: 'text-right text-lg-left', valueFormatter: enumIntensityFormatter, cellRendererFramework: IntensityComponent },
        { headerName: '', headerClass: 'header-right', minWidth: 295 , cellClass: 'cell-fullwidth', suppressKeyboardEvent: LiveClassesListActionCellRendererComponent.suppressKeyboardEvent, cellRendererFramework: LiveClassesListActionCellRendererComponent }
      ])"
      >
        <app-data-grid-filters>
          <div class="form-row mb-4 mb-lg-0">
            <div class="col-6 col-md-auto">
              <button (click)="onTodayClick()" class="btn btn-outline-primary w-100">
                {{ 'VIEW.MAIN.LIVE_CLASSES.TODAY' | translate }}
              </button>
            </div> <!-- /.col -->

            <div class="col-6 col-md-auto">
              <ng-select
                      id="days-ahead"
                      name="days-ahead"
                      [placeholder]="'VIEW.MAIN.LIVE_CLASSES.NEXT_DAYS' | translate"
                      [searchable]="false"
                      [style.--icon]="'url(/asset/image/icon/calendar-due.svg)'"
                      (ngModelChange)="onDaysAheadChange($event)"
                      [(ngModel)]="consecutiveDays"
                      [tourWizardAnchor]="TourWizardAnchor.NEXT_DAYS"
              >
                <ng-option *ngFor="let daysAhead of daysAheadSelectList" [value]="daysAhead.value">
                  {{ daysAhead.label | translate }}
                </ng-option>
              </ng-select>
            </div> <!-- /.col -->

            <div class="col-12 col-md-auto">
              <div id="filter-date" class="mt-4 mt-md-0">
                <svg-icon
                        src="asset/image/icon/chevron-left.svg"
                        class="mr-auto mr-md-2"
                        (click)="changeToPreviousDay()"
                ></svg-icon>
                <div class="d-flex flex-column align-items-center">
                  <div (click)="dp.toggle()">
                    <span class="btn-link">{{ selectedDate | date: 'EEE, MMM d, yyyy' }}</span>
                  </div>
                  <input
                          type="text"
                          class="text-like-input"
                          bsDatepicker
                          [bsConfig]="bsConfig"
                          #dp="bsDatepicker"
                          (bsValueChange)="onDateChange($event)"
                          readonly
                  />
                </div>
                <svg-icon
                        src="asset/image/icon/chevron-right.svg"
                        class="ml-auto ml-md-2"
                        (click)="changeToNextDay()"
                ></svg-icon>
              </div>
            </div> <!-- /.col -->
          </div>
        </app-data-grid-filters>

        <app-extra-control>
          <ng-select
                  id="filter-classes"
                  [placeholder]="'VIEW.MAIN.LIVE_CLASSES.FILTER_BY' | translate"
                  [searchable]="false"
                  [style.--icon]="'url(/asset/image/icon/filter.svg)'"
                  (click)="showFilterClick()"
                  [tourWizardAnchor]="TourWizardAnchor.FILTER_BY"
          >
          </ng-select>
        </app-extra-control>

        <app-selected-filters [criteria]="criteria"
                              [friendlyNames]="friendlyNames"
                              [getLabelForKeyValue]="getLabelForKeyValueBind"
                              (criteriaChange)="onCriteriaChanged($event)"></app-selected-filters>
      </app-data-grid>
    </div>
  </ng-container>
</section>

<app-live-classes-selected-action-bar [selectedClasses]="selectedItems"
                                      [seniorMode]="true"
                                      (favoriteClick)="onFavoriteClick($event)"
                                      (registerClick)="onRegisterClick($event)">
</app-live-classes-selected-action-bar>

<!-- Grid Templates -->
<ng-template #typeIconTemplate let-value="value">
  <div class="d-flex align-items-center h-100">
    <app-live-class-type-icon [seriesId]="value"></app-live-class-type-icon>
  </div>
</ng-template>
